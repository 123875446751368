import axios from 'axios'
import NoPic from 'assets-next/images/no-pic.png'
import NoCollectionPic from 'assets-next/images/defaultCollection.jpg'
import { getAllNFTAssetsFromAddress, getAskTransactions, removeDecimalFromPrice } from 'utils'

import { categoryOptions } from 'components-next/Marketplace/constants'
import { isTemplateMiddle } from 'typescript'

const MockData = {
  data: {
    artistName: 'NA',
    artWorkType: 'image',
    artworkName: 'No name',
    socialLink: 'https://temp.data',
    bio: '',
    image: NoPic,
  },
}

export const ipfsURL = (url) => {
  let tokenURI = url ? url : ''
  if (tokenURI && tokenURI.includes('ipfs://ipfs/') && !tokenURI.includes('https://ipfs.io/')) {
    const val = tokenURI.split('ipfs://')[1]
    tokenURI = `https://ipfs.io/${val}`
  } else if (tokenURI && tokenURI.includes('ipfs://') && !tokenURI.includes('https://ipfs.io/')) {
    const val = tokenURI.split('ipfs://')[1]
    tokenURI = `https://ipfs.io/ipfs/${val}`
  }

  return tokenURI
}

export const getProfileDetail = async (myNfts, address) => {
  const _getNFT = []

  for (const nft of myNfts) {
    try {
      const tokenURI = ipfsURL(nft?.tokenURI)

      const _extraDetail = tokenURI ? await axios.get(tokenURI) : MockData
      const details = await getAllNFTAssetsFromAddress({
        account: address.toLowerCase(),
        collectionAddress: null,
        quoteToken: null,
      })

      const id = nft?.id
      const tokenId = nft?.tokenID
      const collectionAddress = nft.id.split('_')[0]
      const currentDetail = details.filter((detail) => detail.tokenId === tokenId)[0]
      const quoteToken = currentDetail ? currentDetail.quoteToken : null
      const price = currentDetail ? removeDecimalFromPrice(currentDetail.quoteToken, Number(currentDetail.price)) : null
      const voteCnt = currentDetail ? currentDetail.voteCnt : null

      const category = currentDetail
        ? Object.values(categoryOptions).some((item) => item.value === currentDetail.category)
          ? currentDetail.category
          : 'NA'
        : null

      const image = ipfsURL(_extraDetail.data.image)
      const imgdata = await axios.get(image)
      const types = imgdata.headers[`content-type`].split('/')
      const artWorkType = types[0] === 'image' ? 'image' : 'video'

      _extraDetail.data.artWorkType = artWorkType

      if (_extraDetail.data) {
        _getNFT.push({
          id,
          tokenId,
          collectionAddress,
          price,
          voteCnt,
          tokenURI,
          quoteToken,
          category,
          ..._extraDetail.data,
          image,
        })
      } else {
        _getNFT.push({
          id,
          tokenId,
          collectionAddress,
          price,
          voteCnt,
          quoteToken,
          category,
          tokenURI,
          ...MockData.data,
        })
      }
    } catch (err) {
      console.error('err', err)
    }
  }

  return _getNFT
}

export const getUserGNFTDetails = async (myNfts, address) => {
  const _getNFT = []

  for (const nft of myNfts) {
    try {
      const artWorkType = nft.artworkType
      const artistName = nft.artistName
      const artworkName = nft.artworkName
      const bio = nft.description || ''
      const category = nft.categor || null
      const collectionAddress = nft?.tokenCollection
      const id = nft?.id
      const image = nft?.tokenImage

      const socialLink = ''
      const tokenId = nft?.tokenId
      const tokenURI = ipfsURL(nft?.tokenUri)

      const details = await getAllNFTAssetsFromAddress({
        account: nft?.owner ? nft.owner.toLowerCase() : address.toLowerCase(),
        collectionAddress: null,
        quoteToken: null,
      })

      const currentDetail = details.filter((detail) => Number(detail.tokenId) === tokenId)[0]

      const quoteToken = currentDetail ? currentDetail.quoteToken : null
      const price = currentDetail ? removeDecimalFromPrice(currentDetail.quoteToken, Number(currentDetail.price)) : null
      const voteCnt = currentDetail ? currentDetail.voteCnt.toString() : null
      _getNFT.push({
        id,
        tokenId,
        collectionAddress,
        price,
        voteCnt,
        tokenURI,
        quoteToken,
        category,

        image,
        artWorkType,
        artistName,
        artworkName,
        bio,
        socialLink,
      })
    } catch (err) {
      console.error('err', err)
    }
  }

  return _getNFT
}

export const getUserNFTDetails = async (myNfts, address) => {
  const _getNFT = []

  for (const nft of myNfts) {
    try {
      const tokenURI = ipfsURL(nft?.tokenUri)

      const _extraDetail = tokenURI ? await axios.get(tokenURI) : MockData
      const details = await getAllNFTAssetsFromAddress({
        account: address.toLowerCase(),
        collectionAddress: null,
        quoteToken: null,
      })

      const id = nft?.id
      const tokenId = nft?.tokenId
      const collectionAddress = nft?.tokenCollection
      const currentDetail = details.filter((detail) => detail.tokenId === tokenId)[0]
      const quoteToken = currentDetail ? currentDetail.quoteToken : null
      const price = currentDetail ? removeDecimalFromPrice(currentDetail.quoteToken, Number(currentDetail.price)) : null
      const voteCnt = currentDetail ? currentDetail.voteCnt : null

      const category = currentDetail
        ? Object.values(categoryOptions).some((item) => item.value === currentDetail.category)
          ? currentDetail.category
          : 'NA'
        : null

      const image = ipfsURL(_extraDetail.data.image)
      const imgdata = await axios.get(image)
      const types = imgdata.headers[`content-type`].split('/')
      const artWorkType = types[0] === 'image' ? 'image' : 'video'

      _extraDetail.data.artWorkType = artWorkType

      if (_extraDetail.data) {
        _getNFT.push({
          id,
          tokenId,
          collectionAddress,
          price,
          voteCnt,
          tokenURI,
          quoteToken,
          category,
          ..._extraDetail.data,
          image,
        })
      } else {
        _getNFT.push({
          id,
          tokenId,
          collectionAddress,
          price,
          voteCnt,
          quoteToken,
          category,
          tokenURI,
          ...MockData.data,
        })
      }
    } catch (err) {
      console.error('err', err)
    }
  }

  return _getNFT
}

export const getProfile_CollectionDetail = (nfts) => {
  return nfts.map((item) => ({
    tokenId: item.id,
    collectionAddress: item.tokenCollection,
    name: item.name,
    symbol: item.symbol,
    image: item.image ? item.image : NoCollectionPic,
    is_Collection: true,
    nftCount: item.nftCount,
  }))
}

export const getCovalentData = async (data, exchangeNftSC) => {
  const { items } = data
  const _nftDetails = []
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    const { contract_address, nft_data } = item
    if (!nft_data) return []
    for (let j = 0; j < nft_data.length; j++) {
      const nft = nft_data[j]
      const id = nft?.id
      const tokenId = nft?.token_id
      const ownerAddress = nft?.owner
      const tokenUri = nft?.token_url
      const collectionAddress = contract_address
      let price = nft?.token_price_wei || null
      let quoteToken = nft?.token_quote_rate_eth || null
      if (exchangeNftSC && exchangeNftSC._address !== null) {
        try {
          const auctionId = await exchangeNftSC.methods.getAuctionId(collectionAddress, tokenId).call()

          if (auctionId > 0) {
            const _askTransactions = await getAskTransactions(collectionAddress, tokenId, auctionId)

            if (_askTransactions.length > 0) {
              // price = Math.max(...((_askTransactions && _askTransactions.map((t) => t.price)) || []))

              price = _askTransactions[0]?.price
              quoteToken = _askTransactions[0]?.quoteToken
            }
          }
        } catch (error) {
          console.error('Marketplace exchangeNftSC error:--->', error)
        }
      }
      const voteCnt = null
      const image = nft?.external_data?.image || NoPic
      const categoryVal = nft?.external_data?.attributes?.filter((i) => i.trait_type === 'Set')[0]?.value || 'NA'
      let artworkName
      let artistName
      let artWorkType
      let bio
      try {
        const { data: additional_details } = await axios.get(tokenUri)
        artworkName =
          additional_details?.artworkName || additional_details?.name || nft?.external_data?.name || 'No name'
        artistName =
          additional_details?.artistName ||
          nft?.external_data?.attributes?.filter((i) => i.trait_type === 'Artist')[0]?.value ||
          'Unamed'
        artWorkType =
          additional_details?.artWorkType ||
          nft?.external_data?.attributes?.filter((i) => i.trait_type === 'Type')[0]?.value ||
          'NA'
        bio = additional_details?.bio || additional_details?.description || nft?.external_data?.description || ''
      } catch (err) {
        artworkName = nft?.external_data?.name || 'No name'
        artistName = nft?.external_data?.attributes?.filter((i) => i.trait_type === 'Artist')[0]?.value || 'Unamed'
        artWorkType = nft?.external_data?.attributes?.filter((i) => i.trait_type === 'Type')[0]?.value || 'NA'
        bio = nft?.external_data?.description || ''
      }

      const category = categoryVal
        ? Object.values(categoryOptions).some((item) => item.value === categoryVal)
          ? categoryVal
          : 'NA'
        : null

      _nftDetails.push({
        ownerAddress,
        id,
        tokenId,
        tokenURL: tokenUri,
        collectionAddress,
        quoteToken,
        price: removeDecimalFromPrice(quoteToken, Number(price)),

        voteCnt,
        artworkName,
        category,
        artistName,
        artWorkType,
        bio,
        image,
      })
    }
  }

  return _nftDetails
}

export const getCovalentData2 = async (data, exchangeNftSC) => {
  const _nftDetails = []
  const { collectionAddress, nftData } = data
  if (!nftData) return []

  for (let i = 0; i < nftData.length; i++) {
    const nft = nftData[i]

    const id = nft?.id
    const tokenId = nft?.token_id
    const tokenUri = nft?.token_url
    const ownerAddress = nft?.owner

    let price = nft?.token_price_wei || null
    let quoteToken = nft?.token_quote_rate_eth || null
    if (exchangeNftSC && exchangeNftSC._address !== null) {
      try {
        const auctionId = await exchangeNftSC.methods.getAuctionId(collectionAddress, tokenId).call()
        if (auctionId > 0) {
          const _askTransactions = await getAskTransactions(collectionAddress, tokenId, auctionId)
          if (_askTransactions.length > 0) {
            // price = Math.max(...((_askTransactions && _askTransactions.map((t) => t.price)) || []))
            price = _askTransactions[0]?.price
            quoteToken = _askTransactions[0]?.quoteToken
          }
        }
      } catch (error) {
        console.error('Marketplace exchangeNftSC error:--->', error)
      }
    }
    const voteCnt = null
    const image = nft?.external_data?.image || NoPic

    const categoryVal = nft?.external_data?.attributes?.filter((i) => i.trait_type === 'Set')[0]?.value || 'NA'
    let artworkName
    let artistName
    let artWorkType
    let bio

    try {
      const { data: additional_details } = await axios.get(tokenUri)
      artworkName = additional_details?.artworkName || additional_details?.name || nft?.external_data?.name || 'No name'
      artistName =
        additional_details?.artistName ||
        nft?.external_data?.attributes?.filter((i) => i.trait_type === 'Artist')[0]?.value ||
        'Unamed'
      artWorkType =
        additional_details?.artWorkType ||
        nft?.external_data?.attributes?.filter((i) => i.trait_type === 'Type')[0]?.value ||
        'NA'
      bio = additional_details?.bio || additional_details?.description || nft?.external_data?.description || ''
    } catch (err) {
      artworkName = nft?.external_data?.name || 'No name'
      artistName = nft?.external_data?.attributes?.filter((i) => i.trait_type === 'Artist')[0]?.value || 'Unamed'
      artWorkType = nft?.external_data?.attributes?.filter((i) => i.trait_type === 'Type')[0]?.value || 'NA'
      bio = nft?.external_data?.description || ''
    }

    const category = categoryVal
      ? Object.values(categoryOptions).some((item) => item.value === categoryVal)
        ? categoryVal
        : 'NA'
      : null

    _nftDetails.push({
      id,
      tokenId,
      tokenURL: tokenUri,
      collectionAddress,
      ownerAddress,
      quoteToken,
      price: removeDecimalFromPrice(quoteToken, Number(price)),

      voteCnt,
      artworkName,
      category,
      artistName,
      artWorkType,
      bio,
      image,
    })
  }

  return _nftDetails
}

export const getCovalentCollection = async (items) => {
  const _getNFT = []
  if (items.length === 0) return []

  try {
    items.map((item) => {
      const tokenId = item?.contract_address
      const collectionAddress = item?.contract_address
      const name = item?.contract_name
      const symbol = item?.contract_ticker_symbol
      const image = NoCollectionPic || item?.logo_url
      const is_Collection = true
      const nftData = item?.nft_data

      _getNFT.push({
        tokenId,
        collectionAddress,
        name,
        symbol,
        image,
        is_Collection,
        nftData,
      })
    })
  } catch (err) {
    console.error(err)
  }

  return _getNFT
}
