import React from 'react'
import { CheckIcon, ErrorIcon, WarningIcon } from 'shared-next/Icons'

interface AlertProps extends React.HTMLProps<HTMLDivElement> {
  icon?: 'success' | 'danger' | 'warning' | 'info' | undefined
}

const getIcon = (val: string) => {
  switch (val) {
    case 'success':
      return <CheckIcon />
    case 'danger':
      return <ErrorIcon />
    case 'warning':
      return <WarningIcon />
    default:
      return <CheckIcon />
  }
}

const getColor = (val: string) => {
  switch (val) {
    case 'success':
      return 'bg-green-200 text-green-700'
    case 'danger':
      return 'bg-red-200 text-red-700'
    case 'warning':
      return 'bg-yellow-300 text-yellow-700'
    default:
      return 'bg-green-400 text-green-700'
  }
}

const Alert = ({ icon = 'success', children }: AlertProps) => {
  return (
    <div className={`flex items-center px-4 py-2 rounded-lg gap-3 ${getColor(icon)}`}>
      <span className="">{getIcon(icon)}</span>
      <div>{children}</div>
    </div>
  )
}

export default Alert
