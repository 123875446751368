import gql from 'graphql-tag'

export const ASK_TRANSACTIONS_SEARCH = ({ tokenId, auctionId }) => {
  const queryString = `
    query askTransactions {
      askTransactions(
        where: { 
          ${auctionId ? `auctionId: "${auctionId}"` : ``}
          ${tokenId ? `token: "${tokenId}"` : ``}
        }
        orderBy: price,
        orderDirection: desc
      )
      {
        seller {
          address
        }
        price
        quoteToken
      }
    }`
  return gql(queryString)
}

export const BID_TRANSACTIONS_SEARCH = ({ tokenId, auctionId }) => {
  const queryString = `
    query bidTransactions {
      bidTransactions(
        where: { 
          ${auctionId ? `auctionId: "${auctionId}"` : ``}
          ${tokenId ? `token: "${tokenId}"` : ``}
        }
        orderBy: price,
        orderDirection: desc
      )
      {
        buyer {
          address
        }
        price
        quoteToken
      }
    }`
  return gql(queryString)
}

export const TRADE_TRANSACTIONS_SEARCH = ({ tokenId }) => {
  const queryString = `
    query tradeTransactions {
      tradeTransactions(
        where: { 
          ${tokenId ? `token: "${tokenId}"` : ``}
        }
        orderBy: tradeDate,
        orderDirection: desc
      )
      {
        seller {
          address
        }
        buyer {
          address
        }
        price
        quoteToken
        tradeDate
        type
      }
    }`
  return gql(queryString)
}
