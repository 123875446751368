import React from 'react'

const WalletIcon = () => (
  <svg width={20} height={20} viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.432 1.731A1.52 1.52 0 008.92.331H1.817A1.52 1.52 0 00.299 1.85v7.024a1.52 1.52 0 001.518 1.518h8.205a1.52 1.52 0 001.518-1.518v-5.68a1.52 1.52 0 00-1.107-1.462zm-8.615-.69H8.92a.81.81 0 01.79.633H1.816c-.297 0-.575.087-.809.235v-.06a.81.81 0 01.809-.809zm8.205 8.641H1.817a.81.81 0 01-.809-.809v-5.68a.81.81 0 01.809-.81h8.205a.81.81 0 01.81.81V4.43H8.57c-.896 0-1.625.729-1.625 1.625S7.674 7.68 8.57 7.68h2.261v1.193a.81.81 0 01-.809.81zm.81-2.711H8.57a.917.917 0 010-1.832h2.261v1.832z"
      fill="currentColor"
    />
    <path d="M8.702 6.45a.37.37 0 100-.737.37.37 0 000 .738z" fill="currentColor" />
  </svg>
)

export default WalletIcon
