import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Marketplace, MyProfile, NftDetail, Layout, UserProfile, HomePage, CollectionDetails } from 'components-next'
import { NotFound } from 'shared-next'
import connector from './connector'

function MainPage() {
  const { login, logout, onWeb3Connect, persistLogin, chainError } = connector()

  useEffect(() => {
    persistLogin()
  }, [login])

  return (
    <>
      <Layout login={login} logout={logout} onWeb3Connect={onWeb3Connect} chainError={chainError}>
        <Switch>
          <Route path="/home" exact component={(props) => <HomePage {...props} key={window.location.pathname} />} />
          <Route
            path="/my-profile"
            exact
            component={(props) => <MyProfile {...props} key={window.location.pathname} />}
          />
          <Route
            path="/marketplace"
            exact
            component={(props) => <Marketplace {...props} key={window.location.pathname} />}
          />
          <Route
            path="/assets/:contract_id/:token_id"
            component={(props) => <NftDetail {...props} key={window.location.pathname} />}
          />
          <Route
            path="/collection/:collectionAddress"
            component={(props) => <CollectionDetails {...props} key={window.location.pathname} />}
          />
          <Route
            path="/profile/:address"
            component={(props) => <UserProfile {...props} key={window.location.pathname} />}
          />
          <Route
            exact
            path="/"
            render={() => {
              return <Redirect to="/home" />
            }}
          />
          <Route component={NotFound} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </Layout>
    </>
  )
}

export default MainPage
