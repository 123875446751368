import React, { useEffect, useState } from 'react'
import { CardList, Sidebar } from 'components-next'
import { Accordion, Dropdown, ErrorBoundry, NoWallet } from 'shared-next'
import {
  ArtworkIcon,
  DollarIcon,
  CartIcon,
  CollectionIcon,
  CopyIcon,
  SoldIcon,
  TradeupIcon,
  CheckIcon,
  PuzzleIcon,
} from 'shared-next/Icons'
import useFetchNfts from 'hooks/useFetchNfts'
import useFetchProfile from 'hooks/useProfile'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/reducers'
import { setQuoteTokens, setCollection } from 'store/actions'
import { getTokenNameFromAddress } from 'utils'
import { artworkTypeOptions } from 'components-next/Marketplace/constants'
import { Alert } from 'shared-next'
import UserTab from './components/UserTab'
import { NftType } from 'types'
import { useHistory } from 'react-router'
import { connectorLocalStorageKey } from 'components-next/WalletModal/config'
import CreateNFT from './components/CreateNFT'
import { setActiveNft } from 'store/actions'
import { getContractAddresses } from 'contracts'
import { ClipLoader } from 'react-spinners'

const profileTabs = [
  {
    name: 'GNFT',
    value: 'GNFT',
    icon: <PuzzleIcon />,
  },
  {
    name: 'Collections',
    value: 'Collections',
    icon: <CollectionIcon />,
  },
  {
    name: 'All',
    value: 'All',
    icon: <ArtworkIcon />,
  },
  {
    name: 'Sold',
    value: 'Sold',
    icon: <SoldIcon />,
  },
  {
    name: 'On Sale',
    value: 'onSale',
    icon: <TradeupIcon />,
  },
]

const MyProfile = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const walletReducer = useSelector((state: RootState) => state.wallet)
  const { contracts, activeChainID, provider } = walletReducer
  const { exchangeSC, nftSC } = contracts
  const [artworkTypeOpt, setArtworkTypeOpt] = useState({ label: 'Select Type', value: '', field: 'artWorkType' })
  const [showAlert, setShowAlert] = useState<string>('')
  const [mintLoader, setMintLoader] = useState<boolean>(false)
  const [activeLink, setActiveLink] = useState('GNFT')

  const onChangeSelect = (value) => {
    setArtworkTypeOpt(value)
  }

  // quote tokens
  const [quoteTokenOptions, setQuoteTokenOptions] = useState([])
  const [activeQuoteTokenOption, setActiveQuoteTokenOption] = useState({ label: 'Quote Token', value: 'all' })

  // Option data
  const activeQuoteTokenAddress = activeQuoteTokenOption.value
  const activeArtWorkTypeOpt = artworkTypeOpt.value

  // Custom NFT fetch hook
  const {
    web3,
    address,
    myNfts,
    loading,
    canFetchMore,
    fetchMore,
    setPageNumber,
    setCanFetchMore,
    fetchProfileData,
    cleanupNfts,
    fetchMySellNftAssets,
    fetchMySoldNftAssets,
    fetchMyAllNftCollection,
    fetchMyParticularNftCollection,
    myNFTCollection,
    mySoldNFT,
    myOnSaleNFT,
    fetchGNFT,
    addMintNFT,
    GNFT,
    //activeChainID,
  } = useFetchNfts()

  const { profileLoader, isProfileDetailsAvailable, userDetails, fetchUserProfileDetails } = useFetchProfile()

  const addresses = getContractAddresses(activeChainID)

  // fetch nft quote tokens
  const fetchQuoteTokens = async () => {
    if (!address) return
    if (!exchangeSC) return
    if (exchangeSC && exchangeSC._address === null) return

    const _quoteTokens = await exchangeSC.methods.getQuoteTokens().call()
    const _quoteTokenOptions = _quoteTokens.map((quoteToken) => {
      const quoteTokenName = getTokenNameFromAddress(quoteToken)
      return {
        label: quoteTokenName.length > 0 ? `${quoteTokenName}` : quoteToken,
        value: quoteToken,
      }
    })

    setQuoteTokenOptions([{ label: 'Quote Token', value: 'all' }, ..._quoteTokenOptions])
    dispatch(setQuoteTokens(_quoteTokens))
  }

  // change quote token
  const onSelectQuoteToken = (item) => {
    if (item.value !== activeQuoteTokenOption.value) {
      setCanFetchMore(true)
      setPageNumber(0)
      setActiveQuoteTokenOption(item)
    }
  }

  const handleAfterMintLoader = () => {
    setMintLoader(!mintLoader)
  }

  useEffect(() => {
    if (!web3 || Object.keys(web3).length === 0) {
      return
    }
    if (address === '' || !address) return
    fetchQuoteTokens()
    fetchUserProfileDetails(address)
    // fetchMyAllNftCollection(address)
    fetchGNFT(address, (addresses && addresses.nftToken) || '')
    dispatch(setCollection({ label: 'Collection', value: 'all' }))

    const connectorKey = window.localStorage.getItem(connectorLocalStorageKey)
    if (!address && !connectorKey) {
      cleanupNfts()
    }

    return () => {
      cleanupNfts()
    }
  }, [address, web3])

  useEffect(() => {
    if (activeLink === 'onSale') {
      fetchMySellNftAssets(address, 'all', activeQuoteTokenAddress, activeArtWorkTypeOpt)
    }
  }, [artworkTypeOpt, activeQuoteTokenOption])

  const displayAlert = (state: string, details: any) => {
    setShowAlert(state)

    setTimeout(
      () => {
        setShowAlert('')
        if (state === 'success2') {
          fetchUserProfileDetails(address)
        } else {
          //  setCanFetchMore(true)
          setMintLoader(false)
          setPageNumber(0)
          // fetchMyAllNftCollection(address)
          //fetchGNFT(address, (addresses && addresses.nftToken) || '')
          if (state !== 'error') addMintNFT(details)
        }
      },
      state === 'success2' ? 2000 : 7000,
    )
  }

  const onSelectNft = (collectionAddress: string, tokenId: string, is_Collection: boolean) => {
    if (is_Collection) {
      setActiveLink('')
      return fetchMyParticularNftCollection(address, collectionAddress)
    }
    if (collectionAddress === '' || tokenId === '') return

    const selectedNft = myNfts.find(
      (row: NftType) => row.collectionAddress === collectionAddress && row.tokenId === tokenId,
    )
    const selectedNFTSale = myOnSaleNFT.find(
      (row: NftType) => row.collectionAddress === collectionAddress && row.tokenId === tokenId,
    )
    const selectedNFTSold = mySoldNFT.find(
      (row: NftType) => row.collectionAddress === collectionAddress && row.tokenId === tokenId,
    )

    const selectedGNFT = GNFT.find(
      (row: NftType) => row.collectionAddress === collectionAddress && row.tokenId === tokenId,
    )

    if (selectedNft) {
      dispatch(setActiveNft(selectedNft))
      history.push(`/assets/${selectedNft.collectionAddress}/${selectedNft.tokenId}`, { detail: 'my-profile' })
    }
    if (selectedNFTSale) {
      dispatch(setActiveNft(selectedNFTSale))
      history.push(`/assets/${selectedNFTSale.collectionAddress}/${selectedNFTSale.tokenId}`, {
        detail: 'my-profile',
      })
    }
    if (selectedNFTSold) {
      dispatch(setActiveNft(selectedNFTSold))
      history.push(`/assets/${selectedNFTSold.collectionAddress}/${selectedNFTSold.tokenId}`, {
        detail: 'my-profile',
      })
    }

    if (selectedGNFT) {
      dispatch(setActiveNft(selectedGNFT))
      history.push(`/assets/${selectedGNFT.collectionAddress}/${selectedGNFT.tokenId}`, {
        detail: 'my-profile',
      })
    }
  }

  const handleLinkChange = (newLink) => {
    if (activeLink === newLink) return

    setActiveLink(newLink)
    switch (newLink) {
      case 'GNFT': {
        fetchGNFT(address, (addresses && addresses.nftToken) || '')
        break
      }
      case 'Collections': {
        fetchMyAllNftCollection(address)

        break
      }
      case 'All': {
        fetchProfileData(address, activeQuoteTokenAddress, activeArtWorkTypeOpt)

        break
      }
      case 'Sold': {
        fetchMySoldNftAssets(address)

        break
      }
      case 'onSale': {
        fetchMySellNftAssets(address, 'all', activeQuoteTokenAddress, activeArtWorkTypeOpt)

        break
      }

      default: {
        return 0
      }
    }
  }

  return (
    <>
      {address ? (
        <>
          {profileLoader ? (
            <div className="p-5 max-w-screen-2xl min-h-screen flex items-center dark:text-white justify-center">
              <ClipLoader color={'dark:text-white'} />
            </div>
          ) : (
            <>
              <div className="border-gray-200 border-b">
                <UserTab
                  activeLink={activeLink}
                  handleLinkChange={handleLinkChange}
                  address={address}
                  displayAlert={displayAlert}
                  isProfileDetailsAvailable={isProfileDetailsAvailable}
                  userDetails={userDetails}
                  web3={web3}
                  profileTabs={profileTabs}
                  isMyProfile={true}
                />
              </div>
              <div className="flex">
                {/*<Sidebar>
                  <Accordion title="Price" icon={<DollarIcon />}>
                    <>
                      {Object.keys(quoteTokenOptions).length > 0 && (
                        <Dropdown
                          list={activeLink === 'onSale' ? quoteTokenOptions : []}
                          selected={
                            activeLink === 'onSale' ? activeQuoteTokenOption : { label: 'Quote Token', value: 'all' }
                          }
                          onSelect={(item) => onSelectQuoteToken(item)}
                        />
                      )}
                    </>
                  </Accordion>
                  <Accordion title="Artwork Type" icon={<ArtworkIcon />}>
                    <>
                      {Object.keys(quoteTokenOptions).length > 0 && (
                        <Dropdown
                          list={activeLink === 'onSale' ? artworkTypeOptions : []}
                          selected={
                            activeLink === 'onSale'
                              ? artworkTypeOpt
                              : { label: 'Select Type', value: '', field: 'artWorkType' }
                          }
                          onSelect={(item) => onChangeSelect(item)}
                        />
                      )}
                    </>
                  </Accordion>
                        </Sidebar> */}
                <section className="w-full px-4 sm:px-8 ">
                  {showAlert !== '' && (
                    <div className="fixed bottom-4 right-4 z-40">
                      <>
                        {showAlert === 'success' && (
                          <Alert icon="success">Your NFT will be added in few seconds...</Alert>
                        )}
                        {showAlert === 'success2' && <Alert icon="success">Edit Profile Successfull</Alert>}
                        {showAlert === 'error' && <Alert icon="danger">Oops! Something went wrong...</Alert>}
                      </>
                    </div>
                  )}

                  <div className="flex items-center pt-5  justify-between">
                    <p className="text-gray-500 dark:text-white text-sm">
                      <span>
                        {activeLink === 'Collections'
                          ? myNFTCollection.length
                          : activeLink === 'All'
                          ? myNfts.length
                          : activeLink === 'Sold'
                          ? mySoldNFT.length
                          : activeLink === 'onSale'
                          ? myOnSaleNFT.length
                          : activeLink === 'GNFT'
                          ? GNFT.length
                          : myNfts.length}
                      </span>
                      <span className="ml-1">results</span>
                    </p>
                    <CreateNFT
                      collectionAddress={(addresses && addresses.nftToken) || ''}
                      displayAlert={displayAlert}
                      handleAfterMintLoader={handleAfterMintLoader}
                    />
                  </div>

                  <div className="pb-4">
                    <ErrorBoundry>
                      <CardList
                        isCollection={activeLink === 'Collections'}
                        nfts={
                          activeLink === 'Collections'
                            ? myNFTCollection
                            : activeLink === 'All'
                            ? myNfts
                            : activeLink === 'Sold'
                            ? mySoldNFT
                            : activeLink === 'onSale'
                            ? myOnSaleNFT
                            : activeLink === 'GNFT'
                            ? GNFT
                            : myNfts
                        }
                        loader={loading}
                        canFetchMore={activeLink === 'Collections' ? false : canFetchMore}
                        fetchMore={fetchMore}
                        onSelectNft={onSelectNft}
                        mintLoader={mintLoader}
                      />
                    </ErrorBoundry>
                  </div>
                </section>
              </div>{' '}
            </>
          )}
        </>
      ) : window.web3 === undefined ? (
        <NoWallet metamask={false} />
      ) : (
        <NoWallet metamask={true} />
      )}
    </>
  )
}

export default MyProfile
