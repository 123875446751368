import React, { useEffect, useState } from 'react'
import useFetchNfts from 'hooks/useFetchNfts'
import { getAllNFTCollections } from 'utils'
import { ClipLoader } from 'react-spinners'
import { Header } from './Components'
import { CardList } from 'components-next'
import { useHistory } from 'react-router'
import { ErrorBoundry } from 'shared-next'
import { NftType } from 'store/types'

const CollectionDetails = (props: any) => {
  const { collectionAddress } = props.match.params
  const {
    loading,
    pageNumber,
    canFetchMore,
    allNfts,
    fetchAllNftAssets,
    fetchMoreAllNftAssets,
    cleanupNfts,
    fetchMore,
  } = useFetchNfts()
  const history = useHistory()
  const [collection, setCollection] = useState({})
  const [isCollectionAvailable, setIsCollectionAvailabe] = useState(false)
  const [collectionLoading, setCollectionLoading] = useState(false)

  const fetchAllNftCollections = async () => {
    if (!collectionAddress) return
    if (collectionAddress === '') return
    setCollectionLoading(true)
    try {
      const _nftCollections = await getAllNFTCollections()
      const activeCollection = _nftCollections.find(
        (item) => item.address.toLowerCase() === collectionAddress.toLowerCase(),
      )
      if (activeCollection && Object.keys(activeCollection).length > 0) {
        setCollection(activeCollection)
        setIsCollectionAvailabe(true)
      }
    } catch (err) {
      setIsCollectionAvailabe(false)
      setCollectionLoading(false)
    }

    setCollectionLoading(false)
  }

  const onSelectNft = (collectionAddress: string, tokenId: string) => {
    if (collectionAddress === '' || tokenId === '') return
    const selectedNft = allNfts.find(
      (row: NftType) => row.collectionAddress === collectionAddress && row.tokenId === tokenId,
    )
    if (selectedNft) {
      history.push(`/assets/${selectedNft.collectionAddress}/${selectedNft.tokenId}`)
    }
  }

  const fetchMarketNftsInfo = async () => {
    await fetchAllNftAssets(collectionAddress, '', '', 0, 999, '', '')
  }

  const fetchMoreMarketNftsInfo = async (page) => {
    fetchMoreAllNftAssets(collectionAddress, '', 0, 999, '', '', '', page)
  }

  useEffect(() => {
    fetchAllNftCollections()
  }, [])

  useEffect(() => {
    fetchMarketNftsInfo()

    return () => {
      cleanupNfts()
    }
  }, [])

  useEffect(() => {
    if (pageNumber > 0) {
      fetchMoreMarketNftsInfo(pageNumber)
    }
  }, [pageNumber])

  return (
    <React.Fragment>
      {collectionLoading ? (
        <div className="p-5 max-w-screen-2xl min-h-screen flex items-center dark:text-white justify-center">
          <ClipLoader color={'dark:text-white'} />
        </div>
      ) : (
        <React.Fragment>
          <div className="border-gray-200 border-b">
            <Header
              collection={collection}
              isCollectionAvailable={isCollectionAvailable}
              collectionAddress={collectionAddress}
            />
          </div>
          <div className="flex">
            <section className="w-full px-4 sm:px-8 ">
              <div className="flex items-center pt-5  justify-start">
                <p className="text-gray-500 dark:text-white text-sm">
                  <span>{allNfts.length}</span>
                  <span className="ml-1">results</span>
                </p>
              </div>
              <div className="pb-4">
                <ErrorBoundry>
                  <CardList
                    isCollection={false}
                    nfts={allNfts}
                    loader={loading}
                    canFetchMore={canFetchMore}
                    fetchMore={fetchMore}
                    onSelectNft={onSelectNft}
                    mintLoader={false}
                  />
                </ErrorBoundry>
              </div>
            </section>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default CollectionDetails
