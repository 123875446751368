import React from 'react'
import { chains, wallets } from '../config'
import { ConnectWallet } from '../types'
import { SelectedIcon } from 'shared-next/Icons'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducers'

const ConnectionModal = ({ handleWalletConnect, handleChain, handleClick, checked }: ConnectWallet) => {
  const activeChainID = useSelector((state: RootState) => state.wallet.activeChainID)

  return (
    <>
      {/* <section className="card-body__item">
              <div className="item">
                <p className="item-count">1</p>
                <div className="item-label">
                  <p>
                    Accept <span>Terms of Services</span> and <span>Privacy Policy</span>
                  </p>
                  <button className="icon" type="button" onClick={handleClick}>
                    <ErrorIcon />
                  </button>
                  {openPopper && <div className="popup-tab">Please agree to the conditions.</div>}
                </div>
              </div>
              <div className="item-detail">
                <label className="form-label flex items-center gap-4">
                  <input type="checkbox" onChange={handleChange} checked={checked} classname="rounded-md" />
                  <span>I read and accept</span>
                </label>
              </div>
            </section> */}

      <section className="flex flex-col mb-4 gap-y-3">
        <div className="flex items-center gap-x-3">
          <p className="bg-purple-200 dark:bg-gray-700  w-8 h-8 p-1 rounded-full text-center dark:text-white">1</p>
          <p className="font-medium dark:text-white">Choose Network</p>
        </div>
        <div className="item-detail">
          <div className="grid grid-cols-3 lg:grid-cols-4">
            {chains &&
              chains.map((chain) => (
                <button
                  type="button"
                  key={chain.label}
                  className="w-full h-full flex flex-col items-center py-4 px-4 rounded-lg cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700 focus:bg-gray-200 dark:focus:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  onClick={() => handleChain(chain)}
                >
                  <div className="relative">
                    <img src={chain.icon} alt={chain.label} className="w-10 h-10" />
                    {activeChainID === chain.value && (
                      <span className="absolute p-0.5 bg-white rounded-full -bottom-1 -right-2 flex items-center justify-center">
                        <SelectedIcon />
                      </span>
                    )}
                  </div>
                  <p className="text-sm mt-1 dark:text-white">{chain.label}</p>
                </button>
              ))}
          </div>
        </div>
      </section>

      <section className="flex flex-col gap-y-3">
        <div className="flex items-center gap-x-3">
          <p className="bg-purple-200 dark:bg-gray-700 w-8 h-8 p-1 rounded-full text-center dark:text-white">2</p>
          <p className="font-medium dark:text-white">Choose Wallet</p>
        </div>
        <div className="item-detail">
          <div className="grid grid-cols-3 lg:grid-cols-4">
            {wallets &&
              wallets.map((wallet) => (
                <button
                  type="button"
                  key={wallet.title}
                  className="w-full h-full flex flex-col items-center py-4 px-4 rounded-lg cursor-pointer hover:bg-gray-200 hover:bg:gray-700 focus:bg-gray-200 dark:hover:bg-gray-700  dark:focus:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  onClick={() => handleWalletConnect(wallet.connectorId!)}
                >
                  <img src={wallet.icon} alt={wallet.title} className="w-10 h-10" />
                  <p className="text-sm mt-1 dark:text-white">{wallet.title}</p>
                </button>
              ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default ConnectionModal
