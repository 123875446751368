import WalletConnectProvider from '@walletconnect/web3-provider'
import Web3 from 'web3'
import Web3Modal from 'web3modal'

export const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: '57bf8e03ef764e97a3f55cb67772c463',
    },
  },
}

export const infuraProvider = new Web3.providers.HttpProvider(
  `https://mainnet.infura.io/v3/57bf8e03ef764e97a3f55cb67772c463}`,
)

export const walletConnectProvider = new WalletConnectProvider({ infuraId: '57bf8e03ef764e97a3f55cb67772c463' })

export const web3Modal = new Web3Modal({
  network: 'mainnet', // optional
  cacheProvider: false, // optional
  providerOptions, // required
})
