import { useMediaQuery } from 'react-responsive'

const useScreen = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 640px)` })
  const isTab = useMediaQuery({ query: `(min-width: 640px) and (max-width: 992px)` })

  return {
    isMobile,
    isTab,
  }
}

export default useScreen
