import { store } from 'store'
import { callUrl } from 'utils/queryHelper'
import { checkForAddress, enableRestApi, addDecimalInPrice } from 'utils'
import axios from 'axios'
import { _gamyFiMarketPlaceClient, getCovalentApiUrl, _gamyFiMyProfileClient } from 'subgraph/client'

import {
  fetchSingleNft,
  fetchCollections,
  fetchMarketNfts,
  fetchUserCollections,
  fetchMarketBids,
  fetchAllUserNfts,
  fetchAllUserOnSaleNfts,
  fetchAllUserSoldNfts,
  fetchUser,
  fetchVotes,
  fetchTransactionHistory,
} from 'services'

import {
  NFT_ASSETS_SEARCH_ALL,
  NFT_ASSETS_SEARCH,
  NFT_ASSETS_SELL,
  ASK_TRANSACTIONS_SEARCH,
  BID_TRANSACTIONS_SEARCH,
  TRADE_TRANSACTIONS_SEARCH,
  ASSERTSEARCH_ALL,
  COLLECTIONSEARCH_ALL,
  NFT_SINGLE_ASSETS_SEARCH,
  NFT_COLLECTIONS_SEARCH_ALL,
} from 'subgraph/queries'
import {
  MY_NFT_ASSET,
  My_SOLD_NFT_ASSET,
  My_All_NFT_COLLECTIONS_ASSET,
  My_PARTICULAR_COLLECTIONS_NFT_ASSET,
} from 'subgraph/queries/profile'

const DEFAULT_PER_PAGE = 8

const getSortOption = (key) => {
  if (key === 'all') return ''
  if (key === 'price-ASC') return `orderBy: price, orderDirection: asc`
  if (key === 'price-DESC') return `orderBy: price, orderDirection: desc`
  if (key === 'like-ASC') return `orderBy: voteCnt, orderDirection: asc`
  if (key === 'like-DESC') return `orderBy: voteCnt, orderDirection: desc`
  return ''
}

/**
 * Api to Fetch all nfts Collections
 */
export const getAllNFTCollections = async () => {
  try {
    const { wallet } = store.getState()
    const { activeChainID } = wallet
    if (activeChainID !== null) {
      if (enableRestApi()) {
        const filter_set = {
          chainId: activeChainID,
          limit: 8,
          page: 0,
        }

        const query = callUrl('', filter_set)
        const { data } = await fetchCollections(query)
        const { rows } = data
        return rows.map((row) => ({
          id: row.id,
          address: row.address,
          name: row.name,
          contractUri: '',
          image: row.image,
          nftsCount: row.total,
        }))
      } else {
        const res = await _gamyFiMarketPlaceClient(activeChainID).request(NFT_COLLECTIONS_SEARCH_ALL())
        return res.tokenCollections
      }
    }
    return []
  } catch (error) {
    console.error('getAllNFTCollections error: ', error)
    return []
  }
}

/**
 * Api to fetch Assets
 */
export const getAllNFTAssets = async ({
  collectionAddress,
  quoteToken,
  pageNumber,
  perPage = DEFAULT_PER_PAGE,
  sortOption,
  priceGt,
  priceLt,
  category,
  artWork,
}: any) => {
  try {
    const { wallet } = store.getState()
    const { activeChainID } = wallet
    if (activeChainID !== null) {
      if (enableRestApi()) {
        const filter_set = {
          chainId: activeChainID,
          status: 'Ask',
          limit: perPage,
          page: pageNumber * perPage,
          quoteToken: quoteToken,
          artworkType: artWork,
          contract: collectionAddress,
          category,
          sortOption,
          priceGt: addDecimalInPrice(quoteToken, Number(priceGt)),
          priceLt: priceLt === 0 ? undefined : addDecimalInPrice(quoteToken, Number(priceLt)),
        }

        const query = callUrl('', filter_set)

        const { data } = await fetchMarketNfts(query)
        const { rows } = data as any

        return rows.map((row) => ({
          id: row.id,
          tokenId: row.token.nftId,
          artistName: row.token.artistName,
          artworkName: row.token.name,
          artworkType: row.token.artworkType,
          category: row.details.category,
          creator: row.token.creator || row.token.owner,
          description: row.token.bio,
          price: row.details.price,
          quoteToken: row.quoteToken,
          tokenCollection: row.token.collection.address,
          tokenImage: row.token.image,
          owner: row.token.owner,
          voteCnt: row.token.like,
          love: row.token.love,
          isDetailed: row.token.isDetailed,
          tokenUri: row.token.uri,
        }))
      } else {
        const res = await _gamyFiMarketPlaceClient(activeChainID).request(
          NFT_ASSETS_SEARCH_ALL({
            collectionAddress,
            quoteToken,
            pageNumber,
            perPage,
            sortOption: getSortOption(sortOption),
            priceGt,
            priceLt,
            category,
            artWork,
          }),
        )

        return res.tokenAssets.map((row) => ({
          id: row.id,
          tokenId: row.tokenId,
          artistName: row.artistName,
          artworkName: row.name,
          artworkType: row.artworkType,
          category: row.category,
          creator: row.creator.address,
          description: row.description,
          price: row.price,
          quoteToken: row.quoteToken,
          tokenCollection: row.tokenCollection.address,
          tokenImage: row.image,
          owner: row.owner.address,
          voteCnt: row.voteCnt,
          love: row.love,
          isDetailed: row.isDetailed,
          tokenUri: row.tokenUri,
        }))
      }
    }
    return []
  } catch (error) {
    console.error('getAllNFTAssets error: ', error)
    return []
  }
}

/**
 * Fetch Market Nfts from given Address
 */
export const getAllNFTAssetsFromAddress = async ({
  account,
  collectionAddress,
  quoteToken,
  pageNumber,
  perPage = DEFAULT_PER_PAGE,
  sortOption,
  category,
}: {
  account: string
  collectionAddress: string
  quoteToken: string
  pageNumber?: number
  perPage?: number
  sortOption?: string
  category?: string
}) => {
  try {
    const { wallet } = store.getState()
    const { activeChainID } = wallet
    if (activeChainID !== null) {
      if (enableRestApi()) {
        const filter_set = {
          chainId: activeChainID,
          owner: account,
          limit: perPage,
          page: pageNumber * perPage,
          quoteToken: quoteToken,
          contract: collectionAddress,
          category,
          sortOption,
          status: 'Ask',
        }

        const query = callUrl('', filter_set)
        const { data } = await fetchMarketNfts(query)
        const { rows } = data as any
        return rows.map((row) => ({
          id: row.id,
          tokenId: row.token.nftId,
          artistName: row.token.artistName,
          artworkName: row.token.name,
          artworkType: row.token.artworkType,
          category: row.details.category,
          creator: row.token.creator || row.token.owner,
          description: row.token.bio,
          price: row.details.price,
          quoteToken: row.quoteToken,
          tokenCollection: row.token.collection.address,
          tokenImage: row.token.image,
          owner: row.token.owner,
          voteCnt: row.token.like,
          love: row.token.love,
          isDetailed: row.token.isDetailed,
          tokenUri: row.token.uri,
        }))
      } else {
        const res = await _gamyFiMarketPlaceClient(activeChainID).request(
          NFT_ASSETS_SEARCH({
            account,
            collectionAddress,
            quoteToken,
            pageNumber,
            perPage,
            sortOption: getSortOption(sortOption),
            category,
          }),
        )

        return res.tokenAssets.map((row) => ({
          id: row.id,
          tokenId: row.tokenId,
          artistName: row.artistName,
          artworkName: row.name,
          artworkType: row.artworkType,
          category: row.category,
          creator: row.creator.address,
          description: row.description,
          price: row.price,
          quoteToken: row.quoteToken,
          tokenCollection: row.tokenCollection.address,
          tokenImage: row.image,
          owner: row.owner.address,
          voteCnt: row.voteCnt,
          love: row.love,
          isDetailed: row.isDetailed,
          tokenUri: row.tokenUri,
        }))
      }
    }
    return []
  } catch (error) {
    console.error('getAllNFTAssetsFromAddress Error: ', error)
    return []
  }
}

/**
 * Fetch user on Sale Assets
 */
export const getAllSellNFTAssetsFromAddress = async ({
  account,
  quoteToken,
  artWork,
  pageNumber,
  perPage = DEFAULT_PER_PAGE,
  sortOption,
}: {
  account: string
  quoteToken: string
  artWork: string
  pageNumber?: number
  perPage?: number
  sortOption?: string
}) => {
  try {
    const { wallet } = store.getState()
    const { activeChainID } = wallet
    if (activeChainID !== null) {
      if (enableRestApi()) {
        const filter_set = {
          chainId: activeChainID,
          owner: account,
          limit: 8,
          page: pageNumber * perPage,
          quoteToken: quoteToken,
          artworkType: artWork,
          sortOption,
        }

        const query = callUrl('', filter_set)
        console.log('DEBUG-fetch-on-sale', query)
        const { data } = await fetchAllUserOnSaleNfts(query)
        const { rows } = data as any

        return rows.map((row) => ({
          id: row.id,
          tokenId: row.token.nftId,
          artistName: row.token.artistName,
          artworkName: row.token.name,
          artworkType: row.token.artworkType,
          category: row.details.category,
          creator: row.token.creator || row.token.owner,
          description: row.token.bio,
          price: row.details.price,
          quoteToken: row.quoteToken,
          tokenCollection: row.token.collection.address,
          tokenImage: row.token.image,
          owner: row.token.owner,
          voteCnt: row.token.like,
          love: row.token.love,
          isDetailed: row.token.isDetailed,
          tokenUri: row.token.uri,
        }))
      } else {
        const res = await _gamyFiMarketPlaceClient(activeChainID).request(
          NFT_ASSETS_SELL({
            account,
            quoteToken,
            artwork: artWork,
            pageNumber,
            perPage,
            sortOption: getSortOption(sortOption),
          }),
        )
        return res.tokenAssets.map((row) => ({
          id: row.id,
          tokenId: row.tokenId,
          tokenUri: row.tokenUri,
          tokenCollection: row.tokenCollection.address,
          creator: row.creator.address,
          owner: row.owner.address,
          price: row.price,
          quoteToken: row.quoteToken,
          voteCnt: row.voteCnt,
          artworkType: row.artworkType,

          artworkName: row.name,
          artistName: row.artistName,
          description: row.description,
          category: row.category,

          tokenImage: row.image,
          love: row.love,
          isDetailed: row.isDetailed,
        }))
      }
    }
    return []
  } catch (error) {
    console.error('getAllSellNFTAssetsFromAddress Error: ', error)
    return []
  }
}

/**
 * Fetch user on Sold Assets
 */
export const getAllSoldNFTAssetsFromAddress = async ({ account }: { account: string }) => {
  try {
    const { wallet } = store.getState()
    const { activeChainID } = wallet
    if (activeChainID !== null) {
      if (enableRestApi()) {
        const filter_set = {
          chainId: activeChainID,
          owner: account,
          page: 0,
          limit: 8,
          status: 'Sell',
        }

        const query = callUrl('', filter_set)

        const { data } = await fetchAllUserSoldNfts(query)
        const { rows } = data as any

        return rows.map((row) => ({
          id: row.id,
          tokenId: row.token.nftId,
          artistName: row.token.artistName,
          artworkName: row.token.name,
          artworkType: row.token.artworkType,
          category: row.token.category,
          creator: row.token.creator || row.token.owner,
          description: row.token.bio,
          price: row.details.price,
          quoteToken: row.quoteToken,
          tokenCollection: row.token.collection.address,
          tokenImage: row.token.image,
          owner: row.token.owner,
          voteCnt: row.token.like,
          love: row.token.love,
          isDetailed: row.token.isDetailed,
          tokenUri: row.token.uri,
        }))
      } else {
        const res = await _gamyFiMarketPlaceClient(activeChainID).request(
          My_SOLD_NFT_ASSET({
            account,
          }),
        )
        return res.tradeTransactions.map((row) => ({
          id: row.id || row.token.tokenId || '',
          tokenId: row.token.token.tokenId,
          tokenUri: row.token.tokenUri || '',
          tokenCollection: row.token.tokenCollection.address || row.tokenCollection.id || '',
          creator: row.creator.address || '',
          owner: row.owner.address || '',
          price: row.price || '',
          quoteToken: row.quoteToken || '',
          voteCnt: row.voteCnt || '',
          artworkType: row.token.artworkType,

          artworkName: row.token.name,
          artistName: row.token.artistName || '',
          description: row.token.description || '',
          category: row.token.category || '',

          tokenImage: row.token.image,
          love: row.love,
          isDetailed: row.isDetailed,
        }))
      }
    }
    return []
  } catch (error) {
    console.error('getAllSoldNFTAssetsFromAddress Error: ', error)
    return []
  }
}

/**
 * Fetching Asset's Collections of given address
 */
export const getAllNFTAssetsCollectionFromAddress = async ({ account }: { account: string }) => {
  try {
    const { wallet } = store.getState()
    const { activeChainID } = wallet
    if (activeChainID !== null) {
      const filter_set = {
        chainId: activeChainID,
        owner: account,
        page: 0,
        limit: 8,
      }

      const query = callUrl('', filter_set)
      const { data } = await fetchUserCollections(query)
      const { rows } = data as any

      return rows.map((row) => ({
        id: row.id,
        tokenCollection: row.address,
        image: row.image,
        name: row.name,
        symbol: row.symbol,
        nftCount: row.tokens.length,
      }))
    }
    return []
  } catch (error) {
    console.error('getAllNFTAssetsCollectionFromAddress Error', error)
    return []
  }
}

/**
 * Fetch Assets of given Collection Address & User address
 */
export const getParticularCollectionNFT = async ({
  account,
  contract_address,
}: {
  account: string
  contract_address: string
}) => {
  try {
    const { wallet } = store.getState()
    const { activeChainID } = wallet
    if (activeChainID !== null) {
      const filter_set = {
        chainId: activeChainID,
        owner: account,
        contract: contract_address,
        page: 0,
        limit: 8,
      }

      const query = callUrl('', filter_set)
      const { data } = await fetchUserCollections(query)
      const { rows } = data as any

      return rows[0].tokens.map((row) => ({
        id: row.id,
        tokenId: row.nftId,
        artistName: row.artistName,
        artworkName: row.name,
        artworkType: row.artworkType,
        category: row.category,
        creator: row.creator || row.owner,
        description: row.bio,
        tokenCollection: contract_address,
        tokenImage: row.image,
        owner: row.owner,
        isDetailed: row.isDetailed,
        tokenUri: row.token.uri,
      }))
    }
    return []
  } catch (error) {
    console.error('getParticularCollectionNFT Error: ', error)
    return []
  }
}

export const getAskTransactions = async (contract: string, tokenId: string, auctionId: number) => {
  try {
    const { wallet } = store.getState()
    const { activeChainID } = wallet
    if (activeChainID !== null) {
      if (enableRestApi()) {
        const filter_set = {
          contract: contract || null,
          tokenId: tokenId || null,
          chainId: activeChainID,
          limit: 8,
          status: 'Ask',
        }

        const query = callUrl('', filter_set)

        const res = await fetchMarketNfts(query)
        const { data } = res as any

        return data.rows.map((row) => ({
          id: row.id,
          tokenId: row.token.nftId,
          artistName: row.token.artistName,
          artworkName: row.token.name,
          artworkType: row.token.artworkType,
          category: row.token.category,
          creator: row.token.creator || row.token.owner,
          description: row.token.bio,
          price: row.details.price,
          quoteToken: row.quoteToken,
          tokenCollection: row.token.collection.address,
          tokenImage: row.token.image,
          owner: row.token.owner,
          voteCnt: row.token.like,
          love: row.token.love,
          isDetailed: row.token.isDetailed,
          tokenUri: row.token.uri,
        }))
        // const res = await _gamyFiMarketPlaceClient(activeChainID).request(ASK_TRANSACTIONS_SEARCH({ tokenId, auctionId }))
        // return res.askTransactions
      } else {
        const res = await _gamyFiMarketPlaceClient(activeChainID).request(
          ASK_TRANSACTIONS_SEARCH({ tokenId, auctionId }),
        )
        return res.askTransactions
      }
    }
    return []
  } catch (error) {
    console.error('getAskTransactions Error:', error)
    return []
  }
}

export const getBidTransactions = async (tokenId: string, auctionId: number) => {
  try {
    const { wallet } = store.getState()
    const { activeChainID } = wallet
    if (activeChainID !== null) {
      if (enableRestApi()) {
        const token_id = tokenId.split('-')
        const filter_set = {
          chainId: activeChainID,
          //  limit: 8,
          status: 'Ask',
          auctionId,
          tokenId: token_id[1],
        }

        const query = callUrl('', filter_set)

        const res = await fetchMarketBids(query)

        const { data } = res as any

        return data.rows.map((row) => ({
          price: row.price,
          buyer: {
            address: row.buyer,
          },
          quoteToken: row.quoteToken,
        }))
      } else {
        const res = await _gamyFiMarketPlaceClient(activeChainID).request(
          BID_TRANSACTIONS_SEARCH({ tokenId, auctionId }),
        )
        return res.bidTransactions
      }
    }
    return []
  } catch (error) {
    console.error('getBidTransactions Error: ', error)
    return []
  }
}

export const getTradeTransactions = async (tokenId: string, collectionAddress: string) => {
  try {
    const { wallet } = store.getState()
    const { activeChainID } = wallet
    if (activeChainID !== null) {
      if (enableRestApi()) {
        const filter_set = {
          chainId: activeChainID,
          collectionAddress,

          tokenId: Number(tokenId),
        }

        const query = callUrl('', filter_set)
        const res = await fetchTransactionHistory(query)
        const { data } = res

        return data.rows.map((row) => ({
          price: row.details.price,
          seller: {
            address: row.details.seller,
          },
          buyer: {
            address: row.details.buyer ? row.details.buyer : 'NA',
          },
          quoteToken: row.quoteToken,
          tradeDate: row.details.tradeDate,
          type: row.eventType,
        }))
      } else {
        const res = await _gamyFiMarketPlaceClient(activeChainID).request(TRADE_TRANSACTIONS_SEARCH({ tokenId }))
        return res.tradeTransactions
      }
    }
    return []
  } catch (error) {
    console.error('getTradeTransactions Error:', error)
    return []
  }
}

/**
 * Fetching Single Asset
 */
export const getSingleNFT = async ({ collectionAddress, tokenId }: { collectionAddress: string; tokenId: string }) => {
  try {
    const { wallet } = store.getState()
    const { activeChainID } = wallet
    if (activeChainID !== null) {
      if (enableRestApi()) {
        const arr = []
        let d = {}
        const filter_set = {
          chainId: activeChainID,
          contract: collectionAddress,
          tokenId,
          status: 'Ask',
        }

        const query = callUrl('', filter_set)
        const { data: marketRes } = await fetchMarketNfts(query)
        if (marketRes.count > 0) {
          d = {
            ...marketRes.rows[0],
            id: marketRes.rows[0].id,
            tokenId: marketRes.rows[0].token.nftId,
            artistName: marketRes.rows[0].token.artistName,
            artworkName: marketRes.rows[0].token.name,
            artworkType: marketRes.rows[0].token.artworkType,
            category: marketRes.rows[0].details.category,
            creator: marketRes.rows[0].token.creator || marketRes.rows[0].token.owner,
            description: marketRes.rows[0].token.bio,
            price: marketRes.rows[0].details.price,
            quoteToken: marketRes.rows[0].quoteToken,
            tokenCollection: marketRes.rows[0].token.collection.address,
            tokenImage: marketRes.rows[0].token.image,
            owner: marketRes.rows[0].token.owner,
            voteCnt: marketRes.rows[0].token.like,
            love: marketRes.rows[0].token.love,
            isDetailed: marketRes.rows[0].token.isDetailed,
            tokenUri: marketRes.rows[0].token.uri,
          }
          arr.push(d)
          return arr
        }

        const { data: res } = (await fetchSingleNft(query)) as any

        if (res === null) return []

        d = {
          ...res,

          id: res.id,
          tokenId: res.nftId,
          artistName: res.artistName,
          artworkName: res.name,
          artworkType: res.artworkType,
          category: '',
          creator: res.creator || res.owner,
          description: '',
          price: '',
          quoteToken: '',
          tokenCollection: res.collection.address,
          tokenImage: res.image,
          owner: res.owner,
          voteCnt: res.like,
          love: res.love,
        }

        arr.push(d)
        return arr
      } else {
        const res = await _gamyFiMarketPlaceClient(activeChainID).request(
          NFT_SINGLE_ASSETS_SEARCH({ collectionAddress, tokenId }),
        )

        return res.tokenAssets.map((row) => ({
          id: row.id,
          tokenId: row.tokenId,
          tokenUri: row.tokenUri,
          tokenCollection: row.tokenCollection.address,
          creator: row.creator.address,
          owner: row.owner.address,
          price: row.price,
          quoteToken: row.quoteToken,
          voteCnt: row.voteCnt,
          artworkType: row.artworkType,

          artworkName: row.name,
          artistName: row.artistName,
          description: row.description,
          category: row.category,

          tokenImage: row.image,
          love: row.love,
          isDetailed: row.isDetailed,
        }))
      }
    }

    return []
  } catch (error) {
    console.error('getSingleNFT Error: ', error)
    return []
  }
}

/**
 * Fetching Assets of given address
 */
export const getMyNFTAsset = async ({
  account,
  quoteToken,
  artWork,
}: {
  account: string
  quoteToken: string
  artWork: string
}) => {
  try {
    const { wallet } = store.getState()
    const { activeChainID } = wallet
    if (activeChainID !== null) {
      if (enableRestApi()) {
        const filter_set = {
          chainId: activeChainID,
          owner: account,
          quoteToken: quoteToken,
          artworkType: artWork,
          page: 0,
          limit: 8,
        }

        const query = callUrl('', filter_set)
        const { data } = await fetchAllUserNfts(query)
        const { rows } = data as any

        return rows.map((row) => ({
          id: row.id,
          tokenId: row.nftId,
          artistName: row.artistName,
          artworkName: row.name,
          artworkType: row.artworkType,
          category: row.category,
          creator: row.creator || row.owner,
          description: row.bio,
          price: row.price,
          quoteToken: row.quoteToken,
          tokenCollection: row.collection.address,
          tokenImage: row.image,
          owner: row.owner,
          voteCnt: row.like,
          love: row.love,
          isDetailed: row.isDetailed,
          tokenUri: row.uri,
        }))
      } else {
        const res = await _gamyFiMyProfileClient(activeChainID).request(
          MY_NFT_ASSET({ account, quoteToken, artwork: artWork }),
        )
        return res.owner.tokens
      }
    }
    return []
  } catch (error) {
    console.error('getMyNFTAsset Error: ', error)
    return []
  }
}

/**
 * Fetching GAssets of given address
 */
export const getGNFTAsset = async ({ account, contract }: { account: string; contract: string }) => {
  try {
    const { wallet } = store.getState()
    const { activeChainID } = wallet
    if (activeChainID !== null) {
      const filter_set = {
        chainId: activeChainID,
        owner: account,
        contract,
        page: 0,
      }

      const query = callUrl('', filter_set)
      const { data } = await fetchAllUserNfts(query)
      const { rows } = data as any

      return rows.map((row) => ({
        id: row.id,
        tokenId: row.nftId,
        artistName: row.artistName,
        artworkName: row.name,
        artworkType: row.artworkType,
        category: row.category,
        creator: row.creator || row.owner,
        description: row.bio,
        price: row.price,
        quoteToken: row.quoteToken,
        tokenCollection: row.collection.address,
        tokenImage: row.image,
        owner: row.owner,
        voteCnt: row.like,
        love: row.love,
        isDetailed: row.isDetailed,
        tokenUri: row.uri,
      }))
    }
    return []
  } catch (error) {
    console.error('getGNFTAsset Error: ', error)
    return []
  }
}

/**
 * Fetching search query Assets & Collections
 */
export const getAllSearchResult = async ({ query }: { query: string }) => {
  try {
    const { wallet } = store.getState()
    const { activeChainID } = wallet
    if (activeChainID !== null) {
      const result = []

      if (enableRestApi()) {
        const isAddress = checkForAddress(query)

        const { data: assets } = await fetchMarketNfts(
          isAddress
            ? `?contract=${query}&page=0&limit=100&chainId=${activeChainID}`
            : `?search=${query}&page=0&limit=100&chainId=${activeChainID}`,
        )

        const { rows: res1 } = assets as any

        const data1 = res1.map((row) => ({
          id: row.id,
          tokenId: row.token.nftId,
          artworkName: row.token.name,
          creator: row.token.creator || row.token.owner,
          tokenCollection: row.token.collection.address,
          image: row.token.image,
          owner: row.token.owner,
          isDetailed: row.token.isDetailed,
          tokenUri: row.token.uri,
        }))

        const { data: collections } = await fetchCollections(
          isAddress
            ? `?address=${query}&page=0&limit=100&chainId=${activeChainID}`
            : `?search=${query}&page=0&limit=100&chainId=${activeChainID}`,
        )
        const { rows: res2 } = collections as any
        const data2 = res2.map((row) => ({
          id: row.id,
          name: row.name,
          creator: row.owner,
          address: row.address,
          image: row.image,
          owner: row.owner,
          total: row.total,
          symbol: row.symbol,
        }))

        result.push({ name: 'Collection', data: data2 })
        result.push({ name: 'Assets', data: data1 })

        return result
      } else {
        const res = await _gamyFiMarketPlaceClient(activeChainID).request(ASSERTSEARCH_ALL({ query }))
        const data2 = res.tokenAssets.map((row) => ({
          id: row.id,
          tokenId: row.tokenId,
          artworkName: row.artworkName,
          tokenCollection: row.tokenCollection.address,
          image: row.image,
          tokenUri: row.tokenUri,
        }))

        const col = await _gamyFiMarketPlaceClient(activeChainID).request(COLLECTIONSEARCH_ALL({ query }))
        result.push({ name: 'Collection', data: col.tokenCollections })
        result.push({ name: 'Assets', data: data2 })
        return result
      }
    }
    return []
  } catch (error) {
    console.error('getAllSearchResult Error: ', error)
    return []
  }
}

/*
 * Fetch User Profile Details
 */
export const fetchUserDetails = async ({ address }: { address: string }) => {
  const { data } = await fetchUser(address)

  return {
    address: data.address,
    bio: data.bio,
    coverImage: data.coverImage,
    details: data.details || {},
    email: data.email,
    isVerified: data.isVerified,
    name: data.name,
    profileImage: data.profileImage,
  }
}

/**
 * Fetching Assets and Collections of given address using covalent api
 */
export const covalentData = async (account) => {
  const { wallet } = store.getState()
  const { activeChainID } = wallet

  if (activeChainID !== null) {
    const url = getCovalentApiUrl(activeChainID, account)
    const res = await axios.get(url)
    return res.data
  }
  return []
}

/**
 * Fetch Vote data
 */
export const isEligibleToVote = async (owner, tokenIds) => {
  try {
    const filter_set = {
      owner: owner,
      tokenIds: tokenIds.join(','),
    }
    const query = callUrl('', filter_set)
    const { data } = await fetchVotes(query)
    if (data.count > 0) return false
    else true
  } catch (error) {
    console.error('isEligibleToVote error: ', error)
    return false
  }
}
