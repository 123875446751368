import React from 'react'
import Skeleton from 'react-loading-skeleton'

const SkeletonCard = ({ layout }: { layout?: 'grid' | 'flex' }) => {
  return (
    <article
      className={`${
        layout === 'flex' ? 'md:w-80 md:max-w-xs' : ''
      } border border-gray-200 text-gray-800 min-w-0 rounded-lg p-2 cursor-pointer transform transition-all hover:shadow-md hover:-translate-y-1 flex-shrink-0`}
      style={{ height: '26rem' }}
    >
      <section className="h-72 rounded-md overflow-hidden">
        <Skeleton height={504} />
      </section>
      <section className="flex flex-col gap-y-4">
        <section className="flex items-center justify-between mt-1">
          <div className="flex flex-col">
            <p className="font-medium">
              <Skeleton count={2} width={150} />
            </p>
          </div>
          <div className="flex items-center">
            <Skeleton />
            <p className="font-medium">
              <Skeleton width={20} height={30} />
            </p>
          </div>
        </section>
        <section className="flex">
          <div className="flex flex-col">
            <p className="text-xs text-gray-600 font-medium"></p>
            <p className="font-medium">
              <Skeleton width={50} height={30} />
            </p>
          </div>
        </section>
      </section>
    </article>
  )
}

export default SkeletonCard
