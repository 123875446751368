import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NftType } from 'types'
import { getTokenNameFromAddress } from 'utils'
import useFetchNfts from 'hooks/useFetchNfts'
import { setActiveNft, setAllNfts } from 'store/actions'
import { RootState } from 'store/reducers'
import { Vote } from 'shared-next'
import { ClipLoader } from 'react-spinners'
import Np_pic from 'assets-next/images/defaultCollection.jpg'
import { getKeyValue } from 'components-next/Marketplace/utils'

interface CardProps extends React.HTMLProps<HTMLDivElement> {
  nft: NftType
  lastNFTRef?: (node: any) => void
  onSelectNft: (collectionAddress: string, tokenId: string, is_Collection: boolean) => void
  layout?: 'grid' | 'flex'
}

const Card = ({ nft, lastNFTRef = null, onSelectNft, layout }: CardProps) => {
  const dispatch = useDispatch()
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)
  const [canVote, setCanVote] = useState<boolean>(true)
  const { address } = useFetchNfts()
  const nftReducer = useSelector((state: RootState) => state.nft)
  const { allNfts } = nftReducer
  const walletReducer = useSelector((state: RootState) => state.wallet)
  const { contracts } = walletReducer
  const { exchangeSC } = contracts

  const getCanVote = async () => {
    if (!exchangeSC) return

    if (exchangeSC && exchangeSC._address !== null && Object.keys(nft).length > 0) {
      const _canVote = await exchangeSC.methods.getUserCanVote(address, nft.collectionAddress, nft.tokenId).call()
      setCanVote(_canVote)
    }
  }

  useEffect(() => {
    if (!address) return
    getCanVote()
  }, [address])

  const onVote = async (e) => {
    e.stopPropagation()
    if (!canVote) {
      return
    }
    const result = await exchangeSC.methods.voteToken(nft.collectionAddress, nft.tokenId).send({ from: address })
    if (result) {
      dispatch(setActiveNft({ ...nft, voteCnt: nft.voteCnt + 1 }))

      const _allNfts = allNfts.map((row) => {
        return {
          ...row,
          voteCnt:
            nft.collectionAddress === row.collectionAddress && nft.tokenId === row.tokenId
              ? Number(row.voteCnt) + 1
              : row.voteCnt,
        }
      })
      dispatch(setAllNfts(_allNfts))
    }
  }

  return (
    <article
      ref={lastNFTRef}
      className={`${
        layout === 'flex' ? 'md:w-80 md:max-w-xs' : ''
      } border border-gray-200 text-gray-800 min-w-0 rounded-lg p-2 cursor-pointer transform transition-all hover:shadow-md hover:-translate-y-1 flex-shrink-0`}
      style={{ height: '26rem' }}
      onClick={() => onSelectNft(nft.collectionAddress || '', nft.tokenId || '', nft.is_Collection || false)}
    >
      {nft.is_Collection ? (
        <React.Fragment>
          <section className="h-72 rounded-md overflow-hidden">
            <div
              className={`h-full w-full flex items-center justify-center ${!imageLoaded ? 'align-middle py-16' : ''}`}
            >
              <img
                src={nft.image}
                alt={`Collection_${nft.name}`}
                className={`w-full h-full object-fill ${imageLoaded ? 'visible ' : 'hidden  '}`}
                onLoad={() => setImageLoaded(true)}
              />
              {!imageLoaded && <ClipLoader color="#8b5cf6" />}
            </div>
          </section>
          <section className="flex flex-col gap-y-4 min-w-0">
            <section className="flex items-center justify-center mt-1 min-w-0 text-xl dark:text-white pt-4">
              {nft.name}
            </section>
          </section>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <section className="h-72 rounded-md overflow-hidden">
            {nft.artWorkType === 'video' ? (
              <div className={`h-full w-full flex items-center justify-center`}>
                <video controls className={`w-full h-full object-contain`}>
                  <source src={nft.image} />
                </video>
              </div>
            ) : (
              <div
                className={`h-full w-full flex items-center justify-center ${
                  !(nft.artWorkType === 'video') && !imageLoaded ? 'align-middle py-16' : ''
                }`}
              >
                <picture className={`w-full h-full object-contain ${imageLoaded ? 'visible ' : 'hidden  '}`}>
                  <source srcSet={nft.image} onLoad={() => setImageLoaded(true)} />
                  <img
                    src={nft.image}
                    alt={`artwork_${nft.tokenId}`}
                    className={`w-full h-full object-contain ${imageLoaded ? 'visible ' : 'hidden  '}`}
                    onLoad={() => setImageLoaded(true)}
                  />
                </picture>
                {nft.artWorkType !== 'video' && (
                  <React.Fragment>{!imageLoaded && <ClipLoader color="#8b5cf6" />}</React.Fragment>
                )}
              </div>
            )}
          </section>
          <section className="flex flex-col gap-y-4 min-w-0 dark:text-white">
            <section className="flex items-center justify-between mt-1 min-w-0">
              <div className="flex flex-col min-w-0">
                <p className="truncate capitalize font-semibold" title={nft.artworkName}>
                  {getKeyValue(nft, 'artworkName')}
                </p>
                <p className="text-xs text-gray-500 font-medium capitalize">{getKeyValue(nft, 'artistName')}</p>
              </div>
            </section>
            <section className="flex items-center justify-between">
              <div className="flex flex-col">
                {nft.price && nft.quoteToken ? (
                  <>
                    <p className="text-xs text-gray-500 font-medium">Price</p>
                    <p className="font-medium">{`${nft.price} ${getTokenNameFromAddress(nft.quoteToken)}`}</p>
                  </>
                ) : (
                  <>
                    <p className="text-xs text-gray-500 font-medium">Price</p>
                    <p className="font-medium">NA</p>
                  </>
                )}
              </div>
              {nft.voteCnt && (
                <Vote canVote={canVote} count={Number(nft.voteCnt)} sizing="sm" onClick={(e) => onVote(e)} />
              )}
            </section>
          </section>
        </React.Fragment>
      )}
    </article>
  )
}

export default React.memo(Card)
