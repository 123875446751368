export const callUrl = (params, filter_set) => {
  for (const key in filter_set) {
    if (filter_set[key] !== '') {
      params = addToParam(params, key, filter_set[key])
    }
  }

  return params
}

const addToParam = (params, newParamName, newParamValue) => {
  if (newParamValue || newParamValue === 0) {
    if (params) params = params + '&'
    else params = params + '?'
    params = params + newParamName + '=' + newParamValue
  }
  return params
}
