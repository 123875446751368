import * as actionTypes from '../constants'
import { IWalletState, WalletActionTypes } from '../types'

const initialState: IWalletState = {
  isLoading: false,
  web3: null,
  address: '',
  provider: null,
  accountInfo: {},
  contracts: {},
  nftTotalSupply: 0,
  activeChainID: Number(window.localStorage.getItem('chainId')) || 43114,
  contractsAddress: {},
  supportedChain: [56, 43114, 42161, 1285, 1284, 250, 592, 336],
}

function walletReducer(state = initialState, action: WalletActionTypes) {
  switch (action.type) {
    case actionTypes.ACTION_WALLET_INIT:
      return initialState
    case actionTypes.ACTION_WALLET_LOADING:
      return { ...state, isLoading: action.payload }
    case actionTypes.ACTION_WALLET_SET_WEB3:
      return { ...state, web3: action.payload }
    case actionTypes.ACTION_WALLET_SET_ADDRESS:
      return { ...state, address: action.payload }
    case actionTypes.ACTION_WALLET_SET_PROVIDER:
      return { ...state, provider: action.payload }
    case actionTypes.ACTION_WALLET_SET_ACCOUNT_INFO:
      return {
        ...state,
        accountInfo: { ...state.accountInfo, ...action.payload },
      }
    case actionTypes.ACTION_WALLET_SET_CONTRACTS:
      return { ...state, contracts: action.payload }

    case actionTypes.ACTION_WALLET_SET_NFT_TOTAL_SUPPLY:
      return { ...state, nftTotalSupply: action.payload }
    case actionTypes.ACTION_WALLET_SET_ACTIVE_CHAIN:
      return { ...state, activeChainID: action.payload }
    default:
      return state
  }
}

export default walletReducer
