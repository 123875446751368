import { CardList } from 'components-next'
import { getNftsWithDetail } from 'components-next/Marketplace/constants'
import { connectorLocalStorageKey } from 'components-next/WalletModal/config'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Accordion, ErrorBoundry } from 'shared-next'
import { RootState } from 'store/reducers'
import { NftType } from 'store/types'
import { getAllNFTAssets } from 'utils'

const Recommendation = ({
  contract_id,
  token_id,
  notFound,
  NFTloading,
}: {
  contract_id: string
  token_id: string
  notFound: boolean
  NFTloading: boolean
}) => {
  const history = useHistory()
  const [allNfts, setAllNfts] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [canFetchMore, setCanFetchMore] = useState<boolean>(true)

  // global data (reducer data)
  const web3 = useSelector((state: RootState) => state.wallet.web3)
  const address = useSelector((state: RootState) => state.wallet.address)
  const contracts = useSelector((state: RootState) => state.wallet.contracts)

  const { exchangeSC: exchangeNftSC } = contracts

  const fetchAllNftAssets = async () => {
    const sortOption = ''
    const priceGt = 0
    const priceLt = 999
    setLoading(true)
    const _nfts = await getAllNFTAssets({
      collectionAddress: contract_id.toLowerCase(),
      quoteToken: null,
      pageNumber,
      sortOption,
      priceGt,
      priceLt,
    })

    let nftsWithDetail = []
    if (_nfts) {
      nftsWithDetail = await getNftsWithDetail(_nfts, web3, address, exchangeNftSC)
      nftsWithDetail.splice(
        nftsWithDetail.findIndex((nft) => nft.tokenId === token_id),
        1,
      )
    }

    if (_nfts && _nfts.length < 8) {
      setCanFetchMore(false)
    }
    setAllNfts(nftsWithDetail)
    setLoading(false)
  }

  // fetch more data for pagination
  const fetchMoreAllNftAssets = async () => {
    const sortOption = ''
    const priceGt = 0
    const priceLt = 999
    const _nfts = await getAllNFTAssets({
      collectionAddress: contract_id.toLowerCase(),
      quoteToken: null,
      pageNumber,
      sortOption,
      priceGt,
      priceLt,
    })
    const nftsWithDetail = await getNftsWithDetail(_nfts, web3, address, exchangeNftSC)
    if (_nfts && _nfts.length < 8) {
      setCanFetchMore(false)
    }
    setAllNfts([...allNfts, ...nftsWithDetail])
  }

  const fetchMore = () => {
    setTimeout(() => {
      setPageNumber(pageNumber + 1)
    }, 1000)
  }

  const cleanupNfts = () => {
    setAllNfts([])
    setPageNumber(0)
  }

  useEffect(() => {
    if (!notFound && !NFTloading) {
      setTimeout(() => {
        fetchAllNftAssets()
      }, 1000)
    }
    const connectorKey = window.localStorage.getItem(connectorLocalStorageKey)
    if (!connectorKey) {
      cleanupNfts()
    }

    return () => {
      cleanupNfts()
    }
  }, [])

  // fetch more data
  useEffect(() => {
    if (pageNumber > 0) {
      fetchMoreAllNftAssets()
    }
  }, [pageNumber])

  // On selecting NFT
  const onSelectNft = (collectId: string, tokenId: string, isCollection: boolean) => {
    if (tokenId === '') return
    const selectedNft = allNfts.find((row: NftType) => row.tokenId === tokenId)
    if (selectedNft) {
      history.replace(`/assets/${selectedNft.collectionAddress}/${selectedNft.tokenId}`)
    }
  }

  return (
    <div className="w-full mt-8 border border-t-0 border-gray-300 rounded-lg overflow-hidden">
      <Accordion title="More from this collection" icon={''} defaultOpen={true}>
        <div className="overflow-auto">
          <ErrorBoundry>
            <CardList
              nfts={allNfts}
              loader={loading}
              canFetchMore={canFetchMore}
              fetchMore={fetchMore}
              onSelectNft={onSelectNft}
              layout="flex"
              mintLoader={false}
            />
          </ErrorBoundry>
        </div>
      </Accordion>
    </div>
  )
}

export default Recommendation
