import React, { useEffect, useState } from 'react'
import { DialogModal, Dropdown } from 'shared-next'
import { getTokenNameFromAddress } from 'utils'
import { categoryOptions } from 'components-next/Marketplace/constants'

interface PriceModalProps {
  title: string
  label: string
  type: string
  disabled?: boolean
  minPrice: number
  onConfirm: any
  // onConfirm: (quoteToken: string, price: string, category?: string) => Promise<{ error: string }>
  nft: any
  quoteTokens: any
  chainId: number
}

const PriceModal = ({
  title,
  label,
  disabled = false,
  type,
  minPrice,
  nft,
  quoteTokens,
  onConfirm,
  chainId,
}: PriceModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isErrors, setErrors] = useState({ quote: '', price: '', other: '' })

  const validate = () => {
    const errs = { quote: '', price: '', other: '' }
    if (Number(price) <= 0) errs.price = 'Value should be greater than ' + (minPrice ? minPrice : 0)
    if (type === 'bid' && Number(price) < minPrice)
      errs.price = 'Value should be greater than ' + (minPrice ? minPrice : 0)

    if (!quoteToken || quoteToken.value === '') errs.quote = 'Please select a valid token'
    if (type === 'ask' && !nft.category) {
      if (!categoryOpt || categoryOpt.value === '') errs.other = 'Please select a valid Category'
    }

    return errs
  }

  const handleOpen = () => {
    setIsOpen((isOpen) => !isOpen)
  }

  const handleClose = () => {
    setIsOpen(false)
  }
  // Data fetch
  const [quoteToken, setQuoteToken] = useState<{ value: string; label: string }>({
    label: 'Select Token',
    value: '',
  })
  const [price, setPrice] = useState<string>(`${minPrice ? minPrice : '0'}`)
  const [categoryOpt, setCategoryOpt] = useState<{ label: string; value: string; icon?: () => JSX.Element }>({
    label: 'Select Category',
    value: '',
  })

  useEffect(() => {
    setPrice(`${minPrice ? minPrice : '0'}`)
  }, [open])

  useEffect(() => {
    if (nft && Object.keys(nft).length === 0) return
    /*  if (nft.quoteToken === null) {
       if (chainId === 43114)
        return setQuoteToken({
          label: 'WAVAX',
          value: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
        })
      else
        return setQuoteToken({
          label: 'GFX',
          value: '0x65ad6a2288b2dd23e466226397c8f5d1794e58fc',
        })
    }*/
    if (nft.price !== 0) {
      setQuoteToken({
        label: getTokenNameFromAddress(nft.quoteToken),
        value: nft.quoteToken,
      })
    }
  }, [nft, quoteTokens])

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    return setPrice(value)
  }

  const onFocusInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (/0/.test(value)) {
      e.target.select()
    }
  }

  const onClickConfirm = async () => {
    const errors = validate()
    setErrors(errors)

    if (errors.price !== '' || errors.quote !== '' || errors.other !== '') return

    if (type === 'ask') {
      await onConfirm(quoteToken.value, price, categoryOpt.value)
    } else {
      await onConfirm(quoteToken.value, price)
    }
    handleClose()
  }

  const onSelectQuoteToken = (value) => {
    setQuoteToken(value)
  }

  const onChangeCategory = (value) => {
    setCategoryOpt(value)
  }

  const options = quoteTokens.map((quoteToken) => {
    const quoteTokenName = getTokenNameFromAddress(quoteToken)
    return {
      label: quoteTokenName.length > 0 ? `${quoteTokenName}` : quoteToken,
      value: quoteToken,
    }
  })

  return (
    <DialogModal
      title={title}
      label={label}
      disabled={disabled}
      isOpen={isOpen}
      handleOpen={handleOpen}
      handleClose={handleClose}
      wSize="md"
      buttonStyle="w-full justify-center"
    >
      <div className=" my-2 px-4 py-3 flex flex-col gap-4 dark:text-white ">
        {type === 'ask' && !nft.category && (
          <section>
            <label htmlFor="" className="text-sm block mb-1 ">
              Category
            </label>
            <Dropdown list={categoryOptions} selected={categoryOpt} onSelect={onChangeCategory} />
            {isErrors.other !== '' && <p className="text-red-500 text-xs mt-1">{isErrors.other}</p>}
          </section>
        )}

        <section>
          <label htmlFor="" className="text-sm block mb-1 ">
            Quote Token
          </label>
          {type === 'bid' || type === 'increase' ? (
            <select
              className="relative w-full py-4 pl-4 pr-10 text-left bg-white dark:bg-gray-800 rounded-lg border border-gray-300 cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm"
              disabled
            >
              {quoteToken && <option value={quoteToken.value}>{quoteToken.label}</option>}
            </select>
          ) : (
            <Dropdown list={options} selected={quoteToken} onSelect={onSelectQuoteToken} />
          )}
          {isErrors.quote !== '' && <p className="text-red-500 text-xs mt-1">{isErrors.quote}</p>}
        </section>

        <section>
          <label htmlFor="price" className="text-sm block mb-1">
            Price
          </label>
          <input
            className="no-spinner w-full py-4 pl-4 pr-10 text-left bg-white dark:bg-gray-800 rounded-lg border border-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 sm:text-sm"
            type="number"
            id="price"
            name="price"
            min={type === 'bid' ? minPrice : 0}
            value={price}
            onChange={onChangeInput}
            onFocus={onFocusInput}
          />
          {isErrors.price !== '' && <p className="text-red-500 text-xs mt-1">{isErrors.price}</p>}
        </section>

        <section className="flex justify-center items-center py-2">
          <button
            type="button"
            className="glow-on-hover bg-gradient-to-r from-blue-400 via-green-300 to-purple-500 text-white px-4 py-2 rounded-lg"
            onClick={onClickConfirm}
          >
            Confirm
          </button>
        </section>
      </div>
    </DialogModal>
  )
}

export default React.memo(PriceModal)
