import * as actionTypes from '../constants'

export const setCollection = (payload: any) => ({
  type: actionTypes.SET_SELECTED_COLLECTION,
  payload,
})

export const initNft = (payload: any) => ({
  type: actionTypes.ACTION_NFT_INIT,
  payload,
})

export const setNftLoading = (payload: any) => ({
  type: actionTypes.ACTION_NFT_LOADING,
  payload,
})

export const setAllNftCollections = (payload: any) => ({
  type: actionTypes.ACTION_NFT_SET_ALL_NFT_COLLECTIONS,
  payload,
})

export const setMyNfts = (payload: any) => ({
  type: actionTypes.ACTION_NFT_SET_MY_NFTS,
  payload,
})

export const setMySoldNfts = (payload: any) => ({
  type: actionTypes.ACTION_NFT_SET_MY_Sold_NFTS,
  payload,
})
export const setMySaleNfts = (payload: any) => ({
  type: actionTypes.ACTION_NFT_SET_MY_ONSALE_NFTS,
  payload,
})
export const setMyCollectionNfts = (payload: any) => ({
  type: actionTypes.ACTION_NFT_SET_MY_Collection_NFTS,
  payload,
})

export const _setAssumeAllNfts = (payload: any) => ({
  type: actionTypes.ACTION_NFT_SET_ALL_ASSUME_NFTS,
  payload,
})

export const setAllNfts = (payload: any) => ({
  type: actionTypes.ACTION_NFT_SET_ALL_NFTS,
  payload,
})

export const setActiveNft = (payload: any) => ({
  type: actionTypes.ACTION_NFT_SET_ACTIVE_NFT,
  payload,
})

export const setQuoteTokens = (payload: any) => ({
  type: actionTypes.ACTION_NFT_SET_QUOTE_TOKENS,
  payload,
})
