import React from 'react'
import { DisconnectIcon } from 'shared-next/Icons'
import { AccountActionType, ConnectorNames } from '../types'
import Copy from './Copy'
import { wallets } from '../config'
import { useActiveWeb3React } from 'hooks/useAuth'
import { connectorsByName } from 'utils'

const AccountAction = ({ address, setChange, shortAddress, disconnect }: AccountActionType) => {
  const { connector } = useActiveWeb3React()

  const connectorName = () => {
    const { ethereum } = window
    const isMetaMask = !!(ethereum && ethereum.isMetaMask)
    const title = wallets
      .filter(
        (wallet) =>
          wallet.connector === connector &&
          (connector !== connectorsByName[ConnectorNames.Injected] || isMetaMask === (wallet.title === 'Metamask')),
      )
      .map((wallet) => wallet.title)[0]

    return <p className="font-medium text-gray-700 dark:text-white">Connected with {title}</p>
  }

  return (
    <>
      <div className="mb-4">
        <div className="flex items-center justify-between mb-2 text-base ">
          {connectorName()}
          <button
            type="button"
            className="glow-on-hover bg-gradient-to-r from-blue-400 via-green-300 to-purple-500 text-white rounded-lg px-3 py-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            onClick={() => setChange(true)}
          >
            Change
          </button>
        </div>
        <p className="text-xl dark:text-white">{shortAddress}</p>
      </div>

      <div className="flex item-center gap-x-6">
        <Copy toCopy={address} />
        <button
          type="button"
          className="flex items-center gap-x-2 bg-gray-200 dark:bg-gray-700 px-3 py-1 rounded-lg text-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600"
          onClick={disconnect}
        >
          <DisconnectIcon />
          <p className="text-sm font-medium">Disconnect</p>
        </button>
      </div>
    </>
  )
}

export default AccountAction
