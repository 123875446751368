import React from 'react'
import Header from 'components-next/Header'
import { Login } from 'components-next/WalletModal/types'
import ScrollToView from 'components-next/ScrollToView'
import Alert from 'shared-next/Alert'

interface LayoutProps extends React.HTMLProps<HTMLDivElement> {
  login: Login
  logout: () => void
  onWeb3Connect: () => Promise<void>
  chainError: string
}

const Layout = ({ login, logout, onWeb3Connect, children, chainError }: LayoutProps) => {
  return (
    <ScrollToView>
      <div className="min-h-screen  dark:bg-gray-900">
        <Header login={login} logout={logout} onWeb3Connect={onWeb3Connect} />
        {chainError !== '' && (
          <div className="alert-message">
            <Alert icon="danger">{chainError}</Alert>
          </div>
        )}
        {children}
      </div>
    </ScrollToView>
  )
}

export default Layout
