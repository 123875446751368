import React, { Component, ErrorInfo, ReactNode } from 'react'
import { Alert } from 'shared-next'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundry extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="fixed bottom-4 right-4 z-40">
          <Alert icon={'danger'}>Oops! Something went wrong</Alert>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundry
