import NoPic from 'assets-next/images/no-pic.png'
import { getAskTransactions, removeDecimalFromPrice } from 'utils'
import { CollectionIcon, ArtworkIcon, MusicNoteIcon, SportIcon, GameIcon } from 'shared-next/Icons'
import { fetchTokenUriDetails, fetchNfts, fetchAllUserSoldNfts } from 'services'
//import { getKeyValue } from './utils'
//import { extractDetails } from 'utils'
//import { ipfsURL } from 'components-next/MyProfile/constants'

export const artworkTypeOptions = [
  {
    label: 'Image',
    value: 'image',
    field: 'artworkType',
  },
  {
    label: 'GIF',
    value: 'gif',
    field: 'artworkType',
  },
  {
    label: 'Video',
    value: 'video',
    field: 'artworkType',
  },
]

export const categoryOptions = [
  { label: 'All', value: '', icon: CollectionIcon },
  {
    label: 'Artwork',
    value: 'artwork',
    icon: ArtworkIcon,
  },
  {
    label: 'Music',
    value: 'music',
    icon: MusicNoteIcon,
  },
  {
    label: 'Sports',
    value: 'sports',
    icon: SportIcon,
  },
  {
    label: 'Gaming',
    value: 'gaming',
    icon: GameIcon,
  },
]

export const sortOptions = [
  {
    label: 'Recently Created',
    value: 'recently-created',
  },
  {
    label: 'Price: High to Low',
    value: 'price-DESC',
  },
  {
    label: 'Price: Low to High',
    value: 'price-ASC',
  },
  {
    label: 'Like: High to Low',
    value: 'like-DESC',
  },
  {
    label: 'Like: Low to High',
    value: 'like-ASC',
  },
]

const extractDetails = (res, parent_Key = '', giveParentKey = false) => {
  const data = {}
  for (const key in res) {
    if (typeof res[key] === 'object') {
      let d
      if (key === 'image' || key === 'name' || key === 'description') d = extractDetails(res[key], key, true)
      else d = extractDetails(res[key])
      for (const key1 in d.data) {
        if (key === 'name') data['artworkName'] = d.data[key1]
        if (key === 'artist' || key === 'artistName') data['artistName'] = d.data[key1]
        else data[key1] = d.data[key1]
      }
    } else {
      if (key !== 'type') {
        if (giveParentKey) data[parent_Key] = res[key]
        else {
          if (key === 'name') data['artworkName'] = res[key]
          if (key === 'artist' || key === 'artistName') data['artistName'] = res[key]
          else data[key] = res[key]
        }
      }
    }
  }

  return { data }
}

const ipfsURL = (url) => {
  let tokenURI = url ? url : ''
  if (tokenURI && tokenURI.includes('ipfs://ipfs/') && !tokenURI.includes('https://ipfs.io/')) {
    const val = tokenURI.split('ipfs://')[1]
    tokenURI = `https://ipfs.io/${val}`
  } else if (tokenURI && tokenURI.includes('ipfs://') && !tokenURI.includes('https://ipfs.io/')) {
    const val = tokenURI.split('ipfs://')[1]
    tokenURI = `https://ipfs.io/ipfs/${val}`
  }

  return tokenURI
}

export const getNftsWithDetail = async (nfts, web3, address, exchangeNftSC) => {
  if (!nfts) return []
  if (nfts && nfts.length <= 0) return []
  const nftsWithDetail = []

  for (let i = 0; i < nfts.length; i++) {
    try {
      const collectionAddress = nfts[i]?.tokenCollection
      const ownerAddress = nfts[i]?.owner
      const creatorAddress = nfts[i]?.creator
      const tokenId = nfts[i]?.tokenId

      const voteCnt = nfts[i]?.voteCnt ? nfts[i]?.voteCnt : nfts[i]?.voteCnt === 0 ? '0' : null
      const love = nfts[i]?.love ? nfts[i]?.love : nfts[i]?.love === 0 ? '0' : null
      const category =
        nfts[i] && nfts[i].category !== null
          ? Object.values(categoryOptions).some((item) => item.value === nfts[i]?.category)
            ? nfts[i].category
            : 'NA'
          : null
      let image = nfts[i]?.tokenImage ? nfts[i]?.tokenImage : NoPic
      let artistName = nfts[i]?.artistName ? nfts[i]?.artistName : 'NA'
      let artWorkType = nfts[i]?.artworkType
      let bio = nfts[i]?.description ? nfts[i]?.description : ''
      let artworkName = nfts[i]?.artworkName ? nfts[i]?.artworkName : 'NA'
      const tokenURL = nfts[i]?.tokenUri ? ipfsURL(nfts[i]?.tokenUri) : ''

      if (nfts[i]?.tokenUri !== '' && nfts[i]?.tokenUri !== undefined) {
        try {
          const { data: rawData } = await fetchTokenUriDetails(ipfsURL(nfts[i]?.tokenUri))
          const { data }: any = extractDetails(rawData)

          image = data?.image ? data?.image : NoPic
          artistName = data?.artistName ? data?.artistName : 'NA'
          artWorkType = data?.artWorkType
          bio = data?.bio ? data?.bio : ''
          artworkName = data?.artworkName ? data?.artworkName : 'NA'
        } catch (e) {
          image = nfts[i]?.tokenImage ? nfts[i]?.tokenImage : NoPic
          artistName = nfts[i]?.artistName ? nfts[i]?.artistName : 'NA'
          artWorkType = nfts[i]?.artworkType
          bio = nfts[i]?.description ? nfts[i]?.description : ''
          artworkName = nfts[i]?.artworkName ? nfts[i]?.artworkName : 'NA'
        }
      }

      // get price from marketplace
      let price = nfts[i]?.price ? nfts[i]?.price : null
      let quoteToken = nfts[i]?.quoteToken ? nfts[i]?.quoteToken : null

      if (exchangeNftSC && exchangeNftSC._address !== null) {
        try {
          const auctionId = await exchangeNftSC.methods.getAuctionId(collectionAddress, tokenId).call()
          if (auctionId > 0) {
            const _askTransactions = await getAskTransactions(collectionAddress, tokenId, auctionId)
            if (_askTransactions.length > 0) {
              // price = Math.max(...((_askTransactions && _askTransactions.map((t) => t.price)) || []))
              price = _askTransactions[0]?.price
              quoteToken = _askTransactions[0]?.quoteToken
            }
          }
        } catch (error) {
          console.error('Marketplace exchangeNftSC error:--->', error)
        }
      }
      nftsWithDetail.push({
        tokenId,
        collectionAddress,
        quoteToken,
        price: removeDecimalFromPrice(quoteToken, price),
        voteCnt,
        ownerAddress,
        creatorAddress,
        artistName,
        artWorkType,
        artworkName,
        bio,
        image,
        category,
        love,
        tokenURL,
      })
    } catch (error) {
      nftsWithDetail.push({
        tokenId: nfts[i].tokenId,
        artistName: 'NA',
        artworkType: 'Normal',
        artworkName: 'No name',
        socialLink: 'https://temp.data',
        description: '',
        image: NoPic,
        quoteToken: nfts[i]?.quoteToken,
        price: '0',
        voteCnt: '0',
        love: 0,
        ownerAddress: address ? address : '',
        creatorAddress: address ? address : '',
        collectionAddress: address ? address : '',
        category: 'NA',
        tokenURL: '',
      })
    }
  }

  return nftsWithDetail
}

export const getAvalancheNftsWithDetail = async (owner) => {
  const limit = 20
  let data = [],
    res,
    page = 0

  do {
    const resp = await fetchNfts(`?owner=${owner}&status=Ask&page=${page}&limit=${limit}`)
    res = resp.data

    if (res && res.rows && res.rows.length) {
      data = data.concat(
        res.rows.map((row) => ({
          tokenId: row.token.tokenId,
          collectionAddress: row.token.collection.address,
          quoteToken: row.quoteToken,
          price: removeDecimalFromPrice(row.quoteToken, row.details.price),
          voteCnt: row.token.like,
          ownerAddress: row.token.owner,
          creatorAddress: '',
          artistName: row.token.artistName,
          artWorkType: row.token.artworkType,
          tokenURL: row.token.uri ? ipfsURL(row.token.uri) : '',
          artworkName: '',
          bio: '',
          image: row.token.image,
          category: 'N/A',
          love: row.token.love,
        })),
      )
    }

    page += limit
  } while (res && res.rows && res.rows.length)

  return data
}

export const getAvalancheSoldNftWithDetail = async (owner) => {
  const limit = 20
  let data = [],
    res,
    page = 0

  do {
    const resp = await fetchAllUserSoldNfts(`?owner=${owner}&page=${page}&limit=${limit}`)
    res = resp.data

    if (res && res.rows && res.rows.length) {
      data = data.concat(
        res.rows.map((row) => ({
          tokenId: row.token.tokenId,
          collectionAddress: row.token.collection.address,
          quoteToken: row.quoteToken,

          price: removeDecimalFromPrice(row.quoteToken, row.details.price),
          voteCnt: row.token.like,
          ownerAddress: row.token.owner,
          creatorAddress: '',
          artistName: row.token.artistName,
          artWorkType: row.token.artworkType,
          tokenURL: row.token.uri ? ipfsURL(row.token.uri) : '',
          artworkName: '',
          bio: '',
          image: row.token.image,
          category: 'N/A',
          love: row.token.love,
        })),
      )
    }

    page += limit
  } while (res && res.rows && res.rows.length)

  return data
}
