import { Transition } from '@headlessui/react'
import { AdjustmentIcon, CloseIcon } from 'shared-next/Icons'
import useScreen from 'hooks/useScreen'
import React, { Fragment, useEffect, useState } from 'react'

type SidebarProps = React.HTMLProps<HTMLDivElement>

const Sidebar = ({ children }: SidebarProps) => {
  const { isMobile } = useScreen()
  const [isOpen, setIsOpen] = useState(!isMobile)

  useEffect(() => {
    if (isMobile) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }, [isMobile])

  return (
    <>
      {isMobile && (
        <div className="fixed bottom-4 right-4 w-16 h-16 z-30">
          <button
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="bg-purple-500 text-white flex items-center justify-center rounded-full w-full h-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 shadow-lg"
          >
            <AdjustmentIcon />
          </button>
        </div>
      )}

      <Transition
        show={isOpen}
        as={Fragment}
        enter={`${isMobile ? 'ease-out duration-300' : ''}`}
        enterFrom={`${isMobile ? 'opacity-0' : ''}`}
        enterTo={`${isMobile ? 'opacity-100' : ''}`}
        leave={`${isMobile ? 'ease-in duration-200' : ''}`}
        leaveFrom={`${isMobile ? 'opacity-100' : ''}`}
        leaveTo={`${isMobile ? 'opacity-0' : ''}`}
      >
        <aside
          className="fixed sm:sticky top-16 w-full sm:w-2/5 md:w-1/4 md:flex flex-col bg-white dark:bg-gray-800 border-r border-gray-200 z-20 overflow-y-auto"
          style={{ height: 'calc(100vh - 4rem)' }}
        >
          <Transition.Child
            as={Fragment}
            enter={`${isMobile ? 'ease-out duration-300' : ''}`}
            enterFrom={`${isMobile ? 'opacity-0 scale-95' : ''}`}
            enterTo={`${isMobile ? 'opacity-100 scale-100' : ''}`}
            leave={`${isMobile ? 'ease-in duration-200' : ''}`}
            leaveFrom={`${isMobile ? 'opacity-100 scale-100' : ''}`}
            leaveTo={`${isMobile ? 'opacity-0 scale-95' : ''}`}
          >
            <div className="w-full h-full">
              <div className="border-b border-gray-300 dark:bg-gray-700 px-3 py-2 flex items-center justify-end sm:hidden">
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="flex items-center font-medium text-gray-600 dark:text-white gap-2"
                >
                  <CloseIcon />
                  <span>Close</span>
                </button>
              </div>
              {children}
            </div>
          </Transition.Child>
        </aside>
      </Transition>
    </>
  )
}

export default React.memo(Sidebar)
