import * as actionTypes from '../constants'
import { INftState, NftActionTypes } from '../types'

const initialState: INftState = {
  isLoading: false,
  nftCollections: [],
  myNfts: [],
  allNfts: [],
  activeNft: {},
  quoteTokens: [],
  assumeAllNFTS: '',
  selectedCollection: { label: 'Collection', value: 'all' },
  myNFTCollection: [],
  mySoldNFT: [],
  myOnSaleNFT: [],
}

function nftReducer(state = initialState, action: NftActionTypes) {
  switch (action.type) {
    case actionTypes.ACTION_NFT_INIT:
      return initialState
    case actionTypes.ACTION_NFT_LOADING:
      return { ...state, isLoading: action.payload }
    case actionTypes.ACTION_NFT_SET_ALL_NFT_COLLECTIONS:
      return { ...state, nftCollections: action.payload }
    case actionTypes.ACTION_NFT_SET_MY_NFTS:
      return { ...state, myNfts: action.payload }
    case actionTypes.ACTION_NFT_SET_ALL_NFTS:
      return { ...state, allNfts: action.payload }
    case actionTypes.ACTION_NFT_SET_ACTIVE_NFT: {
      return { ...state, activeNft: action.payload }
    }
    case actionTypes.ACTION_NFT_SET_QUOTE_TOKENS: {
      return { ...state, quoteTokens: action.payload }
    }
    case actionTypes.ACTION_NFT_SET_ALL_ASSUME_NFTS: {
      return { ...state, assumeAllNFTS: action.payload }
    }

    case actionTypes.SET_SELECTED_COLLECTION: {
      return { ...state, selectedCollection: action.payload }
    }
    case actionTypes.ACTION_NFT_SET_MY_Sold_NFTS:
      return { ...state, mySoldNFT: action.payload }
    case actionTypes.ACTION_NFT_SET_MY_Collection_NFTS:
      return { ...state, myNFTCollection: action.payload }
    case actionTypes.ACTION_NFT_SET_MY_ONSALE_NFTS:
      return { ...state, myOnSaleNFT: action.payload }

    default:
      return state
  }
}

export default nftReducer
