import React from 'react'
import { Disclosure, Transition } from '@headlessui/react'
import { ChevronUpIcon } from 'shared-next/Icons'

interface AccordionProps extends React.HTMLProps<HTMLDivElement> {
  title: string
  icon?: React.ReactNode
  defaultOpen?: boolean
}

const Accordion = ({ title, icon, defaultOpen = false, children }: AccordionProps) => {
  return (
    <>
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={`flex items-center justify-between w-full px-4 py-4 text-sm font-medium text-left text-gray-700 dark:text-white bg-white dark:bg-gray-800 border-t border-b focus:outline-none focus-visible:ring focus-visible:ring-purple-200 focus-visible:ring-opacity-75`}
            >
              <p className="flex items-center gap-3">
                {icon && <span>{icon}</span>}
                <span className="text-base font-medium">{title}</span>
              </p>
              <span
                className={`${
                  open ? 'transform rotate-180' : ''
                } w-5 h-5 text-gray-500 dark:text-white flex items-center justify-center transform transition-all`}
              >
                <ChevronUpIcon />
              </span>
            </Disclosure.Button>
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="px-2 py-2 text-sm text-gray-500 dark:text-white bg-gray-100 dark:bg-gray-900 bg-opacity-50">
                {children}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </>
  )
}

export default Accordion
