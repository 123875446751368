import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAllNfts,
  setMyNfts,
  _setAssumeAllNfts,
  setMyCollectionNfts,
  setMySaleNfts,
  setMySoldNfts,
} from 'store/actions'
import { RootState } from 'store/reducers'
import {
  getNftsWithDetail,
  getAvalancheNftsWithDetail,
  getAvalancheSoldNftWithDetail,
} from 'components-next/Marketplace/constants'
import {
  getAllNFTAssets,
  getAllNFTAssetsFromAddress,
  getMyNFTAsset,
  getAllSellNFTAssetsFromAddress,
  getAllSoldNFTAssetsFromAddress,
  getAllNFTAssetsCollectionFromAddress,
  getParticularCollectionNFT,
  covalentData,
  isChainAvalanche,
  getGNFTAsset,
} from 'utils'

import {
  getUserNFTDetails,
  getUserGNFTDetails,
  getProfileDetail,
  getProfile_CollectionDetail,
  getCovalentCollection,
  getCovalentData,
  getCovalentData2,
} from 'components-next/MyProfile/constants'
import _ from 'lodash'

const useFetchNfts = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [GNFT, setGNFT] = useState([])
  const [canFetchMore, setCanFetchMore] = useState<boolean>(true)

  // global data (reducer data)
  const walletReducer = useSelector((state: RootState) => state.wallet)
  const { web3, address, activeChainID, contracts } = walletReducer
  const { exchangeSC: exchangeNftSC } = contracts

  const nftReducer = useSelector((state: RootState) => state.nft)
  const {
    myNfts,
    allNfts,
    activeNft,
    quoteTokens,
    assumeAllNFTS,
    selectedCollection,
    myNFTCollection,
    mySoldNFT,
    myOnSaleNFT,
  } = nftReducer

  /**
   * Fetch All NFTs
   */
  const fetchAllNftAssets = async (
    activeCollectionAddress = '',
    activeQuoteTokenAddress = '',
    sortOption = '',
    priceGt = 0,
    priceLt = 999,
    category = '',
    artWork = '',
  ) => {
    setLoading(true)
    // dispatch(setAllNfts([]))
    const _nfts = await getAllNFTAssets({
      collectionAddress: activeCollectionAddress === '' ? null : activeCollectionAddress.toLowerCase(),
      quoteToken: activeQuoteTokenAddress === '' ? null : activeQuoteTokenAddress.toLowerCase(),
      pageNumber,
      sortOption,
      priceGt,
      priceLt,
      category,
      artWork,
    })

    let nftsWithDetail = []
    if (_nfts) {
      nftsWithDetail = await getNftsWithDetail(_nfts, web3, address, exchangeNftSC)
    }
    if (_nfts && _nfts.length < 8) {
      setCanFetchMore(false)
    }
    dispatch(setAllNfts(nftsWithDetail))
    setLoading(false)
  }

  /**
   * Fetch more NFTs for pagination
   */
  const fetchMoreAllNftAssets = async (
    activeCollectionAddress = '',
    activeQuoteTokenAddress = '',
    priceGt = 0,
    priceLt = 999,
    sortOption = '',
    category = '',
    artWork = '',
    pageNumber,
  ) => {
    const _nfts = await getAllNFTAssets({
      collectionAddress: activeCollectionAddress === '' ? null : activeCollectionAddress.toLowerCase(),
      quoteToken: activeQuoteTokenAddress === '' ? null : activeQuoteTokenAddress.toLowerCase(),
      pageNumber,
      sortOption,
      priceGt,
      priceLt,
      category,
      artWork,
    })

    const nftsWithDetail = await getNftsWithDetail(_nfts, web3, address, exchangeNftSC)
    if (_nfts && _nfts.length < 8) {
      setCanFetchMore(false)
    }
    dispatch(setAllNfts([...allNfts, ...nftsWithDetail]))
  }

  const fetchMyNftAssets = async (
    activeCollectionAddress = 'all',
    activeQuoteTokenAddress = 'all',
    sortOption = '',
  ) => {
    if (!address) return
    setLoading(true)
    try {
      const _nfts = await getAllNFTAssetsFromAddress({
        account: address,
        collectionAddress: activeCollectionAddress === 'all' ? null : activeCollectionAddress.toLowerCase(),
        quoteToken: activeQuoteTokenAddress === 'all' ? null : activeQuoteTokenAddress.toLowerCase(),
        pageNumber,
        sortOption,
      })

      const nftsWithDetail = await getNftsWithDetail(_nfts, web3, address, exchangeNftSC)
      if (_nfts && _nfts.length < 8) {
        setCanFetchMore(false)
      }
      dispatch(setMyNfts(nftsWithDetail))
      setLoading(false)
    } catch (error) {
      console.error('error--->', error)
    }
  }

  /**
   * Fetch user on Sale Assets
   */
  const fetchMySellNftAssets = async (
    address,
    sortOption = '',
    activeQuoteTokenAddress = 'all',
    activeArtWorkTypeOpt = 'all',
  ) => {
    if (!address) return

    setLoading(true)
    try {
      const _nfts = await getAllSellNFTAssetsFromAddress({
        account: address.toLowerCase(),
        quoteToken: activeQuoteTokenAddress === 'all' ? null : activeQuoteTokenAddress,
        artWork: activeArtWorkTypeOpt === 'all' ? '' : activeArtWorkTypeOpt,
        pageNumber,
        sortOption,
      })

      // const nftsWithDetail = isChainAvalanche(activeChainID)
      //  ? await getNftsWithDetail(_nfts, web3, address, exchangeNftSC)
      //  : await getAvalancheNftsWithDetail(address)
      const nftsWithDetail = await getNftsWithDetail(_nfts, web3, address, exchangeNftSC)
      if (_nfts && _nfts.length < 8) {
        setCanFetchMore(false)
      }
      dispatch(setMySaleNfts(nftsWithDetail))
      setLoading(false)
    } catch (error) {
      console.error('error--->', error)
    }
  }

  /**
   * Fetching Sold nfts of user
   */
  const fetchMySoldNftAssets = async (address) => {
    if (!address) return
    setLoading(true)
    try {
      const _nfts = await getAllSoldNFTAssetsFromAddress({
        account: address.toLowerCase(),
      })

      const nftsWithDetail = await getUserGNFTDetails(_nfts, address)

      // if (_nfts && _nfts.length < 8) {
      setCanFetchMore(false)
      // }

      dispatch(setMySoldNfts(nftsWithDetail))

      // const nfts = await getAvalancheSoldNftWithDetail(address)
      // dispatch(setMySoldNfts(nfts))

      setLoading(false)
    } catch (error) {
      console.error('error--->', error)
    }
  }

  /**
   * Fetch User Asset Collections
   */
  const fetchMyAllNftCollection = async (address) => {
    if (!address) return
    setLoading(true)
    try {
      /*
      const collections = await getAllNFTAssetsCollectionFromAddress({
        account: address,
      })
      const result = getProfile_CollectionDetail(collections)
      //if (result && result.length < 8) {
      //  setCanFetchMore(false)
      // }
      dispatch(setMyCollectionNfts(result))
      */

      // Fetching NFT collection using covalent api
      const res = await covalentData(address)
      const collection = await getCovalentCollection(res.data.items)
      setCanFetchMore(false)
      dispatch(setMyCollectionNfts(collection))
    } catch (error) {
      console.error('fetchMyAllNftCollection Error: ', error)
    }
    setLoading(false)
  }

  /**
   * Fetching Assets of given collection
   */
  const fetchMyParticularNftCollection = async (address, contract_address: string) => {
    if (!address) return
    if (!contract_address) return
    setLoading(true)
    try {
      /*
      const _nfts = await getParticularCollectionNFT({
        account: address,
        contract_address: contract_address,
      })
      const result = _nfts && getUserNFTDetails(_nfts, address)
      if (_nfts && _nfts.length < 8) {
        setCanFetchMore(false)
      }
      dispatch(setMyNfts(result))
      setLoading(false)*/

      //Fetching Assets of given Collection using Covalent api
      const collectionNFTs = myNFTCollection.filter((nft) => nft.collectionAddress === contract_address)[0]
      const result = await getCovalentData2(collectionNFTs, exchangeNftSC)
      dispatch(setMyNfts(result))
      setLoading(false)
    } catch (error) {
      console.error('fetchMyParticularNftCollection Error: ', error)
    }
  }

  const fetchMoreMyNftAssets = async (
    activeCollectionAddress = 'all',
    activeQuoteTokenAddress = 'all',
    sortOption = '',
  ) => {
    if (!address) return
    const _nfts = await getAllNFTAssetsFromAddress({
      account: address.toLowerCase(),
      collectionAddress: activeCollectionAddress === 'all' ? null : activeCollectionAddress.toLowerCase(),
      quoteToken: activeQuoteTokenAddress === 'all' ? null : activeQuoteTokenAddress.toLowerCase(),
      pageNumber,
      sortOption,
    })

    const nftsWithDetail = await getNftsWithDetail(_nfts, web3, address, exchangeNftSC)
    if (_nfts && _nfts.length < 8) {
      setCanFetchMore(false)
    }
    dispatch(setMyNfts([...myNfts, ...nftsWithDetail]))
  }

  const cleanupNfts = () => {
    dispatch(setMyNfts([]))
    dispatch(setAllNfts([]))
    setPageNumber(0)
  }

  const fetchMore = () => {
    setTimeout(() => {
      setPageNumber(pageNumber + 1)
    }, 1000)
  }

  const fetchGNFT = async (address, collectionAddress) => {
    if (!address) return
    setLoading(true)
    try {
      const myNFTResponse = await getGNFTAsset({
        account: address.toLowerCase(),
        contract: collectionAddress.toLowerCase(),
      })
      const result = myNFTResponse && (await getUserGNFTDetails(myNFTResponse, address))

      setCanFetchMore(false)

      setGNFT(result)
    } catch (err) {
      console.log('GNFT erro', err)
      setGNFT([])
      setCanFetchMore(false)
    }
    setLoading(false)
  }

  const addMintNFT = (details) => {
    setLoading(true)
    const gnfts = []
    gnfts.push(details)

    setGNFT([...gnfts, ...GNFT])
    setLoading(false)
  }

  /*
   * fetching User all Assets
   */
  const fetchProfileData = async (address, activeQuoteTokenAddress, activeArtWorkTypeOpt) => {
    if (!address) return
    setLoading(true)
    try {
      /*
      const myNFTResponse = await getMyNFTAsset({
        account: address.toLowerCase(),
        quoteToken: activeQuoteTokenAddress === 'all' ? null : activeQuoteTokenAddress,
        artWork: activeArtWorkTypeOpt === 'all' ? '' : activeArtWorkTypeOpt,
      })
      const result = myNFTResponse && (await getUserNFTDetails(myNFTResponse, address))
      setCanFetchMore(false)
      dispatch(setMyNfts(result))*/
      // if (result && result.length < 5) {
      //   setCanFetchMore(false)
      // }
      //Fetching all assets of given address using covalent api
      const nftRes = await covalentData(address)
      const covRes = await getCovalentData(nftRes.data, exchangeNftSC)
      dispatch(setMyNfts(covRes))
    } catch (error) {
      console.error('fetchProfileData Error: ', error)
    }
    setLoading(false)
  }

  return {
    loading,
    pageNumber,
    canFetchMore,
    assumeAllNFTS,
    web3,
    address,
    myNfts,
    activeNft,
    allNfts,
    quoteTokens,
    setLoading,
    setPageNumber,
    setCanFetchMore,
    fetchAllNftAssets,
    fetchMyNftAssets,
    fetchMoreAllNftAssets,
    fetchMoreMyNftAssets,
    cleanupNfts,
    fetchMore,
    selectedCollection,
    fetchMySellNftAssets,
    fetchMySoldNftAssets,
    fetchMyAllNftCollection,
    fetchProfileData,
    fetchMyParticularNftCollection,
    myNFTCollection,
    mySoldNFT,
    myOnSaleNFT,
    activeChainID,
    fetchGNFT,
    GNFT,
    addMintNFT,
  }
}

export default useFetchNfts
