import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import MainPage from 'containers/main'
import { ThemeProvider } from 'Theme/themeContext'
import { Background } from 'Theme'

function Routes() {
  return (
    <div className="app ">
      <Router>
        <ThemeProvider>
          <Background>
            <Switch>
              <Route
                path="/"
                render={() => {
                  return <Route component={MainPage} />
                }}
              />
            </Switch>
          </Background>
        </ThemeProvider>
      </Router>
    </div>
  )
}

export default Routes
