import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CloseIcon } from 'shared-next/Icons'

interface ModalType extends React.HTMLProps<HTMLDivElement> {
  title: string
  label: string
  isOpen: boolean
  disabled?: boolean
  icon?: React.ReactNode
  handleOpen: () => void
  handleClose: () => void
  wSize?: 'lg' | 'md'
  buttonStyle?: string
}

const DialogModal = ({
  title,
  label,
  isOpen,
  disabled = false,
  icon,
  handleOpen,
  handleClose,
  wSize = 'lg',
  buttonStyle = '',
  children,
}: ModalType) => {
  return (
    <>
      <div className="flex items-center justify-center  ">
        <button
          type="button"
          onClick={handleOpen}
          title={title}
          disabled={disabled}
          className={`${buttonStyle} ${
            disabled && 'opacity-50'
          } flex items-center gap-x-1 px-4 py-3 text-sm font-medium text-white glow-on-hover bg-gradient-to-r from-blue-400 via-green-300 to-purple-500 rounded-md focus:outline-none hover:ring-2 hover:ring-offset-2 hover:ring-purple-500 focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}
        >
          <span>{title}</span>
          {icon && <span>{icon}</span>}
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto bg-gray-100   bg-opacity-50"
          onClose={() => {
            return false
          }}
        >
          <div className="min-h-screen px-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={`relative inline-block w-full max-w-md ${
                  wSize === 'lg' && 'md:max-w-lg lg:max-w-2xl'
                } h-96 md:h-full my-8 overflow-auto text-left align-middle transition-all transform bg-white dark:bg-gray-800  shadow-xl rounded-2xl`}
              >
                <div className="sticky top-0 flex justify-between items-center border-b border-gray-300 bg-white dark:bg-gray-800 z-40 px-4 py-3">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                    {label}
                  </Dialog.Title>
                  <button
                    className="bg-gray-200 dark:bg-gray-600 text-gray-600 dark:text-white rounded-full p-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600"
                    type="button"
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </button>
                </div>
                <div className="">{children}</div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default React.memo(DialogModal)
