import React, { useState } from 'react'
import { connectorLocalStorageKey } from './config'
import { ChainConfig, ConnectorNames, Login } from './types'
import ConnectionModal from './components/ConnectionModal'
import AccountAction from './components/AccountAction'
import { getShortenAddress } from 'utils'
import MainModal from './components/Modal'

interface WalletModal {
  title?: string
  login: Login
  logout: () => void
  address: string
  onWeb3Connect: () => Promise<void>
  handleChain: (item: ChainConfig) => void
  isButton?: boolean
}

const WalletModal = ({
  title = '',
  login,
  logout,
  address,
  onWeb3Connect,
  handleChain,
  isButton = true,
}: WalletModal) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(true)
  const [openPopper, setOpenPopper] = useState<boolean>(false)
  const [change, setChange] = useState<boolean>(false)

  const handleClick = () => {
    setOpenPopper(!openPopper)
  }

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    change && setChange(false)
    setIsOpen(false)
  }

  const handleWalletConnect = (connectorId: ConnectorNames) => {
    if (!checked) return setOpenPopper(true)

    if (connectorId && checked) {
      login(connectorId)
      window.localStorage.setItem(connectorLocalStorageKey, connectorId)
      onWeb3Connect()
      handleClose()
    }
  }

  const handleChange = (event) => {
    setChecked(event.target.checked)
    setOpenPopper(false)
  }

  const handleDisconnect = () => {
    setIsOpen(false)
    logout()
  }

  return (
    <MainModal
      isButton={isButton}
      title={title}
      label={!address ? 'Connect Wallet' : 'Web3 Wallet'}
      handleOpen={handleOpen}
      handleClose={handleClose}
      isOpen={isOpen}
      change={change}
      setChange={setChange}
    >
      {!address || change ? (
        <ConnectionModal
          checked={checked}
          handleClick={handleClick}
          handleWalletConnect={handleWalletConnect}
          handleChain={handleChain}
        />
      ) : (
        <AccountAction
          setChange={setChange}
          address={address}
          shortAddress={getShortenAddress(address)}
          disconnect={handleDisconnect}
        />
      )}
    </MainModal>
  )
}

export default WalletModal
