import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { ConnectorNames } from 'components-next/WalletModal/types'
import { connectorLocalStorageKey } from 'components-next/WalletModal/config'
import { useCallback } from 'react'
import { connectorsByName } from '../utils/web3React'
import { useDispatch } from 'react-redux'
import { setWalletAddress, setWalletProvider, setWeb3Object } from 'store/actions'
import { infuraProvider } from '../config/constants/provider'
import Web3 from 'web3'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'

export const NetworkContextName = 'NETWORK'

export function useActiveWeb3React(): Web3ReactContextInterface<Web3Provider> & { chainId?: any } {
  const context = useWeb3React<Web3Provider>()
  const contextNetwork = useWeb3React<Web3Provider>(NetworkContextName)
  return context.active ? context : contextNetwork
}

const useAuth = () => {
  const dispatch = useDispatch()
  const { activate, deactivate } = useWeb3React<Web3Provider>()

  const login = useCallback(async (connectorID: ConnectorNames) => {
    try {
      const connector = connectorsByName[connectorID]
      if (connector) {
        await activate(connector, async (error: Error) => console.error(error.name, error.message))
      } else {
        console.error("Can't find connector", 'The connector config is wrong')
      }
    } catch (e) {
      console.log('Login Error: ', e)
    }
  }, [])

  const logout = useCallback(() => {
    dispatch(setWalletAddress(''))
    dispatch(setWeb3Object(new Web3(infuraProvider)))
    dispatch(setWalletProvider(infuraProvider))

    window.localStorage.removeItem(connectorLocalStorageKey)
    deactivate()
  }, [deactivate])

  return { login, logout }
}

export default useAuth
