import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import { AddIcon, ArtworkIcon } from 'shared-next/Icons'
import { DialogModal, Dropdown } from 'shared-next'
import { RootState } from 'store/reducers'
import { ipfsURL, pinataApiKey, pinataApiSecret, pinataApiURL } from 'config/index'
import { artworkTypeOptions } from 'components-next/Marketplace/constants'
import { abis } from 'contracts'

interface INftCreateModalStates {
  artWorkType: string
  artworkName: string
  artistName: string
  socialLink: string
  bio: string
  nftImgUrl: string
  nftImgFile: File | null
}

interface INftCreateModalProps {
  collectionAddress: string
  displayAlert: (state: string, details: any) => void
  handleAfterMintLoader: () => void
}

const CreateNFT = ({ collectionAddress, displayAlert, handleAfterMintLoader }: INftCreateModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [nftTokenSC, setNftTokenSC] = useState(undefined)
  const [nftInformation, setNftInformation] = useState<INftCreateModalStates>({
    artWorkType: '',
    artworkName: '',
    artistName: '',
    socialLink: '',
    bio: '',
    nftImgUrl: '',
    nftImgFile: null,
  })

  const [artworkTypeOpt, setArtworkTypeOpt] = useState({ label: 'Select', value: '', field: 'artWorkType' })
  const [minting, setMinting] = useState<boolean>(false)
  const walletReducer = useSelector((state: RootState) => state.wallet)
  const { web3, address } = walletReducer

  const handleContract = () => {
    const nftTokenSC = new web3.eth.Contract(abis.nftToken, collectionAddress)

    setNftTokenSC(nftTokenSC)
  }

  useEffect(() => {
    if (!web3 || Object.keys(web3).length === 0) {
      return
    }
    handleContract()
  }, [web3])

  const handleOpen = () => {
    setIsOpen((isOpen) => !isOpen)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  // Form Fields Events
  const onChangeSelect = (value) => {
    setArtworkTypeOpt(value)

    // setNftInformation({
    //   ...nftInformation,
    //   artWorkType: value.value,
    // })
  }

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setNftInformation({
      ...nftInformation,
      [name]: value,
    })
  }

  // onChangeTextArea
  const onChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setNftInformation({
      ...nftInformation,
      [name]: value,
    })
  }

  // onChangeImage
  const onChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]

      const types = file.type.split('/')
      if (types[0] === 'application') {
        setNftInformation({
          ...nftInformation,
          nftImgUrl: '',
          nftImgFile: null,
          artWorkType: '',
        })
        return
      }

      const type = types[0] === 'image' ? 'image' : 'video'

      setNftInformation({
        ...nftInformation,
        nftImgUrl: URL.createObjectURL(file),
        nftImgFile: file,
        artWorkType: type,
      })
    }
  }

  const pinFileToIPFS = async () => {
    const pinImgURL = `${pinataApiURL}/pinning/pinFileToIPFS`
    if (nftInformation && nftInformation.nftImgFile && nftInformation.nftImgUrl[0]) {
      const data = new FormData()
      data.append('file', nftInformation.nftImgFile)

      try {
        const pinImage = await axios.post(pinImgURL, data, {
          maxBodyLength: Infinity,
          headers: {
            'Content-Type': `multipart/form-data;`,
            pinata_api_key: pinataApiKey,
            pinata_secret_api_key: pinataApiSecret,
          },
        })

        // nft information upload to pinata
        const pinJSONURL = `${pinataApiURL}/pinning/pinJSONToIPFS`

        const body = {
          pinataContent: {
            artWorkType: nftInformation.artWorkType,
            artworkName: nftInformation.artworkName,
            artistName: nftInformation.artistName,
            socialLink: nftInformation.socialLink,
            bio: nftInformation.bio,
            image: `${ipfsURL}${pinImage.data.IpfsHash}`,
          },
        }

        const pinData = await axios.post(pinJSONURL, body, {
          headers: {
            pinata_api_key: pinataApiKey,
            pinata_secret_api_key: pinataApiSecret,
          },
        })

        return `${ipfsURL}${pinData.data.IpfsHash}`
      } catch (err) {
        console.error('pinImg Err', err)
      }
    }
  }

  const decodingTokenId = (log) => {
    const { returnValues } = log
    const { tokenId } = returnValues
    return tokenId
  }

  const fetchMintNFTDetails = (tokenURI, tokenId) => {
    const details = {
      artWorkType: nftInformation.artWorkType,
      artistName: nftInformation.artistName,
      artworkName: nftInformation.artworkName,
      bio: nftInformation.bio,
      category: null,
      collectionAddress: collectionAddress,
      id: 100,
      image: nftInformation.nftImgUrl,
      socialLink: '',
      tokenId: tokenId,
      tokenURI: tokenURI,
      quoteToken: null,
      price: null,
      voteCnt: null,
    }
    return details
  }

  const onMint = async () => {
    setMinting(true)

    const tokenURI = await pinFileToIPFS()
    if (!web3) return

    try {
      const mintDetails = await nftTokenSC.methods.mint(address, tokenURI).send({ from: address })
      const tokenId = decodingTokenId(mintDetails.events.Mint)
      const NFtDetails = await fetchMintNFTDetails(tokenURI, tokenId)

      setTimeout(() => {
        setMinting(false)

        handleAfterMintLoader()
        displayAlert('success', NFtDetails)
      }, 3000)
    } catch (error) {
      console.error('minting error--->222', error)
      setMinting(false)
      displayAlert('error', null)
    }

    setTimeout(() => {
      handleClose()
      setNftInformation({
        artWorkType: '',
        artworkName: '',
        artistName: '',
        socialLink: '',
        bio: '',
        nftImgUrl: '',
        nftImgFile: null,
      })
    }, 2500)
  }

  const { artworkName, artistName, socialLink, bio, nftImgUrl } = nftInformation
  const canMint: boolean = address && address.length > 0 && artworkName.length > 0 && nftImgUrl.length > 0 && !minting

  return (
    <>
      <DialogModal
        title="Create NFT"
        label="Create NFT"
        icon={<AddIcon />}
        disabled={address === undefined || address.length === 0}
        isOpen={isOpen}
        handleOpen={handleOpen}
        handleClose={handleClose}
      >
        <>
          <div className={`px-4 py-3  ${minting ? 'relative' : ''}`}>
            {minting && (
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40 flex flex-col items-center p-16 z-30 gap-4 backdrop-filter backdrop-grayscale">
                <ClipLoader color="#fff" />
                <span className="text-white text-xl font-semibold">Minting in process...</span>
                <span className="text-white mt-4">Note: Please do not refresh the page</span>
              </div>
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 dark:text-white">
              {/* Artwork Type */}
              <section>
                <label htmlFor="" className="text-sm block mb-1">
                  Artwork Type
                </label>
                <Dropdown
                  list={artworkTypeOptions}
                  selected={artworkTypeOpt}
                  onSelect={(item) => onChangeSelect(item)}
                />
              </section>

              {/* Artwork Name */}
              <section>
                <label htmlFor="artworkName" className="text-sm block mb-1">
                  Artwork Name
                </label>
                <input
                  className="w-full py-4 pl-4 pr-10 text-left bg-white dark:bg-gray-800 rounded-lg border border-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 sm:text-sm"
                  type="text"
                  id="artworkName"
                  name="artworkName"
                  placeholder="Mortal Kombat"
                  value={artworkName}
                  onChange={onChangeInput}
                />
              </section>

              {/* Artist Name */}
              <section>
                <label htmlFor="artistName" className="text-sm block mb-1">
                  Artist Name
                </label>
                <input
                  className="w-full py-4 pl-4 pr-10 text-left bg-white dark:bg-gray-800 rounded-lg border border-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 sm:text-sm"
                  type="text"
                  id="artistName"
                  name="artistName"
                  placeholder="Johnny Cage"
                  value={artistName}
                  onChange={onChangeInput}
                />
              </section>

              {/* Social Link */}
              <section>
                <label htmlFor="socialLink" className="text-sm block mb-1">
                  Social Media/Portfolio link
                </label>
                <input
                  className="w-full py-4 pl-4 pr-10 text-left bg-white dark:bg-gray-800 rounded-lg border border-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 sm:text-sm"
                  type="text"
                  id="socialLink"
                  name="socialLink"
                  placeholder="https://portfolio.com"
                  value={socialLink}
                  onChange={onChangeInput}
                />
              </section>

              {/* NFT Intro */}
              <section>
                <label htmlFor="bio" className="text-sm block mb-1">
                  Brief Introduction
                </label>
                <textarea
                  className="w-full h-48 py-4 pl-4 pr-10 text-left bg-white dark:bg-gray-800 rounded-lg border border-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 sm:text-sm"
                  rows={8}
                  id="bio"
                  name="bio"
                  value={bio}
                  placeholder="Enter the brief information"
                  onChange={onChangeTextArea}
                ></textarea>
              </section>

              {/* NFT Asset */}
              <section>
                <label htmlFor="" className="text-sm block mb-1">
                  Upload Artwork Image
                </label>
                <div className="relative w-full h-48 overflow-hidden text-left bg-white dark:bg-gray-800 rounded-lg border border-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 sm:text-sm">
                  <input
                    className="absolute top-0 left-0 bottom-0 right-0 w-full h-full opacity-0 cursor-pointer"
                    type="file"
                    name=""
                    id="nft-image-file"
                    onChange={onChangeImage}
                  />

                  <div className="w-full h-full">
                    {nftImgUrl !== '' && nftInformation.artWorkType === 'image' && (
                      <img className="w-full h-full object-contain" src={nftImgUrl} alt="nft-image" />
                    )}
                    {nftImgUrl !== '' && nftInformation.artWorkType === 'video' && (
                      <video autoPlay className="object-cover h-full w-full p-2">
                        <source className="w-full h-full object-contain" src={nftImgUrl} type="video/mp4" />
                      </video>
                    )}
                    {nftImgUrl.length === 0 && (
                      <div className="w-full h-full py-4 px-4 flex flex-col items-center justify-center gap-4">
                        <div className="w-12 h-12 flex items-center justify-center rounded-full bg-gray-100 text-gray-500">
                          <ArtworkIcon />
                        </div>
                        <div className="flex flex-col items-center justify-center gap-2 text-gray-400 dark:text-white text-sm md:text-base">
                          <p>Support: (.png/.jp[e]g/.gif)</p>
                          <p>Max Size: 10MB</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </div>

            <div className="w-full flex items-center justify-center py-2 bg-white dark:bg-gray-800">
              <button
                className={`glow-on-hover bg-gradient-to-r from-blue-400 via-green-300 to-purple-500 ${
                  !canMint && 'opacity-50'
                } text-white px-6 py-2 rounded-lg`}
                disabled={!canMint}
                onClick={onMint}
              >
                <span>{minting ? 'Minting...' : 'Mint'}</span>
              </button>
            </div>
          </div>
        </>
      </DialogModal>
    </>
  )
}

export default CreateNFT
