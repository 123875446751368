import React from 'react'
import { CopyIcon } from 'shared-next/Icons'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { getShortenAddress } from 'utils'
import Default_Profile_Pic from 'assets-next/images/defaultCollection.jpg'
import Default_Cover_Pic from 'assets-next/images/defaultCoverPic.jpg'

export interface CollectionHeaderTab {
  collection: any
  isCollectionAvailable: boolean
  collectionAddress: string
}

const Header = ({ collection, isCollectionAvailable, collectionAddress }: CollectionHeaderTab) => {
  const [isCopied, setCopied] = useCopyClipboard()
  const profile_pic = Default_Profile_Pic
  const cover_pic = Default_Cover_Pic

  return (
    <div className="h-80 md:h-96  overflow-hidden mb-2">
      <section className="relative  h-36  md:h-44  ">
        <div className="h-36  md:h-44 w-full ">
          <img src={cover_pic} className="w-full h-full  object-center object-cover" />
        </div>
      </section>

      <div className="flex flex-col gap-y-4  items-center justify-center">
        <div className="w-28 md:w-32 h-28 md:h-32 ring ring-4 ring-white bg-white rounded-full overflow-hidden transform -translate-y-1/2">
          <img
            src={isCollectionAvailable && collection.contractUri !== '' ? collection.contractUri : profile_pic}
            alt="profile_pic"
            className="object-cover"
          />
        </div>
      </div>
      <div className="flex flex-col  items-center justify-center overflow-hidden transform -translate-y-1/2   ">
        <div className="flex  flex-row  gap-x-2 ">
          <h4 className="text-2xl dark:text-white  font-semibold">
            {isCollectionAvailable && collection.name !== '' ? collection.name : getShortenAddress(collectionAddress)}
          </h4>
        </div>

        <div className="flex flex-row items-center  inline gap-x-2 dark:text-gray-400">
          <p>{getShortenAddress(collectionAddress)}</p>
          <button
            className="cursor-pointer flex items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 w-8 h-8 bg-gray-200 dark:bg-gray-600 dark:text-white"
            title={`${isCopied ? 'Copied!' : 'Copy Address'}`}
            onClick={() => setCopied(collectionAddress)}
          >
            <CopyIcon />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Header
