import gql from 'graphql-tag'

export const MY_NFT_ASSET = ({ account, quoteToken, artwork }) => {
  const queryString = `
          query transactions {
            owner(id: "${account ? account : ''}"){
             tokens{
                id,
                tokenURI,
                tokenID 
              }
            }
          }`
  return gql(queryString)
}

export const My_SOLD_NFT_ASSET = ({ account }) => {
  const queryString = `
          query transactions {
            tradeTransactions(
              where:{
                type:"Sell",
                seller:"${account ? account : ''}"
              }
            ) {
              token {
                tokenId,
                tokenCollection {
                  id
                }
                artworkType
                artworkName
                description
              }
              type
            }
          }`
  return gql(queryString)
}

export const My_All_NFT_COLLECTIONS_ASSET = ({ account }) => {
  //${account ? account : ''}
  const queryString = `
          query transactions {
             
              tokens(first: 20,
                where:{
                  owner:"${account ? account : ''}",
                  
                  
                }
              ) {
                contract {
                  id,
                  name,
                  symbol
                }
               
              }
          }`

  return gql(queryString)
}

export const My_PARTICULAR_COLLECTIONS_NFT_ASSET = ({ account, contract_address }) => {
  const queryString = `
          query transactions {
              
              tokens(first: 50,
                where:{
                  owner:"${account ? account : ''}",
                  contract:"${contract_address ? contract_address : ''}"
                }
              ) {
                contract {
                  id
                }
                id,
                tokenURI,
                tokenID 
              }
          }`
  return gql(queryString)
}
