import { editUserDetails } from 'services'

/*
 * Edit User Details
 */
export const editDetails = async (address, details) => {
  try {
    await editUserDetails(details, address)
    return true
  } catch (error) {
    console.error('Edit User Detail error: ', error)
    return false
  }
}
