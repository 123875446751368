import React from 'react'

const DisconnectIcon = () => (
  <svg width={14} height={14} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.573 13.908l.04-.04a3.885 3.885 0 01-5.483 0 3.885 3.885 0 010-5.484l1.598-1.599M8.425 2.089l-.041.04a3.885 3.885 0 015.484 0 3.885 3.885 0 010 5.484l-1.599 1.599M10.846 14.765L5.152 1.231"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default DisconnectIcon
