// global
export const ACTION_INIT_GLOBAL = 'ACTION_INIT_GLOBAL'
export const ACTION_INIT_LOADING = 'ACTION_INIT_LOADING'
export const ACTION_UPDATE_LOADING = 'ACTION_UPDATE_LOADING'

// wallet
export const ACTION_WALLET_LOADING = 'ACTION_WALLET_LOADING'
export const ACTION_WALLET_INIT = 'ACTION_WALLET_INIT'
export const ACTION_WALLET_SET_WEB3 = 'ACTION_WALLET_SET_WEB3'
export const ACTION_WALLET_SET_ADDRESS = 'ACTION_WALLET_SET_ADDRESS'
export const ACTION_WALLET_SET_CONNECT = 'ACTION_WALLET_SET_CONNECT'
export const ACTION_WALLET_SET_PROVIDER = 'ACTION_WALLET_SET_PROVIDER'
export const ACTION_WALLET_SET_ACCOUNT_INFO = 'ACTION_WALLET_SET_ACCOUNT_INFO'
export const ACTION_WALLET_SET_CONTRACTS = 'ACTION_WALLET_SET_CONTRACTS'
export const ACTION_WALLET_SET_NFT_TOTAL_SUPPLY = 'ACTION_WALLET_SET_NFT_TOTAL_SUPPLY'
export const ACTION_WALLET_SET_ACTIVE_CHAIN = 'ACTION_WALLET_SET_ACTIVE_CHAIN'

// nft
export const ACTION_NFT_SET_ALL_NFT_COLLECTIONS = 'ACTION_NFT_SET_ALL_NFT_COLLECTIONS'
export const ACTION_NFT_INIT = 'ACTION_NFT_INIT'
export const ACTION_NFT_LOADING = 'ACTION_NFT_LOADING'
export const ACTION_NFT_SET_MY_NFTS = 'ACTION_NFT_SET_MY_NFTS'
export const ACTION_NFT_SET_ALL_NFTS = 'ACTION_NFT_SET_ALL_NFTS'
export const ACTION_NFT_SET_ACTIVE_NFT = 'ACTION_NFT_SET_ACTIVE_NFT'
export const ACTION_NFT_SET_QUOTE_TOKENS = 'ACTION_NFT_SET_QUOTE_TOKENS'
export const ACTION_NFT_SET_ALL_ASSUME_NFTS = 'ACTION_NFT_SET_ALL_ASSUME_NFTS'
export const SET_SELECTED_COLLECTION = 'SET_SELECTED_COLLECTION'
export const ACTION_NFT_SET_MY_Sold_NFTS = 'ACTION_NFT_SET_MY_Sold_NFTS'
export const ACTION_NFT_SET_MY_Collection_NFTS = 'ACTION_NFT_SET_MY_Collection_NFTS'
export const ACTION_NFT_SET_MY_ONSALE_NFTS = 'ACTION_NFT_SET_MY_ONSALE_NFTS'

//profile
export const ACTION_Profile_LOADING = 'ACTION_Profile_LOADING'
export const ACTION_SET_USER_DETAILS = 'ACTION_SET_USER_DETAILS'
export const ACTION__FAIL_USER_DETAILS_FETCH = 'ACTION__FAIL_USER_DETAILS_FETCH'

// Market
export const ACTION_MARKET_LOADING = 'ACTION_MARKET_LOADING'
export const ACTION_MARKET_INIT_NFTS = 'ACTION_MARKET_INIT_NFTS'
export const ACTION_MARKET_INITIALIZE = 'ACTION_MARKET_INITIALIZE'
