export const getKeyValue = (nft, name) => {
  switch (name) {
    case 'artistName':
      return (
        nft.artistName || 
        nft.style_creator_name || 
        nft.creator_name || 
        nft.creatorName || 
        'Unnamed'
      )
    case 'artworkName':
      return (
        nft.artworkName || 
        nft.artwork_name || 
        nft.name || 
        nft.artwork || 
        nft.creatorName || 
        'No name'
      )
    case 'creatorAddress':
      return (
        nft.creatorAddress || 
        nft.creator_addr || 
        'NA'
      )
    default:
      return
  }
}