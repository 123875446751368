import React, { useState } from 'react'
import { ListProps } from 'shared-next/types'
import { Dropdown } from 'shared-next'

export interface PriceFilter {
  onApply: (query: any) => void
  list: ListProps[]
}

const PriceFilter = ({ onApply, list }: PriceFilter) => {
  const [min, setMin] = useState('')
  const [max, setMax] = useState('')
  const [activeQuoteTokenOption, setActiveQuoteTokenOption] = useState({ label: 'Select Token', value: 'all' })

  const handleApplyPriceFilter = () => {
    if (min === '' && max === '') return { min: 0, max: 9223372036854775807, ...{ activeQuoteTokenOption } }
    //  if (min === '') min = '0'

    onApply({ min: +min, max: +max, ...{ activeQuoteTokenOption } })
  }

  const handleMax = (e) => {
    const { currentTarget: input } = e
    if (input.value >= 0) {
      setMax(input.value)
    }
  }

  const handleMin = (e) => {
    const { currentTarget: input } = e
    if (input.value >= 0) {
      setMin(input.value)
    }
  }

  const onSelectToken = (item) => {
    // if (item.value !== activeQuoteTokenOption.value) {
    setActiveQuoteTokenOption(item)
    // }
    //onSelectQuoteToken(item)
  }

  return (
    <>
      <>
        {Object.keys(list).length > 0 && (
          <Dropdown list={list} selected={activeQuoteTokenOption} onSelect={(item) => onSelectToken(item)} />
        )}
      </>
      <div className="grid grid-flow-col text-center pt-1 gap-2 grid-cols-2 mt-2">
        <div className="">
          <input
            name="min"
            type="number"
            value={min}
            placeholder="Min"
            onChange={handleMin}
            className="no-spinner w-full border border-gray-300 rounded-lg dark:bg-gray-800 dark:text-white focus:outline-none hover:outline-none focus:border-none hover:border-none"
          />
        </div>

        <div className="">
          <input
            name="max"
            type="number"
            value={max}
            placeholder="Max"
            onChange={handleMax}
            className="no-spinner w-full border border-gray-300 rounded-lg dark:bg-gray-800 dark:text-white focus:outline-none hover:outline-none focus:border-none hover:border-none"
          />
        </div>
      </div>
      <div className="text-center pt-3 w-full">
        <button
          disabled={max !== '' && +min > +max ? true : false}
          type="button"
          onClick={handleApplyPriceFilter}
          className="px-4 py-2 glow-on-hover bg-gradient-to-r from-blue-400 via-green-300 to-purple-500 text-white rounded-lg ho"
        >
          Apply
        </button>
      </div>
    </>
  )
}

export default PriceFilter
