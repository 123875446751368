import * as actionTypes from '../constants'

const initialState = {
  profileLoader: false,
  isProfileDetailsAvailable: false,
  userDetails: {
    address: '',
    bio: '',
    coverImage: '',
    details: {},
    email: '',
    isVerified: false,
    name: 'NA',
    profileImage: '',
  },
}

function profileReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ACTION_Profile_LOADING:
      return { ...state, profileLoader: action.payload }
    case actionTypes.ACTION_SET_USER_DETAILS:
      return { ...state, userDetails: action.payload, profileLoader: false, isProfileDetailsAvailable: true }
    case actionTypes.ACTION__FAIL_USER_DETAILS_FETCH:
      return {
        ...state,
        userDetails: {
          address: '',
          bio: '',
          coverImage: '',
          details: {},
          email: '',
          isVerified: false,
          name: 'NA',
          profileImage: '',
        },
        profileLoader: false,
        isProfileDetailsAvailable: false,
      }
    default:
      return state
  }
}

export default profileReducer
