import gql from 'graphql-tag'
import { checkForAddress } from 'utils/index'
export const ASSERTSEARCH_ALL = ({ query }) => {
  const lower_query = query.toLowerCase()

  const queryString = `
            query transactions {
                    tokenAssets(first:5, where: { artworkName_search_starts_with:"${lower_query}"   } )
                    {
                      id,
                      tokenId,
                      tokenUri,
                      price,
                      quoteToken,
                      artworkName,
                      tokenCollection{
                        address
                      }
                      
                    }
                   
                   }`

  return gql(queryString)
}

export const COLLECTIONSEARCH_ALL = ({ query }) => {
  const isAddress = checkForAddress(query)

  const queryString =
    isAddress !== true
      ? `
  query transactions
  
  
  {
    tokenCollections(first:5, where:{name_starts_with: "${query}"})
    {
      name,
      contractUri,
      id,
      address
    }
  }
  
 `
      : `
      query transactions
      
      
      {
        tokenCollections(first:5, where:{id: "${query}"})
        {
          name,
          contractUri,
          id,
          address
        }
      }
      
     `

  return gql(queryString)
}
