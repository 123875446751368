import BSC from 'assets-next/images/BSC.png'
import AVAX from 'assets-next/images/avax.png'
import MOONRIVER from 'assets-next/images/moonriver.png'
import Arbitrum from 'assets-next/images/Arbitrum.svg'
import Evmos from 'assets-next/images/EVMOS.png'
//import ETH from 'assets-next/images/Ethereum.svg'
//import MATIC from 'assets-next/images/MATIC.svg'
import Metamask from 'assets-next/images/Metamask.png'
import WalletConnect from 'assets-next/images/WalletConnect.png'
import TrustWallet from 'assets-next/images/TWT.png'
import { ChainConfig, Config, ConnectorNames } from './types'
import { connectorsByName } from 'utils'
import { CHAIN_INFO } from 'utils/chains'

export const chains: ChainConfig[] = CHAIN_INFO.map((item) => {
  const subitem: any = {}
  subitem.label = item.label
  subitem.icon = item.logoUrl
  subitem.value = item.chainId
  return subitem
})

export const wallets: Config[] = [
  {
    title: 'Metamask',
    icon: Metamask,
    connectorId: ConnectorNames.Injected,
    connector: connectorsByName[ConnectorNames.Injected],
  },
  {
    title: 'WalletConnect',
    icon: WalletConnect,
    connectorId: ConnectorNames.WalletConnect,
    connector: connectorsByName[ConnectorNames.WalletConnect],
  },
  {
    title: 'Trust Wallet',
    icon: TrustWallet,
    connectorId: ConnectorNames.Injected,
    connector: connectorsByName[ConnectorNames.Injected],
  },
]

export const connectorLocalStorageKey = 'connectorId'
