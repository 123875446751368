import { getAllSearchResult } from 'utils'

const useFetchSearch = () => {
  // fetch search result
  const fetchSearchResult = async (query: '') => {
    const searchResult = await getAllSearchResult({ query })
    return searchResult
  }

  return {
    fetchSearchResult,
  }
}

export default useFetchSearch
