import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setProfileLoader, setUserDetails, setUserDetailsFailed } from 'store/actions'
import { RootState } from 'store/reducers'
import { fetchUserDetails } from 'utils'

const useFetchProfile = () => {
  const dispatch = useDispatch()

  const profileLoader = useSelector((state: RootState) => state.profile.profileLoader)
  const isProfileDetailsAvailable = useSelector((state: RootState) => state.profile.isProfileDetailsAvailable)
  const userDetails = useSelector((state: RootState) => state.profile.userDetails)

  const fetchUserProfileDetails = async (address: string) => {
    if (!address) return
    dispatch(setProfileLoader(true))
    try {
      const details = await fetchUserDetails({ address })

      dispatch(setUserDetails(details))
      dispatch(setProfileLoader(false))
    } catch (error) {
      dispatch(setUserDetailsFailed())
      dispatch(setProfileLoader(false))
    }
  }

  return {
    profileLoader,
    fetchUserProfileDetails,
    isProfileDetailsAvailable,
    userDetails,
  }
}

export default useFetchProfile
