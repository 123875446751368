import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootState } from 'store/reducers'
import { NftType } from 'store/types'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { CardList } from 'components-next'
import useFetchNfts from 'hooks/useFetchNfts'
import { SideBar } from './Components'
import { sortOptions } from './constants'
import { Dropdown, ErrorBoundry } from 'shared-next'
import { CloseIcon } from 'shared-next/Icons'
import { getAllNFTCollections, getTokenNameFromAddress } from 'utils'

import { setQuoteTokens, setCollection } from 'store/actions'

const Marketplace = () => {
  // Custom NFT fetch hook
  const {
    loading,
    pageNumber,
    canFetchMore,
    address,
    allNfts,
    setPageNumber,
    setCanFetchMore,
    fetchAllNftAssets,
    fetchMoreAllNftAssets,
    cleanupNfts,
    fetchMore,
    selectedCollection,
    web3,
  } = useFetchNfts()
  const dispatch = useDispatch()

  const history = useHistory()
  const contracts = useSelector((state: RootState) => state.wallet.contracts)
  // const { contracts } = walletReducer
  const { exchangeSC } = contracts

  // Local Variable
  const [filterOptions, setFilterOptions] = useState({
    category: '',
    collection: '',
    quoteToken: '',
    minPrice: 0,
    maxPrice: 9223372036854775807,
    artWork: '',
    sort: '',
  })
  const filterOptionsJsonString = JSON.stringify(filterOptions)

  //Loader
  const [collectionLoading, setCollectionLoading] = useState(false)
  const [nftCollections, setNftCollections] = useState<any>([{ label: 'Collection', value: 'all' }])
  const [quoteTokenOptions, setQuoteTokenOptions] = useState([])

  const [activeSortOption, setActiveSortOption] = useState({ label: 'Sort by', value: 'all' })

  /**
   * Fetching Initial 8 nfts on Market
   */
  const fetchMarketNftsInfo = async (option) => {
    await fetchAllNftAssets(
      option.collection,
      option.quoteToken,
      option.sort,
      option.minPrice,
      option.maxPrice,
      option.category,
      option.artWork,
    )
  }

  /**
   * Fetching nfts for paginantion on Market
   */
  const fetchMoreMarketNftsInfo = async (option, page) => {
    fetchMoreAllNftAssets(
      option.collection,
      option.quoteToken,
      option.minPrice,
      option.maxPrice,
      option.sort,
      option.category,
      option.artWork,
      page,
    )
  }

  /**
   * Function to handle Filter parameter values
   */
  const onFilterChanged = (data) => {
    const prevFilterData = { ...filterOptions }
    setFilterOptions({
      ...data,

      sort: prevFilterData.sort,
    })
  }

  /**
   * Function to handle Sort Option Change
   */
  const onSelectSortOption = (item) => {
    if (item.value !== activeSortOption.value) {
      setCanFetchMore(true)
      setPageNumber(0)
      setActiveSortOption(item)
      setFilterOptions({
        ...filterOptions,
        sort: item.value === 'all' ? '' : item.value,
      })
    }
  }

  /**
   *  fetch nft quote tokens
   */
  const fetchQuoteTokens = async () => {
    if (!exchangeSC) return
    if (exchangeSC && exchangeSC._address !== null) {
      let _quoteTokenOptions = []
      try {
        const _quoteTokens = await exchangeSC.methods.getQuoteTokens().call()

        _quoteTokenOptions = _quoteTokens.map((quoteToken) => {
          const quoteTokenName = getTokenNameFromAddress(quoteToken)
          return {
            label: quoteTokenName.length > 0 ? `${quoteTokenName}` : quoteToken,
            value: quoteToken,
          }
        })
        setQuoteTokenOptions(_quoteTokenOptions)
        dispatch(setQuoteTokens(_quoteTokens))
      } catch (e) {
        setQuoteTokenOptions(_quoteTokenOptions)
      }
    }
  }

  /**
   *
   * Utility function to structure  nft collections
   */
  const getAddressName = (collection) => {
    return {
      label: collection.name,
      value: collection.address,
      url: collection.contractUri,
    }
  }

  /**
   * fetching all nft collections
   */
  const fetchAllNftCollections = async () => {
    setCollectionLoading(true)
    const _nftCollections = await getAllNFTCollections()
    if (_nftCollections !== undefined && _nftCollections.length > 0) {
      const nftCollectionOptions = _nftCollections.map((collection) => getAddressName(collection))
      setNftCollections((prev) => [...prev, ...nftCollectionOptions])
    }

    setCollectionLoading(false)
  }

  const getCollectionNamefromGivenAddress = () => {
    const collection = nftCollections.find((item) => item.value === filterOptions.collection)
    if (collection) {
      return collection.label.length > 20 ? collection.label.substring(0, 20) + '...' : collection.label
    }

    if (selectedCollection) {
      return selectedCollection.label.length > 20
        ? selectedCollection.label.substring(0, 20) + '...'
        : selectedCollection.label
    }
    return ''
  }

  useEffect(() => {
    if (!web3 || Object.keys(web3).length === 0) return
    fetchQuoteTokens()
  }, [web3])

  useEffect(() => {
    fetchAllNftCollections()
  }, [])

  useEffect(() => {
    fetchMarketNftsInfo(filterOptions)

    return () => {
      cleanupNfts()
    }
  }, [filterOptionsJsonString])

  useEffect(() => {
    if (pageNumber > 0) {
      fetchMoreMarketNftsInfo(filterOptions, pageNumber)
    }
  }, [pageNumber, filterOptionsJsonString])

  /**
   * Function to change Route on select NFT
   */
  const onSelectNft = (collectionAddress: string, tokenId: string) => {
    if (collectionAddress === '' || tokenId === '') return
    const selectedNft = allNfts.find(
      (row: NftType) => row.collectionAddress === collectionAddress && row.tokenId === tokenId,
    )
    if (selectedNft) {
      history.push(`/assets/${selectedNft.collectionAddress}/${selectedNft.tokenId}`)
    }
  }

  return (
    <div className="flex">
      <SideBar
        onFilterChanged={onFilterChanged}
        filterOptions={filterOptions}
        setPageNumber={setPageNumber}
        setCanFetchMore={setCanFetchMore}
        selectedCollection={selectedCollection}
        collectionLoading={collectionLoading}
        nftCollections={nftCollections}
        quoteTokenOptions={quoteTokenOptions}
      />
      <section className="w-full px-4">
        <React.Fragment>
          <header
            className={
              filterOptions.collection !== ''
                ? 'py-4 flex items-center justify-between'
                : 'py-4 flex items-center justify-end'
            }
          >
            {filterOptions.collection !== '' && (
              <div className="w-2/5 pr-1 md:w-52 ">
                <span className="relative  inline-block border-2 py-4 rounded  px-2 text-xs border-gray-300 dark:text-white">
                  {getCollectionNamefromGivenAddress()}
                  <span
                    onClick={() => {
                      dispatch(setCollection({ label: '', value: '', url: '' }))
                      setFilterOptions({
                        ...filterOptions,
                        collection: '',
                      })
                    }}
                    className="absolute  cursor-pointer top-0 right-0 inline-flex items-center justify-center  text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-purple-500 rounded-full"
                  >
                    <CloseIcon />
                  </span>
                </span>
              </div>
            )}

            <div className="w-3/5 md:w-52 pl-2">
              <Dropdown list={sortOptions} selected={activeSortOption} onSelect={onSelectSortOption} />
            </div>
          </header>
          <div className="pb-4">
            <ErrorBoundry>
              <CardList
                nfts={allNfts}
                loader={loading}
                canFetchMore={canFetchMore}
                fetchMore={fetchMore}
                onSelectNft={onSelectNft}
                mintLoader={false}
              />
            </ErrorBoundry>
          </div>
        </React.Fragment>
      </section>
    </div>
  )
}

export default Marketplace
