import React from 'react'

const SelectedIcon = () => (
  <svg id="circle_done" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="#23C68B" />
    <path
      d="M6 10L8.66667 12.6667L13.3333 8"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SelectedIcon
