import { FavoriteIcon } from 'shared-next/Icons'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducers'

interface VoteProps extends React.HTMLProps<HTMLButtonElement> {
  canVote: boolean
  count: number
  sizing?: 'sm' | 'lg' | undefined
}

const Vote = ({ canVote, count, sizing = 'lg', onClick }: VoteProps) => {
  const walletReducer = useSelector((state: RootState) => state.wallet)
  const { address } = walletReducer

  return (
    <div className="flex items-center border border-gray-300 dark:bg-gray-700 rounded-lg">
      <button
        type="button"
        className={`flex items-center justify-center ${
          sizing === 'lg' ? 'w-10 h-10' : 'w-8 h-8'
        } text-indigo-500 hover:text-indigo-700 ${!address && 'cursor-default'}`}
        disabled={!address || !canVote}
        title={!address ? 'Connect to Wallet' : !canVote ? 'Liked' : 'Like'}
        onClick={onClick}
      >
        <FavoriteIcon active={!canVote} />
      </button>
      <p className={`font-medium ${sizing === 'lg' ? 'w-10 h-10' : 'w-8 h-8'} flex items-center justify-center`}>
        {count}
      </p>
    </div>
  )
}

export default React.memo(Vote)
