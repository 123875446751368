import React from 'react'
import { CopyIcon, CheckIcon } from 'shared-next/Icons'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { getShortenAddress } from 'utils'
import Default_Profile_Pic from 'assets-next/images/defaultUserProfile.jpg'
import Default_Cover_Pic from 'assets-next/images/defaultCoverPic.jpg'
import EditProfile from './EditProfile'

export interface UserTab {
  activeLink: string
  handleLinkChange: (newLink: string) => void
  address: string
  displayAlert: (state: any, details: any) => void
  isProfileDetailsAvailable: boolean
  userDetails: any
  web3: any

  profileTabs: any
  isMyProfile: boolean
}

const UserTab = ({
  activeLink,
  handleLinkChange,
  address,
  displayAlert,
  isProfileDetailsAvailable,
  userDetails,
  web3,
  profileTabs,
  isMyProfile,
}: UserTab) => {
  const [isCopied, setCopied] = useCopyClipboard()
  const profile_pic = Default_Profile_Pic
  const cover_pic = isProfileDetailsAvailable && userDetails.coverImage ? userDetails.coverImage : Default_Cover_Pic

  return (
    <div className="h-88 md:h-96  overflow-hidden mb-2">
      <section className="relative  h-44  md:h-56  ">
        <div className="h-44  md:h-56 w-full ">
          <img src={cover_pic} className="w-full h-full  object-center object-cover" />
        </div>
        {isMyProfile && (
          <div className=" absolute  bottom-2   z-30 right-4 flex py-2 px-2 justify-end ">
            <EditProfile address={address} displayAlert={displayAlert} userDetails={userDetails} web3={web3} />
          </div>
        )}
      </section>

      <section className="h-20 md:h-24  ">
        <div className="px-2 md:px-4 flex flex-row gap-x-4 md:gap-x-4 md:justify-start justify-between">
          <div className="w-20 md:w-28 h-20 md:h-28 ring ring-4 ring-white bg-white rounded-full overflow-hidden transform -translate-y-1/2">
            <img
              src={isProfileDetailsAvailable && userDetails.profileImage ? userDetails.profileImage : profile_pic}
              alt="profile_pic"
              className="object-cover"
            />
          </div>
          <div className="flex flex-col pt-1  ">
            <div className="flex  flex-row  gap-x-2 ">
              <h4 className="  text-lg md:text-2xl w-40 font-semibold truncate overflow-hidden md:overflow-visible dark:text-white">
                {isProfileDetailsAvailable ? userDetails.name : 'NA'}
              </h4>
              {isProfileDetailsAvailable && userDetails.isVerified && (
                <p className="text-green-600  align-middle bg-green-200 rounded-full ">
                  <CheckIcon />
                </p>
              )}
            </div>

            <div className="flex text-xm md:text-base   flex-row items-center  inline gap-x-2 dark:text-gray-400">
              <p>{getShortenAddress(address)}</p>
              <button
                className="cursor-pointer flex items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 w-8 h-8 bg-gray-200 dark:bg-gray-600 dark:text-white"
                title={`${isCopied ? 'Copied!' : 'Copy Address'}`}
                onClick={() => setCopied(address)}
              >
                <CopyIcon />
              </button>
            </div>
          </div>
        </div>
      </section>

      {/**
       * Tab Options
       */}
      <section className="flex   items-center gap-2 md:gap-10 text-gray-600 dark:text-white px-2 sm:px-6 pb-2  justify-between sm:justify-start">
        {profileTabs.map((tab) => (
          <div
            key={tab.name}
            onClick={() => handleLinkChange(tab.value)}
            className={
              ' flex flex-col sm:flex-row items-center gap-2 cursor-pointer ' +
              (activeLink === tab.value
                ? 'text-purple-500 dark:text-gray-400 bg-purple-100 dark:bg-gray-600 font-semibold rounded-lg p-2'
                : 'hover:text-purple-500 focus:text-purple-500 dark:hover:text-gray-400 dark:focus:text-gray-400 ')
            }
          >
            {tab.icon}
            <span className=" md:inline-block text-xs sm:text-sm">{tab.name}</span>
          </div>
        ))}
      </section>
    </div>
  )
}

export default UserTab
