import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

type ScrollToViewProps = React.HTMLProps<HTMLDivElement>

const ScrollToView = ({ children }: ScrollToViewProps) => {
  const location = useLocation()
  const [prevLocation, setPrevLocation] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (prevLocation !== location.pathname) window.scrollTo(0, 0)
    return setPrevLocation(location.pathname)
  })

  return <>{children}</>
}

export default ScrollToView
