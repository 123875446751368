import bscLogoUrl from 'assets-next/images/BSC.png'
import avaxLogoUrl from 'assets-next/images/avax.png'
import moonriverLogoUrl from 'assets-next/images/moonriver.png'
import arbitrumLogoUrl from 'assets-next/images/Arbitrum.svg'
import evmosLogoUrl from 'assets-next/images/EVMOS.png'
import mainnetLogoUrl from 'assets-next/images/Ethereum.svg'
import bscScanLogoUrl from 'assets-next/images/bscScan.jpg'
import Snowtrace from 'assets-next/images/snowtrace.svg'
import moonbeamLogoUrl from 'assets-next/images/Moonbeam_logo.png'
import fantomLogoUrl from 'assets-next/images/fantom.png'
import ftmscanLogoUrl from 'assets-next/images/ftmscan.png'
import auroraLogoUrl from 'assets-next/images/aurora.svg'
import shidenLogoUrl from 'assets-next/images/Shiden.png'
import astarLogoUrl from 'assets-next/images/Astar.png'

export enum SupportedChainId {
  // RINKEBY = 4,
  ARBITRUM_ONE = 42161,
  Astar_Mainnet = 592,
  // Aurora = 1313161554,
  Avax_Mainnet = 43114,
  BSC_Mainnet = 56,
  Fantom = 250,
  Moonbeam = 1284,
  Moonriver = 1285,
  Shiden = 336,
}

export const ALL_SUPPORTED_CHAIN_IDS = [
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.BSC_Mainnet,
  SupportedChainId.Avax_Mainnet,
  SupportedChainId.Moonriver,
  SupportedChainId.Moonbeam,
  SupportedChainId.Fantom,
  //  SupportedChainId.Aurora,
  SupportedChainId.Astar_Mainnet,
  SupportedChainId.Shiden,
]

export const CHAIN_INFO = [
  {
    chainId: SupportedChainId.ARBITRUM_ONE,
    explorer: 'https://arbiscan.io/',
    explorerName: 'Arbiscan',
    explorerlogoUrl: arbitrumLogoUrl,
    label: 'Arbitrum',
    logoUrl: arbitrumLogoUrl,
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    wrapperLink: 'https://app.dodoex.io/exchange/ETH-WETH?network=arbitrum',
  },
  {
    chainId: SupportedChainId.Avax_Mainnet,
    explorer: 'https://snowtrace.io/',
    explorerName: 'Snowtrace',
    explorerlogoUrl: Snowtrace,
    label: 'Avax',
    logoUrl: avaxLogoUrl,
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    wrapperLink: 'https://traderjoexyz.com/#/trade?outputCurrency=0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
  },

  {
    chainId: SupportedChainId.Moonriver,
    explorer: 'https://moonriver.moonscan.io/',
    explorerName: 'Moonscan',
    explorerlogoUrl: moonriverLogoUrl,
    label: 'Moonriver',
    logoUrl: moonriverLogoUrl,
    rpcUrls: ['https://rpc.moonriver.moonbeam.network/'],
    wrapperLink: 'https://app.dodoex.io/exchange/MOVR-WMOVR?network=moonriver',
  },
  {
    chainId: SupportedChainId.Moonbeam,
    explorer: 'https://moonbeam.moonscan.io/',
    explorerName: 'Moonbeam',
    explorerlogoUrl: moonbeamLogoUrl,
    label: 'Moonrbeam',
    logoUrl: moonbeamLogoUrl,
    rpcUrls: ['https://rpc.api.moonbeam.network'],
    wrapperLink: 'https://app.solarflare.io/exchange/swap',
  },
  {
    chainId: SupportedChainId.Fantom,
    explorer: 'https://ftmscan.com/',
    explorerName: 'FTMScan',
    explorerlogoUrl: ftmscanLogoUrl,
    label: 'Fantom',
    logoUrl: fantomLogoUrl,
    rpcUrls: ['https://rpc.ftm.tools/'],
    wrapperLink:
      'https://matcha.xyz/markets/250/0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  },
  // {
  //   chainId: SupportedChainId.Aurora,
  //   explorer: 'https://aurorascan.dev/',
  //   explorerName: 'Aurora',
  //   explorerlogoUrl: auroraLogoUrl,
  //   label: 'Aurora',
  //   logoUrl: auroraLogoUrl,
  //   rpcUrls: ['https://mainnet.aurora.dev'],
  //   wrapperLink: '',
  // },
  {
    chainId: SupportedChainId.BSC_Mainnet,
    explorer: 'https://bscscan.com/',
    explorerName: 'Bscscan',
    explorerlogoUrl: bscScanLogoUrl,
    label: 'BSC-Mainnet',
    logoUrl: bscLogoUrl,
    rpcUrls: ['https://bsc-dataseed1.binance.org'],
    wrapperLink: 'https://app.dodoex.io/exchange/BNB-WBNB?network=bsc-mainnet',
  },
  {
    chainId: SupportedChainId.Shiden,
    explorer: 'https://blockscout.com/shiden/',
    explorerName: 'Shiden',
    explorerlogoUrl: shidenLogoUrl,
    label: 'Shiden',
    logoUrl: shidenLogoUrl,
    rpcUrls: ['https://rpc.shiden.astar.network:8545'],
    wrapperLink: 'https://app.standard.tech/trade/buy?outputCurrency=0x00e856ee945a49bb73436e719d96910cd9d116a4',
  },
  {
    chainId: SupportedChainId.Astar_Mainnet,
    explorer: 'https://blockscout.com/astar/',
    explorerName: 'Astar',
    explorerlogoUrl: astarLogoUrl,
    label: 'Astar',
    logoUrl: astarLogoUrl,
    rpcUrls: ['https://rpc.astar.network:8545'],
    wrapperLink: 'https://app.arthswap.org/#/swap',
  },
]
