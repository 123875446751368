import * as actionTypes from '../constants'

export const initWallet = (payload: any) => ({
  type: actionTypes.ACTION_WALLET_INIT,
  payload,
})

export const setWalletLoading = (payload: any) => ({
  type: actionTypes.ACTION_WALLET_LOADING,
  payload,
})

export const setWeb3Object = (web3: any) => ({
  type: actionTypes.ACTION_WALLET_SET_WEB3,
  payload: web3,
})
export const setWalletAddress = (address: any) => ({
  type: actionTypes.ACTION_WALLET_SET_ADDRESS,
  payload: address,
})

export const setChainId = (Id: number) => ({
  type: actionTypes.ACTION_WALLET_SET_ACTIVE_CHAIN,
  payload: Id,
})

export const setWalletProvider = (provider: any) => ({
  type: actionTypes.ACTION_WALLET_SET_PROVIDER,
  payload: provider,
})

export const setAccountInfo = (accountInfo: any) => ({
  type: actionTypes.ACTION_WALLET_SET_ACCOUNT_INFO,
  payload: accountInfo,
})

export const setContractObjects = (contracts: any) => ({
  type: actionTypes.ACTION_WALLET_SET_CONTRACTS,
  payload: contracts,
})
