export const menuItems = [
  {
    id: 0,
    name: 'Marketplace',
    icon: 'trolley',
    value: '/marketplace',
  },
  {
    id: 1,
    name: 'My Profile',
    icon: 'user',
    value: '/my-profile',
  },
]
