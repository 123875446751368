import { useEffect, useState } from 'react'

import { ConnectorNames } from 'components-next/WalletModal/types'
import { connectorLocalStorageKey } from 'components-next/WalletModal/config'
import { infuraProvider, walletConnectProvider } from 'config/constants/provider'
import useAuth from 'hooks/useAuth'
import { hexToDec } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/reducers'
import { chains } from 'components-next/WalletModal/config'
import { setWalletAddress, setWalletProvider, setWeb3Object, setChainId } from 'store/actions'
import Web3 from 'web3'
import { connectorsByName } from 'utils/web3React'

const connector = () => {
  const selectedChainId = useSelector((state: RootState) => state.wallet.activeChainID)
  const supportedChain = useSelector((state: RootState) => state.wallet.supportedChain)

  const [chainError, setChainError] = useState('')
  const [metaChainId, setMetaChainId] = useState(null)
  const dispatch = useDispatch()
  const { login, logout } = useAuth()

  const isChainMatched = (chainId: number, selectedChainId: number) => {
    const isSupportive = supportedChain.find((chain) => chain === chainId)
    if (!isSupportive) {
      return setChainError(`Please switch your network to different chain`)
    }

    const storedChainId = Number(window.localStorage.getItem('chainId'))
    if (!storedChainId) return

    const selectedChain = chains.filter((chain) => chain.value === storedChainId)[0]
    if (selectedChain === undefined) return setChainError(`Please switch your network to different chain`)
    if (chainId !== storedChainId)
      return setChainError(`Please switch your network to ${(selectedChain && selectedChain.label) || 'BSC'}`)

    return setChainError('')
  }

  const onWeb3Connect = async () => {
    const connectorId = (window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames) || 'injected'
    try {
      const connector = connectorsByName[connectorId]

      const { provider } = await connector.activate()

      //const provider = await window.ethereum

      if (provider) {
        await provider.enable()
        //  window.ethereum.enable()

        const web3Object = await new Web3(provider)

        const accounts = await web3Object.eth.getAccounts()
        const chainId = await web3Object.eth.getChainId()
        setMetaChainId(Number(chainId))
        isChainMatched(Number(chainId), selectedChainId)

        dispatch(setWeb3Object(web3Object))
        dispatch(setWalletProvider(provider))
        dispatch(setWalletAddress(accounts[0]))

        // Subscribe to accounts change
        provider.on('accountsChanged', (_accounts: string[]) => {
          dispatch(setWalletAddress(_accounts[0]))
        })

        // chainChanged

        provider.on('chainChanged', (Id) => {
          const chain = hexToDec(Id)
          //  setMetaChainId(Number(chain))
          window.localStorage.setItem('chainId', chain.toString())
          //  dispatch(setChainId(+chain))
          window.location.reload()
        })

        // Subscribe to provider disconnection
        provider.on('disconnect', (error: any) => {
          console.error(error)
          dispatch(setWalletAddress(''))
          dispatch(setWeb3Object(new Web3(infuraProvider)))
          dispatch(setWalletProvider(infuraProvider))
        })
      }
    } catch (e) {
      console.log('onWeb3Connect Error', e)
    }
  }

  const persistLogin = async () => {
    const connectorId = window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames
    if (connectorId) {
      login(connectorId)
      onWeb3Connect()
    }
  }

  const persistChain = async () => {
    if (metaChainId) return isChainMatched(metaChainId, selectedChainId)

    const provider = await window.ethereum

    if (provider) {
      window.ethereum.enable()
      const web3Object = await new Web3(provider)
      const chainId = await web3Object.eth.getChainId()
      return isChainMatched(Number(chainId), selectedChainId)
    }
  }

  useEffect(() => {
    persistChain()
  }, [selectedChainId, metaChainId])

  return {
    login,
    logout,
    onWeb3Connect,
    persistLogin,
    chainError,
  }
}

export default connector
