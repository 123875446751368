import React from 'react'
import { WalletModal } from 'components-next'
import { ChevronUpIcon } from 'shared-next/Icons'
import connector from 'containers/main/connector'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/reducers'
import { ChainConfig } from 'components-next/WalletModal/types'
import { toHex } from 'utils'
import { setChainId } from 'store/actions'

const NoWallet = ({ metamask }: { metamask: boolean }) => {
  const { login, logout, onWeb3Connect } = connector()
  const dispatch = useDispatch()

  const walletReducer = useSelector((state: RootState) => state.wallet)
  const { web3, address } = walletReducer

  // Handle Chain
  const handleChain = async (item: ChainConfig) => {
    if (!web3) return

    try {
      let chainId = item.value
      if (chainId === null) chainId = 56

      const hexString = toHex(item.value)
      const provider = await window.ethereum
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: hexString }],
      })

      dispatch(setChainId(chainId))
    } catch (error) {
      console.error('Chain Switch: ', error)
    }
  }

  return (
    <div className="w-full py-8 flex flex-col items-center justify-center" style={{ height: 'calc(100vh - 4rem)' }}>
      {!metamask ? (
        <div className="flex flex-col items-center justify-center gap-6 text-center dark:text-white">
          <h4 className="text-xl md:text-3xl lg:text-4xl">You need a web3 wallet to use Gamyfi NFT marketplace.</h4>
          <a
            href="https://metamask.io/download.html"
            target="_blank"
            rel="noreferrer noopener"
            className="inline-flex items-center justify-center py-4 px-4 glow-on-hover bg-gradient-to-r from-blue-400 via-green-300 to-purple-500 rounded-lg text-white"
          >
            Get Metamask
          </a>
          {/*
          <p className="flex items-center justify-center text-center">
            <span className="transform rotate-90">
              <ChevronUpIcon />
            </span>
            <span>or use different wallet</span>
          </p>
          */}
          <WalletModal
            title={'or use different wallet'}
            login={login}
            logout={logout}
            onWeb3Connect={onWeb3Connect}
            address={address}
            handleChain={handleChain}
            isButton={false}
          />
        </div>
      ) : (
        <div className="flex flex-col  items-center justify-center text-center gap-6">
          <h4 className="text-xl md:text-3xl lg:text-4xl dark:text-white">Sign in to your wallet</h4>
          <WalletModal
            title={'Connect Wallet'}
            login={login}
            logout={logout}
            onWeb3Connect={onWeb3Connect}
            address={address}
            handleChain={handleChain}
          />
        </div>
      )}
    </div>
  )
}

export default NoWallet
