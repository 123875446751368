import React, { Fragment, useState } from 'react'
//import Logo from 'assets-next/icons/logo.svg'
import Logo from 'assets-next/icons/logo.png'
import Logo_Md from 'assets-next/icons/logo-md.svg'
import { menuItems } from './constants'
import { useHistory } from 'react-router'
import WalletModal from 'components-next/WalletModal'
import { ChainConfig, Login } from 'components-next/WalletModal/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/reducers'
import { ChevronDownIcon } from 'shared-next/Icons'
import useScreen from 'hooks/useScreen'
import { Transition } from '@headlessui/react'
import SearchBar from './components/searchbar'
import useFetchSearch from 'hooks/useFetchSearch'
import { Dropdown } from 'shared-next'
import { chains } from 'components-next/WalletModal/config'
import ChainSelect from './components/ChainSelect'
import { setChainId } from 'store/actions'
import { toHex } from 'utils'
import { ThemeToggleButton } from 'Theme'
import { CHAIN_INFO } from 'utils/chains'
//import AddQuoteToken from 'components-next/AddQuoteToken'

interface HeaderProps {
  login: Login
  logout: () => void
  onWeb3Connect: () => Promise<void>
}

const Header = ({ login, logout, onWeb3Connect }: HeaderProps) => {
  //Search Component Variable
  const dispatch = useDispatch()
  const [nftassets, setNftAssets] = useState(null)
  const [searchLoader, setSearchLoader] = useState(false)
  const [searchDataAvailabe, setSearchDataAvailabe] = useState(false)

  const history = useHistory()
  const { pathname } = history.location
  const { isMobile } = useScreen()
  const [isOpen, setIsOpen] = useState(false)

  const web3 = useSelector((state: RootState) => state.wallet.web3)
  const address = useSelector((state: RootState) => state.wallet.address)
  const activeChainID = useSelector((state: RootState) => state.wallet.activeChainID)

  const { fetchSearchResult } = useFetchSearch()

  const onSelectMenu = (item: any) => {
    isMobile && setIsOpen(false)
    item.value && history.push(`${item.value}`)
  }

  /**
   * Search Handler
   */
  const onSearchQueryHandler = async (query) => {
    if (query === '') return
    try {
      setSearchLoader(true)

      const assets = await fetchSearchResult(query)

      if (assets[0].data.length > 0 || assets[1].data.length > 0) setSearchDataAvailabe(true)
      else {
        setSearchDataAvailabe(false)
      }
      setSearchLoader(false)
      setNftAssets(assets)
    } catch (e) {
      console.log(e)
    }
  }

  // Handle Chain
  const handleChain = async (item: ChainConfig) => {
    // if (!web3) return

    try {
      //  const hexString = toHex(item.value)
      //  const provider = await window.ethereum
      //  await provider.request({
      //    method: 'wallet_switchEthereumChain',
      //    params: [{ chainId: hexString }],
      //  })
      window.localStorage.setItem('chainId', item.value.toString())
      dispatch(setChainId(item.value))
    } catch (error) {
      console.error('Chain Switch: ', error)
    }
  }

  const WrapButtonMoile = () => {
    const chainId = activeChainID
    const wrapData = CHAIN_INFO.find((item) => item.chainId === chainId)
    if (wrapData && wrapData.wrapperLink !== '') {
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={wrapData.wrapperLink}
          className="relative text-sm flex items-center px-4 py-3 cursor-pointer hover:bg-purple-100 focus:outline-none focus:bg-purple-100"
        >
          <span className="dark:text-white">Wrap</span>
        </a>
      )
    }
  }

  const WrapButton = () => {
    const chainId = activeChainID

    const wrapData = CHAIN_INFO.find((item) => item.chainId === chainId)
    if (wrapData && wrapData.wrapperLink !== '')
      return (
        <a
          className="relative h-full flex items-center px-4 cursor-pointer"
          target="_blank"
          rel="noopener noreferrer"
          href={wrapData.wrapperLink}
        >
          <span className="whitespace-nowrap"> Wrap</span>
        </a>
      )
  }

  return (
    <header className="sticky top-0 flex items-center justify-between gap-1 px-3 md:px-5 lg:px-8 h-16 shadow-md bg-white dark:bg-gray-800 z-50">
      <div className="flex items-center gap-1 ">
        <div className="h-full flex items-center">
          <a href="/" className="">
            <img src={Logo} alt="logo" className="hidden md:block h-8 w-36" />
            <img src={Logo_Md} alt="logo" className="block md:hidden h-auto w-8" />
          </a>
        </div>

        {isMobile && (
          <div
            className="text-gray-600 dark:text-white h-full flex items-center justify-center cursor-pointer md:hidden"
            onClick={() => setIsOpen((isOpen) => !isOpen)}
          >
            <span className={`transform transition-all ${isOpen ? 'transform rotate-180' : ''}`}>
              <ChevronDownIcon />
            </span>
          </div>
        )}
      </div>
      {pathname !== '/home' && (
        <SearchBar
          Loader={searchLoader}
          DataAvailabe={searchDataAvailabe}
          Assets={nftassets}
          onSearch={onSearchQueryHandler}
        />
      )}
      <nav className="flex items-center h-full gap-x-2 font-medium">
        {!isMobile && (
          <React.Fragment>
            <ul className="flex items-center text-sm h-full text-gray-500 dark:text-white">
              {WrapButton()}
              {menuItems.map((item) => (
                <li
                  key={item.id}
                  onClick={() => onSelectMenu(item)}
                  className={`relative h-full flex items-center px-4 cursor-pointer ${
                    pathname.includes(item.value) && 'text-black dark:text-gray-400'
                  }`}
                >
                  {pathname.includes(item.value) && (
                    <span className="block w-2 h-2 rounded-full bg-purple-500 absolute bottom-2 left-1/2 transform -translate-x-1/2"></span>
                  )}
                  <span className="whitespace-nowrap">{item.name}</span>
                </li>
              ))}
            </ul>
          </React.Fragment>
        )}

        <ChainSelect
          list={chains}
          selected={chains.filter((chain) => chain.value === activeChainID)[0]}
          onSelect={(item) => handleChain(item)}
        />
        {/*
        <AddQuoteToken /> */}

        <WalletModal
          login={login}
          logout={logout}
          onWeb3Connect={onWeb3Connect}
          address={address}
          handleChain={handleChain}
        />
        <ThemeToggleButton />

        {isMobile && (
          <>
            <Transition
              show={isOpen}
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <aside
                className="absolute top-16 right-0 left-0 bottom-0 bg-black bg-opacity-40"
                style={{ height: 'calc(100vh - 4rem)' }}
                onClick={() => setIsOpen(false)}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="flex flex-col w-1/2 bg-white dark:bg-gray-800 ml-auto shadow-sm h-full">
                    {menuItems.map((item) => (
                      <button
                        key={item.id}
                        onClick={() => onSelectMenu(item)}
                        className={`relative text-sm flex items-center px-4 py-3 cursor-pointer hover:bg-purple-100 focus:outline-none focus:bg-purple-100 ${
                          pathname.includes(item.value) &&
                          'text-black border-l-4 bg-purple-100 dark:bg-gray-700 border-purple-500'
                        }`}
                      >
                        <span className="dark:text-white">{item.name}</span>
                      </button>
                    ))}

                    {WrapButtonMoile()}
                  </div>
                </Transition.Child>
              </aside>
            </Transition>
          </>
        )}
      </nav>
    </header>
  )
}

export default Header
