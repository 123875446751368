import * as actionTypes from '../constants'
import { IGlobalState, GlobalActionTypes } from '../types'

const initialState: IGlobalState = {
  loading: false,
}

function globalReducer(state = initialState, action: GlobalActionTypes) {
  switch (action.type) {
    case actionTypes.ACTION_INIT_GLOBAL:
      return { ...initialState }
    case actionTypes.ACTION_INIT_LOADING:
      return { ...initialState, loading: false }
    case actionTypes.ACTION_UPDATE_LOADING:
      return { ...state, loading: action.payload }
    default:
      return state
  }
}

export default globalReducer
