import React from 'react'

export enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
  BSC = 'bsc',
}

export interface Config {
  title: string
  icon: string
  connectorId?: ConnectorNames
  connector?: any
}

export interface ChainConfig {
  label: string
  icon: string
  value: number
}

export type Login = (connectorId: ConnectorNames) => void

export interface ConnectWallet {
  checked: boolean
  handleWalletConnect: Login
  handleClick: () => void
  handleChain: (item: ChainConfig) => void
}

export interface AccountActionType {
  address: string
  shortAddress: string
  setChange: React.Dispatch<React.SetStateAction<boolean>>
  disconnect: () => void
}
