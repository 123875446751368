import { Transition } from '@headlessui/react'
import { SearchIcon, CheckIcon } from 'shared-next/Icons'
import React, { useEffect, useState } from 'react'
import { ListProps } from 'shared-next/types'
import { ClipLoader } from 'react-spinners'

interface ListBoxProps {
  list: ListProps[]
  selected: ListProps
  onSelect: (item) => void
  loading?: boolean
}

const FilterListBox = ({ list, selected, onSelect, loading = false }: ListBoxProps) => {
  const [filterList, setFilterList] = useState(list)

  const handleChange = (e) => {
    const filterVal = e.target.value.toLowerCase()
    if (filterVal === '') return setFilterList(list)

    const result = Object.values(filterList).filter((value) => value.label.toLowerCase().includes(filterVal))
    return setFilterList(result)
  }

  useEffect(() => {
    setFilterList(list)
  }, [list])

  return (
    <Transition
      appear={true}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div>
        <div className="w-full h-12 overflow-hidden rounded-lg bg-white dark:bg-gray-800  flex items-center border border-gray-300 dark:text-white">
          <span className="pl-4 pr-2 bg-white dark:bg-gray-800">
            <SearchIcon />
          </span>
          <input
            type="text"
            name="filter"
            className="border-0 w-full h-full outline-none focus:ring-0 dark:bg-gray-800 dark:text-white"
            placeholder="Filter"
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col w-full py-1 px-1 mt-3 overflow-auto text-base max-h-60 focus:outline-none sm:text-sm">
          {filterList &&
            filterList.map((item, idx) => (
              <button
                key={idx}
                type="button"
                className={`cursor-pointer select-none relative py-3 px-4 max-w-full flex items-center gap-2 justify-between rounded-md focus:outline-none focus:bg-purple-200 dark:focus:bg-gray-700 hover:text-purple-600 dark:hover:text-gray-600 ${
                  selected.value === item.value ? 'text-purple-600' : 'text-gray-900 dark:text-white'
                }`}
                onClick={() => onSelect(item)}
              >
                <div className="flex items-center gap-2">
                  {item.icon !== undefined ? (
                    <span
                      className={
                        selected.value === item.value
                          ? 'w-8 h-8 rounded-full text-purple-600 overflow-hidden pt-1'
                          : 'w-8 h-8 rounded-full  overflow-hidden text-purple-500 pt-1'
                      }
                    >
                      {<item.icon />}
                    </span>
                  ) : (
                    <span className="w-8 h-8 rounded-full bg-gradient-to-r from-blue-400 via-green-300 to-purple-500 overflow-hidden">
                      {item.url && (
                        <img
                          src={item.url}
                          className="w-full h-full object-cover"
                          onError={(e) => {
                            e.currentTarget.remove()
                          }}
                        />
                      )}
                    </span>
                  )}

                  <span className="font-medium w-24 truncate ">{item.label}</span>
                </div>
                {selected.value === item.value && (
                  <span>
                    <CheckIcon />
                  </span>
                )}
              </button>
            ))}
          {loading && (
            <div className="w-full flex items-center justify-center">
              <ClipLoader color="#8b5cf6" />
            </div>
          )}
        </div>
      </div>
    </Transition>
  )
}

export default React.memo(FilterListBox)
