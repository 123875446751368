import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import { PersistGate } from 'redux-persist/integration/react'
import App from 'containers/app'
import { store, persistor } from 'store'
import './index.css'
import '@fortawesome/fontawesome-free/css/all.css'

import { getLibrary } from './utils/web3React'
import { NetworkContextName } from 'hooks/useAuth'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

const Root = () => (
  <Web3ReactProvider getLibrary={getLibrary}>
    <Web3ProviderNetwork getLibrary={getLibrary}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </Web3ProviderNetwork>
  </Web3ReactProvider>
)

render(<Root />, document.getElementById('root'))
