import NftTokenABI from './abis/nftToken.json'
import ExchangeNftABI from './abis/exchangeNft.json'
import QuoteTokenABI from './abis/quoteToken.json'
import GfxTokenABI from './abis/gfx.json'

export const abis = {
  nftToken: NftTokenABI,
  gfxToken: GfxTokenABI,
  exchangeNFT: ExchangeNftABI,
  quoteToken: QuoteTokenABI,
}

const addresses = {
  main: {
    exchangeNFT: '0x83c3f65A1022c7cF01E371ecbC88A31f89Aba755',
    nftToken: '0x75798587e2F5647F1CF3FE994B5d37aAfEbe51CB',
    // quoteToken: '0x3f4B45aB5262CA6A64d00ce4f6d287e16a77C072',
    // gfxToken: '0x450e369e9bd0e0bea6744651fa5988dd7e98c98a',
    marketApi: '',
    profileApi: '',
  },
  rinkeby: {
    exchangeNFT: '0xe051607F57c4d0f3e1b599C02a669F3348ACfa17',
    nftToken: '0xeee2D461954BF50Ced841bc7a9e8dE87003dF232',
    // quoteToken: '0x3f4B45aB5262CA6A64d00ce4f6d287e16a77C072',
    // gfxToken: '0x450e369e9bd0e0bea6744651fa5988dd7e98c98a',
    marketApi: 'https://api.thegraph.com/subgraphs/name/rsharma94k/gfx-rinkeby',
    profileApi: 'https://api.thegraph.com/subgraphs/name/daisai3/eip721_rinkeby',
  },

  bscmainnet: {
    exchangeNFT: '0x073AB482cBB7587089a4B40d120C5B752283ABd5',
    nftToken: '0xF7C33c08f739516677e40e7f104d5774510E0059',
    // quoteToken: '0x3f4B45aB5262CA6A64d00ce4f6d287e16a77C072',
    // gfxToken: '0xe4fa3c576c31696322e8d7165c5965d5a1f6a1a5',
    marketApi: 'https://api.thegraph.com/subgraphs/name/rsharma94k/gfx',
    profileApi: 'https://api.thegraph.com/subgraphs/name/rsharma94k/gfx-eip-short',
  },

  avaxmain: {
    exchangeNFT: '0x4ab743236646D6b03397959e8DF19b69bE42489C',
    nftToken: '0x41b83ec65d2fc46cbfd5ca9a74a28b02ae7cf852',
    // quoteToken: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    // gfxToken: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    marketApi: 'https://api.thegraph.com/subgraphs/name/rsharma94k/nft-avax',
    profileApi: 'https://api.thegraph.com/subgraphs/name/rsharma94k/gfx-eip-short',
  },
  arbitrum: {
    exchangeNFT: '0x16E2f1af4Aab1cfD5B127Ab5F96667E42d6540CE',
    nftToken: '0x2cCc583d629Cb3279DAEa286474Bd537c82E9094',
    // quoteToken: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    // gfxToken: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    marketApi: 'https://api.thegraph.com/subgraphs/name/rsharma94k/nft-arb',
    profileApi: '',
  },
  moonriver: {
    exchangeNFT: '0xFe6bad5DcCFb174d8Bf383e7C52825742348c187',
    nftToken: '0xfb48387625b36AD6B2B420020FfA9E53E159C5ba',
    // quoteToken: '',
    // gfxToken: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    marketApi: 'https://api.thegraph.com/subgraphs/name/rsharma94k/nft-moonriver',
    profileApi: '',
  },
  moonbeam: {
    exchangeNFT: '0x1460fe950C260670f941b018345F0E870e0f78Dc',
    nftToken: '0xFe6bad5DcCFb174d8Bf383e7C52825742348c187',
    marketApi: '',
    profileApi: '',
  },
  fantom: {
    exchangeNFT: '0x1460fe950C260670f941b018345F0E870e0f78Dc',
    nftToken: '0xFe6bad5DcCFb174d8Bf383e7C52825742348c187',
    marketApi: '',
    profileApi: '',
  },
  aurora: {
    exchangeNFT: '0x1460fe950C260670f941b018345F0E870e0f78Dc',
    nftToken: '0xFe6bad5DcCFb174d8Bf383e7C52825742348c187',
    marketApi: '',
    profileApi: '',
  },
  shiden: {
    exchangeNFT: '0x1460fe950C260670f941b018345F0E870e0f78Dc',
    nftToken: '0xFe6bad5DcCFb174d8Bf383e7C52825742348c187',
    marketApi: '',
    profileApi: '',
  },
  astarMainnet: {
    exchangeNFT: '0x1460fe950C260670f941b018345F0E870e0f78Dc',
    nftToken: '0xFe6bad5DcCFb174d8Bf383e7C52825742348c187',
    marketApi: '',
    profileApi: '',
  },
}

export const getContractAddresses = (network) => {
  switch (`${network}`) {
    case '1':
      return addresses.main
    case '4':
      return addresses.rinkeby
    case '56':
      return addresses.bscmainnet
    case '43114':
      return addresses.avaxmain
    case '42161':
      return addresses.arbitrum
    case '1285':
      return addresses.moonriver

    case '1284':
      return addresses.moonbeam
    case '250':
      return addresses.fantom
    case '1313161554':
      return addresses.aurora
    case '336':
      return addresses.shiden
    case '592':
      return addresses.astarMainnet
  }
}

export const quoteTokens = [
  {
    name: 'GFX_TOKEN',
    address: '0x450E369e9bd0e0bEA6744651fa5988dd7e98c98A',
    symbol: 'oldGFX',
    decimal: 18,
  },
  {
    name: 'OLD_GFX_TOKEN2',
    address: '0xe4fa3c576c31696322e8d7165c5965d5a1f6a1a5',
    symbol: 'oldGFX',
    decimal: 18,
  },

  {
    name: 'QTT_TOKEN1',
    address: '0x0E2d56cD9F07b4E7aaa2604Ae226A18131cB87dc',
    symbol: 'QTT',
    decimal: 18,
  },
  {
    name: 'QTT_TOKEN2',
    address: '0x3f4B45aB5262CA6A64d00ce4f6d287e16a77C072',
    symbol: 'QTT1',
    decimal: 18,
  },
  {
    name: 'ETH_TOKEN',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    symbol: 'ETH',
    decimal: 18,
  },
  {
    name: 'WETH_TOKEN',
    address: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    symbol: 'WETH',
    decimal: 18,
  },
  //Binance Tokens
  {
    name: 'BSC_GFX_TOKEN',
    address: '0x65ad6a2288b2dd23e466226397c8f5d1794e58fc',
    symbol: 'GFX',
    decimal: 18,
  },
  {
    name: 'BSC_WBNB_TOKEN',
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    symbol: 'WBNB',
    decimal: 18,
  },
  {
    name: 'BSC_BUSD_TOKEN',
    address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    symbol: 'BUSD',
    decimal: 18,
  },
  //AVAX Quote Tokens
  {
    name: 'AVAX_WAVAX_TOKEN',
    address: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    symbol: 'WAVAX',
    decimal: 18,
  },
  {
    name: 'AVAX_USDT_TOKEN',
    address: '0xc7198437980c041c805a1edcba50c1ce5db95118',
    symbol: 'USDT',
    decimal: 6,
  },
  {
    name: 'AVAX_USDC_TOKEN',
    address: '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
    symbol: 'USDC',
    decimal: 6,
  },
  //Arbitrum Quote Token
  {
    name: 'ARBITRUM_WETH_TOKEN',
    address: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    symbol: 'WETH',
    decimal: 18,
  },
  {
    name: 'ARBITRUM_USDC_TOKEN',
    address: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    symbol: 'USDC',
    decimal: 6,
  },
  {
    name: 'ARBITRUM_USDT_TOKEN',
    address: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    symbol: 'USDT',
    decimal: 6,
  },

  //Moonriver Quote Token
  {
    name: 'MOONRIVER_WMOVR_TOKEN',
    address: '0x98878b06940ae243284ca214f92bb71a2b032b8a',
    symbol: 'WMOVR',
    decimal: 18,
  },
  {
    name: 'MOONRIVER_USDC_TOKEN',
    address: '0xe3f5a90f9cb311505cd691a46596599aa1a0ad7d',
    symbol: 'USDC',
    decimal: 6,
  },
  {
    name: 'MOONRIVER_ETH_TOKEN',
    address: '0x639a647fbe20b6c8ac19e48e2de44ea792c62c5c',
    symbol: 'ETH',
    decimal: 18,
  },

  //Moonbeam
  {
    name: 'Moonbeam_WGLMR_TOKEN',
    address: '0xacc15dc74880c9944775448304b263d191c6077f',
    symbol: 'WGLMR',
    decimal: 18,
  },
  {
    name: 'Moonbeam_USDC_TOKEN',
    address: '0x8f552a71EFE5eeFc207Bf75485b356A0b3f01eC9',
    symbol: 'USDC',
    decimal: 6,
  },

  //Fantom
  {
    name: 'Fantom_WFTM_TOKEN',
    address: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    symbol: 'WFTM',
    decimal: 18,
  },
  {
    name: 'Fantom_USDC_TOKEN',
    address: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    symbol: 'USDC',
    decimal: 6,
  },

  //Aurora
  {
    name: 'AURORA_AURORA_TOKEN',
    address: '0x8bec47865ade3b172a928df8f990bc7f2a3b9f79',
    symbol: 'AURORA',
    decimal: 18,
  },
  {
    name: 'AURORA_NEAR_TOKEN',
    address: '0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d',
    symbol: 'NEAR',
    decimal: 18,
  },
  {
    name: 'AURORA_USDT_TOKEN',
    address: '0x4988a896b1227218e4a686fde5eabdcabd91571f',
    symbol: 'USDT',
    decimal: 6,
  },
  {
    name: 'AURORA_USDC_TOKEN',
    address: '0xb12bfca5a55806aaf64e99521918a4bf0fc40802',
    symbol: 'USDC',
    decimal: 6,
  },
  //Astar Mainnet
  {
    name: 'ASTAR_WASTR_TOKEN',
    address: '0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720',
    symbol: 'WASTR',
    decimal: 18,
  },

  //Shiden
  {
    name: 'SHIDEN_WSDN_TOKEN',
    address: '0x0f933Dc137D21cA519ae4C7E93f87a4C8EF365Ef',
    symbol: 'WSDN',
    decimal: 18,
  },
]
