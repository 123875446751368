import { ArrowLeftSM } from 'shared-next/Icons'
import React from 'react'
import { useHistory } from 'react-router'

interface NotFoundProps extends React.HTMLProps<HTMLDivElement> {
  title?: string
  description?: string
  buttonLabel?: string
  goTo?: string
}

const NotFound = ({
  title = 'Looks like we have lost you',
  buttonLabel = 'Back to Dashboard',
  goTo = 'dashboard',
  description,
  children,
}: NotFoundProps) => {
  const history = useHistory()

  return (
    <div className="flex flex-col items-center justify-center" style={{ height: 'calc(100vh - 4rem)' }}>
      {children ? (
        <> {children} </>
      ) : (
        <div className="font-playfair text-3xl md:text-6xl font-semibold dark:text-white">
          Error <span className="text-red-500 font-bold">404</span>
        </div>
      )}
      <div className="flex flex-col items-center mt-4">
        <h1 className="text-lg md:text-xl font-medium text-gray-800 dark:text-gray-300">{title}</h1>

        {description && <p className="text-base text-gray-600 dark:text-gray-400 mt-1">{description}</p>}

        <button
          onClick={() => history.push(`/${goTo}`)}
          className="flex items-center px-4 py-2 glow-on-hover bg-gradient-to-r from-blue-400 via-green-300 to-purple-500 text-white rounded-lg mt-4"
        >
          <ArrowLeftSM />
          <span>{buttonLabel}</span>
        </button>
      </div>
    </div>
  )
}

export default NotFound
