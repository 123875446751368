import { combineReducers } from 'redux'
import globalReducer from './globalReducer'
import walletReducer from './walletReducer'
import nftReducer from './nftReducer'
import profileReducer from './profileReducer'

const reducers = combineReducers({
  global: globalReducer,
  wallet: walletReducer,
  nft: nftReducer,
  profile: profileReducer,
})

export type RootState = ReturnType<typeof reducers>
export default reducers
