import { GraphQLClient } from 'graphql-request'
import { getContractAddresses } from 'contracts'

export function getCovalentApiUrl(chainId, address) {
  return `https://api.covalenthq.com/v1/${chainId}/address/${address}/balances_v2/?nft=true&key=ckey_f5af67ce8cbe4383b83b8f67175&match=%7B$or:%5B%7Bsupports_erc:%7B$elemmatch:%22erc721%22%7D%7D,%7Bsupports_erc:%7B$elemmatch:%22erc1155%22%7D%7D%5D%7D`
}

export const _gamyFiMarketPlaceClient = (network) => {
  const getChainId = network.toString() || localStorage.getItem('chainId') || '56'
  const addressDetails = getContractAddresses(getChainId)
  return new GraphQLClient(addressDetails.marketApi)
}

export const _gamyFiMyProfileClient = (network) => {
  const getChainId = network.toString() || localStorage.getItem('chainId') || '56'
  const addressDetails = getContractAddresses(getChainId)
  return new GraphQLClient(addressDetails.profileApi)
}
