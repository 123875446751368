import React from 'react'
import { SunIcon, MoonIcon } from 'shared-next/Icons'
import { ThemeContext } from './themeContext'

const Toggle = () => {
  const { theme, setTheme } = React.useContext(ThemeContext)

  return (
    <div className="transition duration-500 ease-in-out rounded-full p-1 pr-4 w-3">
      {theme === 'dark' ? (
        <span
          onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
          className="text-gray-500 dark:text-gray-400 text-2xl cursor-pointer"
        >
          <SunIcon />
        </span>
      ) : (
        <span
          onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
          className="text-gray-500 dark:text-gray-400 text-2xl cursor-pointer"
        >
          <MoonIcon />
        </span>
      )}
    </div>
  )
}

export default Toggle
