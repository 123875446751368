import axios from 'axios'

const baseURL = process.env.REACT_APP_GAMYFI_API_URL || 'http://localhost:5000'

export const httpClient = axios.create({
  baseURL,
})

export const fetchTradeHistories = async (chainId = 56) => {
  return await httpClient.get(`/market/trade?chainId=${chainId}`)
}

export const fetchTokenUriDetails = async (uri) => {
  return await httpClient.get(uri)
}

export const fetchAskHistories = async (chainId: string | number = 56) => {
  return await httpClient.get(`/market/ask?chainId=${chainId}`)
}

export const fetchBidHistories = async (chainId = 56) => {
  return await httpClient.get(`/market/bid?chainId=${chainId}`)
}

export const fetchCancelHistories = async (chainId = 56) => {
  return await httpClient.get(`/market/cancel?chainId=${chainId}`)
}

export const fetchNfts = async (query) => await httpClient.get(`/nft/market${query}`)

export const fetchMarketBids = async (query) => {
  const url = '/nft/bids' + query
  return await httpClient.get(url)
}

export const fetchAllUserCollections = async (owner) => {
  return await httpClient.get(`/user/collections?owner=${owner}`)
}

/**
 * Fetching Single Asset
 */
export const fetchSingleNft = async (query) => {
  const url = '/nft/single' + query
  return await httpClient.get(url)
}

/**
 * fetching collections
 */
export const fetchCollections = async (query) => {
  const url = 'nft/collections' + query
  return await httpClient.get(url)
}

/**
 * Fetching Market NFTs
 */
export const fetchMarketNfts = async (query) => {
  const url = '/nft/market' + query
  return await httpClient.get(url)
}

export const fetchTransactionHistory = async (query) => {
  const url = '/nft/transaction' + query
  return await httpClient.get(url)
}

/**
 * Fetching User Assets
 */
export const fetchAllUserNfts = async (query) => {
  const url = '/user/all' + query
  return await httpClient.get(url)
}

/**
 * Fetching User Collections
 */
export const fetchUserCollections = async (query) => {
  const url = '/user/collections' + query
  return await httpClient.get(url)
}

/**
 * fetching on sale assests
 */
export const fetchAllUserOnSaleNfts = async (query) => await httpClient.get(`/user/onSale${query}`)

/**
 * Fetching Sold assests
 */
export const fetchAllUserSoldNfts = async (query) => await httpClient.get(`/user/sold${query}`)

/**
 * Edit User Details
 */
export const editUserDetails = async (data, address) => {
  const url = `/user/update?address=${address}`
  return await httpClient.put(url, {
    address: data.address,
    signature: data.signature,
    name: data.name,
    email: data.email,
    bio: data.bio,
    profileImage: data.profileFile,
    coverImage: data.coverFile,
  })
}

/**
 * Fetch User Details
 */
export const fetchUser = async (address) => await httpClient.get(`/user/details?address=${address}`)

export const fetchVotes = async (query) => {
  const url = '/user/votes' + query
  return await httpClient.get(url)
}
