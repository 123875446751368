import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
import Web3 from 'web3'
import getNodeUrl from './getRpcUrl'
import { ConnectorNames } from 'components-next/WalletModal/types'
import { ALL_SUPPORTED_CHAIN_IDS, CHAIN_INFO } from './chains'

const NETWORK_URLS = {}
for (let i = 0; i < CHAIN_INFO.length; i++) {
  NETWORK_URLS[CHAIN_INFO[i].chainId] = CHAIN_INFO[i].rpcUrls[0]
}

//const POLLING_INTERVAL = 12000
//const rpcUrl = getNodeUrl()
//export const chainId = parseInt(process.env.REACT_APP_CHAIN_ID ?? '1', 10)
//export const chainId = parseInt(process.env.REACT_APP_CHAIN_ID ?? '56', 10)

const injected = new InjectedConnector({ supportedChainIds: ALL_SUPPORTED_CHAIN_IDS })

const walletconnect = new WalletConnectConnector({
  supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
  rpc: NETWORK_URLS,
  //bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  // pollingInterval: POLLING_INTERVAL,
})

//const bscConnector = new BscConnector({ supportedChainIds: [chainId] })
const bscConnector = new BscConnector({ supportedChainIds: ALL_SUPPORTED_CHAIN_IDS })

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.BSC]: bscConnector,
}

export const getLibrary = (provider: Web3) => {
  return provider
}
