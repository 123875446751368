import React, { useState, useEffect } from 'react'
import { SearchIcon, CloseIcon } from 'shared-next/Icons'
import { useHistory } from 'react-router'
//import useFetchNfts from 'hooks/useFetchNfts'
import Assets_pic from 'assets-next/images/assets.png'
import { ClipLoader } from 'react-spinners'
import { useDispatch } from 'react-redux'
//import { setCollection } from 'store/actions'

export interface SearchBar {
  onSearch: (query: any) => void
  Assets: any
  Loader: any
  DataAvailabe: any
}

function SearchBar({ onSearch, Assets, Loader, DataAvailabe }: SearchBar) {
  const [searchQuery, setSearchQuery] = useState('')
  // const { fetchAllNftAssets, setCanFetchMore, setPageNumber, selectedCollection } = useFetchNfts()

  /// const [currentCollection, setCurrentCollection] = useState(selectedCollection)

  const history = useHistory()
  // const dispatch = useDispatch()

  useEffect(() => {
    if (searchQuery === '') return
    onSearch(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    const onClick = (e) => {
      const Element = document.getElementById('search_input')
      if (Element !== e.target) setSearchQuery('')
    }

    document.addEventListener('click', onClick)
    return () => window.removeEventListener('click', onClick)
  }, [])

  /*
   * Handle Value Change
   */
  const handleChange = ({ currentTarget: input }) => {
    setSearchQuery(input.value)
  }

  /**
   * Set searchQuery = ''
   */
  const handleReset = () => {
    setSearchQuery('')
  }

  /**
   * Handle Assets Change Function
   */
  const handleAssetsChange = (item) => {
    const collection = item.tokenCollection
    setSearchQuery('')
    if (collection === '' || undefined) return 0
    history.push(`/assets/${collection}/${item.tokenId}`)
    return
  }

  /**
   * Handle Collection Change Function
   */
  const handleCollectionChange = ({ address, name }) => {
    setSearchQuery('')
    history.push(`/collection/${address}`)
    return
    /*
    setCurrentCollection(address)
    dispatch(setCollection({ label: name, value: address }))
    if (address !== currentCollection) {
      setCanFetchMore(true)
      setPageNumber(0)
    }
    if (history.location.pathname !== '/marketplace') {
      history.push(`/marketplace`)
    }*/
  }

  /*
  useEffect(() => {
    fetchUserInfo()
  }, [currentCollection])

  const fetchUserInfo = async () => {
    fetchAllNftAssets(currentCollection, 'all', 'all', 0, 999)
  }
*/
  return (
    <>
      <div aria-placeholder="search_header" id="search_header" className="md:relative   w-2/5 md:w-1/3">
        <div
          aria-placeholder="search_header"
          className="SearchInput p-2 border-2 rounded-lg flex transform transition-all hover:border-purple-500 focus:border-purple-500 overflow-x-hidden"
          aria-expanded="false"
        >
          <div aria-placeholder="search_header" className="text-gray-400 dark:text-white">
            <SearchIcon />
          </div>
          <input
            id="search_input"
            aria-placeholder="search_header"
            type="text "
            onChange={handleChange}
            className="text-xs w-full pl-2 focus:outline-none hover:outline-none focus:border-none hover:border-none  dark:text-white  bg-white dark:bg-gray-800  "
            placeholder="Search collections, and NFT"
            value={searchQuery}
          ></input>
          {searchQuery && (
            <span className="cursor-pointer text-gray-500 dark:text-white hover:text-purple-500" onClick={handleReset}>
              <CloseIcon />
            </span>
          )}
        </div>

        <div className="searchpopbox ">
          {Assets !== null && searchQuery !== '' && (
            <div className="mt-2 absolute w-full left-0  ">
              <div className=" border  border-gray-300 bg-white dark:bg-gray-800 rounded-lg overflow-y-auto max-h-48 shadow text-black-300 dark:text-white ">
                <div>
                  {Loader === true ? (
                    <div className="text-center my-4">
                      <ClipLoader color={'dark:text-white'} />
                    </div>
                  ) : (
                    <div>
                      {DataAvailabe === true ? (
                        <React.Fragment>
                          {
                            /**
                             * Collection Data
                             */
                            Assets[0].data.length > 0 && (
                              <div className="p-1 border-b">
                                <h6 className="text-left text-gray-400 dark:text-white text-sm px-2 py-2 border-b-2">
                                  {Assets[0].name}
                                </h6>
                                <ul>
                                  {Assets[0].data.map((item) => (
                                    <li
                                      onClick={() => {
                                        handleCollectionChange(item)
                                      }}
                                      className="py-4 px-2 text-left cursor-pointer font-medium hover:bg-gray-50 dark:hover:bg-gray-700"
                                      key={`collection${item.id}`}
                                    >
                                      <span className=" ">
                                        <img
                                          src={item.image || Assets_pic}
                                          alt=""
                                          className="rounded-full w-8 h-8 inline-block"
                                        />
                                        &nbsp; <span className="truncate"> {item.name.toUpperCase()}</span>
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )
                          }

                          {
                            /**
                             * Assets Data
                             */
                            Assets[1].data.length > 0 && (
                              <div className="p-1 border-b">
                                <h6 className="text-left text-gray-400 dark:text-white text-sm px-2 py-2 border-b-2">
                                  {Assets[1].name}
                                </h6>
                                <ul>
                                  {Assets[1].data.map((item) => (
                                    <li
                                      onClick={() => {
                                        handleAssetsChange(item)
                                      }}
                                      className="py-4 px-2 text-left cursor-pointer font-medium hover:bg-gray-50 dark:hover:bg-gray-700"
                                      key={`asset_${item.id}`}
                                    >
                                      <span className=" ">
                                        <img
                                          src={item.image || Assets_pic}
                                          alt=""
                                          className="rounded-full w-8 h-8 inline-block"
                                        />
                                        &nbsp;<span className="truncate"> {item.artworkName.toUpperCase()}</span>
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )
                          }
                        </React.Fragment>
                      ) : (
                        <div className="py-2 text-center text-sm">No Result Found</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default SearchBar
