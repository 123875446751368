import React from 'react'
import { CopyIcon } from 'shared-next/Icons'
import useCopyClipboard from 'hooks/useCopyClipboard'

const Copy = (props: any) => {
  const [isCopied, setCopied] = useCopyClipboard()

  return (
    <button
      type="button"
      className="flex items-center gap-x-2 bg-gray-200 dark:bg-gray-700 px-3 py-1 rounded-lg text-gray-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600"
      onClick={() => setCopied(props.toCopy)}
    >
      <CopyIcon />
      <p className="text-sm font-medium">{!isCopied ? 'Copy Address' : 'Copied!'}</p>
    </button>
  )
}

export default Copy
