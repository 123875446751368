import * as actionTypes from '../constants'

export const setProfileLoader = (payload: any) => ({
  type: actionTypes.ACTION_Profile_LOADING,
  payload,
})

export const setUserDetails = (payload: any) => ({
  type: actionTypes.ACTION_SET_USER_DETAILS,
  payload,
})

export const setUserDetailsFailed = () => ({
  type: actionTypes.ACTION__FAIL_USER_DETAILS_FETCH,
})
