import { Listbox, Transition } from '@headlessui/react'
import { SelectorIcon, CheckIcon } from 'shared-next/Icons'
import React, { Fragment } from 'react'
import { ListProps } from 'shared-next/types'

interface DropdownProps {
  list: ListProps[]
  selected: ListProps
  onSelect: (item) => void
}

const Dropdown = ({ list, selected, onSelect }: DropdownProps) => {
  return (
    <div>
      <Listbox value={selected} onChange={onSelect}>
        <div className="relative">
          <Listbox.Button className="relative w-full py-4 pl-4 pr-10 text-left bg-white dark:bg-gray-800 rounded-lg border border-gray-300 cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm dark:text-white">
            <span className="block truncate">{selected.label}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <SelectorIcon aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white dark:bg-gray-800 rounded-md border border-gray-200 shadow-md max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10 ">
              {list.map((item, idx) => (
                <Listbox.Option
                  key={idx}
                  className={({ active }) =>
                    `${
                      active
                        ? 'text-purple-900 dark:text-gray-400 bg-purple-200 dark:bg-gray-600'
                        : 'text-gray-900 dark:text-white'
                    }
                          cursor-default select-none relative py-2 pl-10 pr-4`
                  }
                  value={item}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`${
                          selected ? 'font-medium text-purple-600 dark:text-gray-400' : 'font-normal'
                        } block truncate`}
                      >
                        {item.label}
                      </span>
                      {selected ? (
                        <span
                          className={`${
                            active ? 'text-purple-600 dark:text-gray-400' : 'text-purple-600 dark:text-gray-400'
                          }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                        >
                          <CheckIcon aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

export default React.memo(Dropdown)
