import { quoteTokens } from 'contracts'
export * from './getRpcUrl'
export * from './subgraph'
export * from './web3'
export * from './web3React'

export const getShortenAddress = (address: string) => {
  if (!address) return ''
  return address.slice(0, 6) + '.....' + address.slice(-4)
}

export const getOwnerShortenAddress = (address: string) => {
  if (!address) return ''
  return address.slice(0, 4) + '...' + address.slice(-2)
}

export const getDateinto24Hr = (dateString: string) => {
  const completDate = new Date(+dateString * 1000)

  const date = completDate.getDate() + '-' + (completDate.getMonth() + 1) + '-' + completDate.getFullYear()

  return date
}

export const checkForAddress = (address: string) => {
  if (address.length !== 42) return false
  if (address.substring(0, 2) !== '0x') return false
  return true
}

export const getTokenNameFromAddress = (address: string) => {
  if (!address) return ''
  const quote = quoteTokens.find((quote) => quote.address.toLowerCase() === address.toLowerCase())
  if (quote) return quote.symbol
  return ''
}

export const removeDecimalFromPrice = (address: string, price: number) => {
  if (!address) return null
  if (price === undefined || price === null) return null
  const quote = quoteTokens.find((quote) => quote.address.toLowerCase() === address.toLowerCase())
  if (quote) {
    if (quote.decimal === 6) {
      return price / 1e6
    } else return price / 1e18
  }
  return null
}

export const removeDecimalFromPriceUsingSymbol = (symbol: string, price: number) => {
  if (!symbol) return null
  if (price === undefined || price === null) return null
  const quote = quoteTokens.find((quote) => quote.symbol.toLowerCase() === symbol.toLowerCase())
  if (quote) {
    if (quote.decimal === 6) {
      return price / 1e6
    } else return price / 1e18
  }
  return null
}

export const addDecimalInPrice = (address: string, price: number) => {
  if (!address) return null
  if (price === undefined || price === null) return null
  const quote = quoteTokens.find((quote) => quote.address.toLowerCase() === address.toLowerCase())
  if (quote) {
    if (quote.decimal === 6) {
      return price * 1e6
    } else return price * 1e18
  }
  return null
}

export const toHex = (num) => {
  return '0x' + num.toString(16)
}

export const hexToDec = (hexString) => {
  const hex = hexString.substring(2, hexString)
  return parseInt(hex, 16)
}

export const extractDetails = (res, parent_Key = '', giveParentKey = false) => {
  const data = {}
  for (const key in res) {
    if (typeof res[key] === 'object') {
      let d
      if (key === 'image' || key === 'name' || key === 'description') d = extractDetails(res[key], key, true)
      else d = extractDetails(res[key])
      for (const key1 in d.data) {
        if (key === 'name') data['artworkName'] = d.data[key1]
        else data[key1] = d.data[key1]
      }
    } else {
      if (key !== 'type') {
        if (giveParentKey) data[parent_Key] = res[key]
        else {
          if (key === 'name') data['artworkName'] = res[key]
          else data[key] = res[key]
        }
      }
    }
  }

  return { data }
}

export const isChainAvalanche = (chainId: number) => {
  return chainId !== 43114 ? true : false
}

export const enableRestApi = () => {
  return true
}
