import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ipfsURL, pinataApiKey, pinataApiSecret, pinataApiURL } from 'config/index'
import { ClipLoader } from 'react-spinners'
import { ArtworkIcon, EditIcon } from 'shared-next/Icons'
import { DialogModal } from 'shared-next'
import { editDetails } from '../service'

interface INftCreateModalProps {
  address: string
  displayAlert: (state: string, details: any) => void
  userDetails: any
  web3: any
}

const EditProfile = ({ address, displayAlert, userDetails, web3 }: INftCreateModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState(userDetails)
  const [iserror, setError] = useState({ name: '', email: '' })
  const [profileFile, setProfileFile] = useState(null)
  const [coverFile, setCoverFile] = useState(null)

  const [loader, setLoader] = useState<boolean>(false)

  const handleOpen = () => {
    setIsOpen((isOpen) => !isOpen)
  }

  const handleClose = () => {
    if (loader) return

    setIsOpen(false)
    setError({
      ...iserror,
      name: '',
      email: '',
    })
  }

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setData({
      ...data,
      [name]: value,
    })
  }

  // onChangeTextArea
  const onChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setData({
      ...data,
      [name]: value,
    })
  }

  // onChangeImage
  const onChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]

      const types = file.type.split('/')
      if (types[0] !== 'image') return
      if (types[1] === 'gif') return
      if (e.target.id === 'profileFile') {
        setData({
          ...data,
          profileImage: URL.createObjectURL(file),
        })
        setProfileFile(file)
      } else {
        setData({
          ...data,
          coverImage: URL.createObjectURL(file),
        })
        setCoverFile(file)
      }
    }
  }

  const pinFileToIPFS = async (file) => {
    if (!file) return null
    const pinImgURL = `${pinataApiURL}/pinning/pinFileToIPFS`
    const data = new FormData()
    data.append('file', file)
    try {
      const pinImage = await axios.post(pinImgURL, data, {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': `multipart/form-data;`,
          pinata_api_key: pinataApiKey,
          pinata_secret_api_key: pinataApiSecret,
        },
      })

      return `${ipfsURL}${pinImage.data.IpfsHash}`
    } catch (err) {
      console.error('pinImg Err', err)
      return null
    }
  }

  const validate = () => {
    const errs = { name: '', email: '' }
    const { name, email } = data

    if (name.length > 15) errs.name = 'Name should be less than 15 letters'
    const mail_format = /\S+@\S+\.\S+/
    if (email && email !== '') if (!mail_format.test(email)) errs.email = 'Wrong Email Format'

    setError({
      ...iserror,
      ...errs,
    })
    return errs
  }

  const handleEditForm = async () => {
    const errors = validate()
    if (errors.name !== '' || errors.email !== '') return

    setLoader(true)
    try {
      const profileUrl = await pinFileToIPFS(profileFile)
      const coverUrl = await pinFileToIPFS(coverFile)
      const msg = 'hello world'
      const msgHash = await web3.eth.accounts.hashMessage(msg)
      const signature = await web3.eth.sign(msgHash, address)
      const details = {
        address: address,
        signature: signature,
        name: data.name,
        email: data.email,
        bio: data.bio,
        profileFile: profileUrl,
        coverFile: coverUrl,
      }

      const isSuccess = await editDetails(address, details)
      if (isSuccess) displayAlert('success2', null)
      else displayAlert('error', null)
    } catch (err) {
      displayAlert('error', null)
    }

    handleClose()
    setLoader(false)
  }

  useEffect(() => {
    setData(userDetails)
  }, [userDetails, address])

  const { bio, name, email, profileImage, coverImage } = data

  const canEdit: boolean = !!name || !!email || !!profileImage || !!coverImage

  return (
    <>
      <DialogModal
        title=""
        label="Edit Details"
        icon={<EditIcon />}
        disabled={address.length === 0 || !web3}
        isOpen={isOpen}
        handleOpen={handleOpen}
        handleClose={handleClose}
      >
        <>
          <div className={`px-4 py-3  ${loader ? 'relative' : ''}`}>
            {loader && (
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40 flex flex-col items-center p-16 z-30 gap-4 backdrop-filter backdrop-grayscale">
                <ClipLoader color="#fff" />
                <span className="text-white text-xl font-semibold"> Processing...</span>
                <span className="text-white mt-4">Note: Please do not refresh the page</span>
              </div>
            )}

            <div className=" grid grid-cols-1 md:grid-cols-2 gap-4 dark:text-white">
              {/*Name */}
              <section>
                <label htmlFor="name" className="text-sm block mb-1">
                  Name
                </label>
                <input
                  className="w-full py-4 pl-4 pr-10 text-left bg-white dark:bg-gray-800 rounded-lg border border-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 sm:text-sm"
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Unknown"
                  value={name === null ? '' : name}
                  onChange={onChangeInput}
                />
                {iserror.name !== '' && (
                  <div className="w-full py-2 text-xs text-red-600">
                    <span>{iserror.name}</span>
                  </div>
                )}
              </section>
              {/*Email */}
              <section>
                <label htmlFor="name" className="text-sm block mb-1">
                  Email
                </label>
                <input
                  className="w-full py-4 pl-4 pr-10 text-left bg-white dark:bg-gray-800 rounded-lg border border-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 sm:text-sm"
                  type="text"
                  id="email"
                  name="email"
                  placeholder="unknown@xyz.com"
                  value={email === null ? '' : email}
                  onChange={onChangeInput}
                />
                {iserror.email !== '' && (
                  <div className="w-full py-2 text-xs  text-red-600">
                    <span>{iserror.email}</span>
                  </div>
                )}
              </section>

              {/* Profile Image */}
              <section>
                <label htmlFor="" className="text-sm block mb-1">
                  Upload Profile Image
                </label>
                <div className="relative w-full h-48 overflow-hidden text-left bg-white dark:bg-gray-800 rounded-lg border border-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 sm:text-sm">
                  <input
                    className="absolute top-0 left-0 bottom-0 right-0 w-full h-full opacity-0 cursor-pointer"
                    type="file"
                    name="profileFile"
                    id="profileFile"
                    onChange={onChangeImage}
                  />

                  <div className="w-full h-full">
                    {profileImage ? (
                      <img className="w-full h-full object-contain" src={profileImage} alt="profileImage" />
                    ) : (
                      <div className="w-full h-full py-4 px-4 flex flex-col items-center justify-center gap-4">
                        <div className="w-12 h-12 flex items-center justify-center rounded-full bg-gray-100 text-gray-500">
                          <ArtworkIcon />
                        </div>
                        <div className="flex flex-col items-center justify-center gap-2 text-gray-400 dark:text-white text-sm md:text-base">
                          <p>Support: (.png/.jpg/.jpeg)</p>
                          <p>Max Size: 10MB</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>

              {/* Cover Image */}
              <section>
                <label htmlFor="" className="text-sm block mb-1">
                  Upload Cover Image
                </label>
                <div className="relative w-full h-48 overflow-hidden text-left bg-white dark:bg-gray-800 rounded-lg border border-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 sm:text-sm">
                  <input
                    className="absolute top-0 left-0 bottom-0 right-0 w-full h-full opacity-0 cursor-pointer"
                    type="file"
                    name="coverFile"
                    id="coverFile"
                    onChange={onChangeImage}
                  />

                  <div className="w-full h-full">
                    {coverImage ? (
                      <img className="w-full h-full object-contain" src={coverImage} alt="profileImage" />
                    ) : (
                      <div className="w-full h-full py-4 px-4 flex flex-col items-center justify-center gap-4">
                        <div className="w-12 h-12 flex items-center justify-center rounded-full bg-gray-100 text-gray-500">
                          <ArtworkIcon />
                        </div>
                        <div className="flex flex-col items-center justify-center gap-2 text-gray-400 dark:text-white text-sm md:text-base">
                          <p>Support: (.png/.jpg/.jpeg)</p>
                          <p>Max Size: 10MB</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
              {/* User Bio */}
              <section>
                <label htmlFor="bio" className="text-sm block mb-1">
                  Brief Introduction
                </label>
                <textarea
                  className="w-full h-48 py-4 pl-4 pr-10 text-left bg-white dark:bg-gray-800 rounded-lg border border-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 sm:text-sm"
                  rows={8}
                  id="bio"
                  name="bio"
                  value={bio === null ? '' : bio}
                  placeholder="Enter the brief information"
                  onChange={onChangeTextArea}
                ></textarea>
              </section>
            </div>

            <div className="w-full flex items-center justify-center py-2 bg-white dark:bg-gray-800">
              <button
                className={`glow-on-hover bg-gradient-to-r from-blue-400 via-green-300 to-purple-500 ${
                  !canEdit && 'opacity-50'
                } text-white px-6 py-2 rounded-lg`}
                disabled={!canEdit}
                onClick={handleEditForm}
              >
                <span>{loader ? 'Editing...' : 'Edit'}</span>
              </button>
            </div>
          </div>
        </>
      </DialogModal>
    </>
  )
}

export default EditProfile
