import React, { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { SelectorIcon } from 'shared-next/Icons'
import { ChainConfig } from 'components-next/WalletModal/types'

interface ChainSelectInterface {
  list: ChainConfig[]
  selected: ChainConfig
  onSelect: (item) => void
}

const ChainSelect = ({ list, selected, onSelect }: ChainSelectInterface) => {
  return (
    <div className="">
      <Listbox value={selected} onChange={onSelect}>
        <div className="relative">
          <Listbox.Button className="relative w-full  px-2  sm:px-4  h-10 text-left glow-on-hover bg-gradient-to-r from-blue-400 via-green-300 to-purple-500 text-white cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
            {selected !== undefined ? (
              <span className="flex items-center gap-1">
                <img
                  src={selected.icon}
                  alt={selected.label}
                  className={`w-6 h-6 ${
                    selected.value === 97 || selected.value === 80001 || selected.value === 256
                      ? 'filter grayscale'
                      : ''
                  }`}
                />
                <span className="hidden lg:block truncate text-indi pr-4">{selected.label}</span>
                <span className="block lg:hidden truncate text-indi  pr-0 sm:pr-8 "></span>
              </span>
            ) : (
              <span className="flex items-center gap-2">
                <span className="hidden lg:block truncate text-indi pr-4">Unsupported chain</span>
                <span className="block lg:hidden truncate text-indi pr-0 sm:pr-8"></span>
              </span>
            )}
            <span className="hidden lg:block">
              <span className=" absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon aria-hidden="true" />
              </span>
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base  bg-white dark:bg-gray-800 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {list.map((item, itemIdx) => (
                <Listbox.Option
                  key={itemIdx}
                  className={({ active }) =>
                    `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900 dark:text-white'}
                          cursor-default select-none relative`
                  }
                  value={item}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`${
                          selected ? 'font-medium  text-purple-700 ' : 'font-normal text-gray-500 dark:text-white'
                        } ${active ? 'bg-gray-50 dark:bg-gray-600' : ''} flex items-center gap-2 py-2 px-2 sm:px-4`}
                      >
                        <img
                          src={item.icon}
                          alt={item.label}
                          className={`w-6 h-6 ${item.value === 4 ? 'filter grayscale' : ''}`}
                        />
                        <span className="hidden lg:block block truncate  ">{item.label}</span>
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

export default ChainSelect
