import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CloseIcon, ArrowLeftSM } from 'shared-next/Icons'
import { WalletIcon, ChevronUpIcon } from 'shared-next/Icons'

interface ModalType extends React.HTMLProps<HTMLDivElement> {
  isButton?: boolean
  title?: string
  label: string
  isOpen: boolean
  change?: boolean
  setChange?: React.Dispatch<React.SetStateAction<boolean>>
  handleOpen: () => void
  handleClose: () => void
}

export default function MainModal({
  isButton,
  title,
  label,
  isOpen,
  change,
  setChange,
  handleOpen,
  handleClose,
  children,
}: ModalType) {
  return (
    <>
      <div className="flex items-center justify-center ">
        <button
          type="button"
          onClick={handleOpen}
          title={title}
          className={`flex items-center px-2 sm:px-4 h-10 text-sm font-medium  ${
            isButton
              ? 'glow-on-hover bg-gradient-to-r from-blue-400 via-green-300 to-purple-500 rounded-md focus:outline-none hover:ring-2 hover:ring-offset-2 hover:ring-purple-500 focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-opacity-75 text-white'
              : ''
          }  `}
        >
          {isButton ? (
            <span>
              <WalletIcon />
            </span>
          ) : (
            <span className="transform rotate-90">
              <ChevronUpIcon />
            </span>
          )}
          {title && <span className="pl-2">{title}</span>}
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-20 overflow-y-auto bg-gray-100  bg-opacity-50"
          onClose={() => {
            return
          }}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md lg:max-w-xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-900 shadow-xl rounded-2xl">
                <div className="flex justify-between items-center border-b border-gray-300 px-4 py-3">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                    {label}
                  </Dialog.Title>
                  {change ? (
                    <button
                      className="bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-white  rounded-full p-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600"
                      type="button"
                      onClick={() => setChange(false)}
                    >
                      <ArrowLeftSM />
                    </button>
                  ) : (
                    <button
                      className="bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-white rounded-full p-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600"
                      type="button"
                      onClick={handleClose}
                    >
                      <CloseIcon />
                    </button>
                  )}
                </div>
                <div className="mt-2 px-4 py-3">{children}</div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
