import React, { useEffect } from 'react'
import Routes from 'containers/routes'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store/reducers'
import { abis, getContractAddresses } from 'contracts'
import { setContractObjects } from 'store/actions'

function App() {
  const dispatch = useDispatch()
  const web3 = useSelector((state: RootState) => state.wallet.web3)
  const selectedChainId = useSelector((state: RootState) => state.wallet.activeChainID)
  const supportedChain = useSelector((state: RootState) => state.wallet.supportedChain)

  /**
   * Function to check Supported Chain
   */
  const isChainSupported = () => {
    return supportedChain.find((chain) => chain === selectedChainId)
  }

  /**
   * Function to check Current Chain
   */
  const checkCurrentChain = async () => {
    if (!isChainSupported()) {
      try {
        const provider = await window.ethereum
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0xa86a ' }],
        })
      } catch (error) {
        console.error('Current Chain Change Error: ', error)
      }
    }
  }

  const initializeContracts = async () => {
    if (!isChainSupported()) return

    const addresses = getContractAddresses(selectedChainId)
    // const gfxTokenSC = new web3.eth.Contract(abis.gfxToken, addresses.gfxToken)
    const nftSC = new web3.eth.Contract(abis.nftToken, addresses.nftToken)
    const exchangeSC = new web3.eth.Contract(abis.exchangeNFT, addresses.exchangeNFT)
    //  const quoteSC = new web3.eth.Contract(abis.quoteToken, addresses.quoteToken)

    dispatch(
      setContractObjects({
        //  gfxTokenSC,
        nftSC,
        exchangeSC,
        //  quoteSC,
      }),
    )
  }

  useEffect(() => {
    if (!web3 || Object.keys(web3).length === 0) {
      return
    }

    checkCurrentChain()
    initializeContracts()
  }, [web3])

  return <Routes />
}
export default App
