import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DocumetSeachIcon } from 'shared-next/Icons'
import { RootState } from 'store/reducers'
import MOONRIVER from 'assets-next/images/moonriver.png'
import Arbitrum from 'assets-next/images/Arbitrum.svg'
import Bscscan from 'assets-next/images/bscScan.jpg'
import Evmos from 'assets-next/images/EVMOS.png'
import Snowtrace from 'assets-next/images/snowtrace.svg'
import Default_Profile_Pic from 'assets-next/images/questionmark.png'
import useScreen from 'hooks/useScreen'
import { CHAIN_INFO } from 'utils/chains'

import { NftType, BidTransaction, TradeTransaction } from 'types/index'
import {
  getBidTransactions,
  getTradeTransactions,
  getTokenNameFromAddress,
  getSingleNFT,
  getAllNFTCollections,
} from 'utils'
import {
  getShortenAddress,
  getOwnerShortenAddress,
  getDateinto24Hr,
  addDecimalInPrice,
  removeDecimalFromPrice,
  removeDecimalFromPriceUsingSymbol,
} from 'utils/index'
import { setActiveNft, setAllNfts, setMyNfts, setQuoteTokens, setCollection } from 'store/actions'
import { getNftsWithDetail } from 'components-next/Marketplace/constants'
import useFetchNfts from 'hooks/useFetchNfts'
import useFetchProfile from 'hooks/useProfile'
import Persona from 'assets-next/images/persona.jpg'
import { Vote, NotFound } from 'shared-next'
import { abis } from 'contracts'
import { PriceModal, Recommendation } from './components'
import { ClipLoader } from 'react-spinners'
import swal from 'sweetalert'
import { getKeyValue } from 'components-next/Marketplace/utils'
import { useHistory } from 'react-router'

interface FormattedBidTransaction {
  id: number
  price?: string
  buyerAddress: string
  quoteToken?: string
}

interface FormattedTradeTransaction {
  id: number
  price?: string
  buyerAddress: string
  sellerAddress: string
  tradeDate: string
  type: string
  quoteToken: string
}

const delay = 20
const NftDetail = (props: any) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isMobile } = useScreen()
  const contracts = useSelector((state: RootState) => state.wallet.contracts)
  const activeChainID = useSelector((state: RootState) => state.wallet.activeChainID)
  const { exchangeSC: exchangeNftSC } = contracts

  const { contract_id: collectionAddress, token_id: tokenId } = props.match.params
  const comeFrom = props.location.state?.detail ? props.location.state.detail === 'my-profile' : false
  const [nftTokenSC, setNftTokenSC] = useState(undefined)
  const [quoteTokenSC, setquoteTokenSC] = useState(undefined)

  // loading status
  const [loading, setLoading] = useState(true)
  const [notFound, setNotfound] = useState(false)
  const [asking, setAsking] = useState<boolean>(false)
  const [increasing, setIncreasing] = useState<boolean>(false)
  const [cancellingSell, setCancellingSell] = useState<boolean>(false)
  const [selling, setSelling] = useState<boolean>(false)
  const [bidding, setBidding] = useState<boolean>(false)
  const [isMyNft, setIsMyNft] = useState<boolean>(comeFrom ? true : false)
  const [canBid, setCanBid] = useState<boolean>(false)
  const [nft, setNFT] = useState<any>({})
  const [collectionDetails, setCollectionDetails] = useState<any>({})
  const [isCollectionDetailsAvailable, setIsCollectionDetailsAvailabe] = useState(false)

  const [canVote, setCanVote] = useState<boolean>(true)

  // bid transactions
  const [bidTransactions, setBidTransactions] = useState([])
  const [selectedBidId, setSelectedBidId] = useState<number>(0)

  // trade transactions
  const [tradeTransactions, setTradeTransactions] = useState([])

  // Custom NFT fetch hook
  const { web3, address, allNfts, myNfts, activeNft, quoteTokens, fetchAllNftAssets, fetchMyNftAssets, cleanupNfts } =
    useFetchNfts()
  const { profileLoader, isProfileDetailsAvailable, userDetails, fetchUserProfileDetails } = useFetchProfile()

  // fetch nft quote tokens
  const fetchQuoteTokens = async () => {
    if (!address) return
    if (!exchangeNftSC) return
    if (exchangeNftSC && exchangeNftSC._address === null) return
    const _quoteTokens = await exchangeNftSC.methods.getQuoteTokens().call()
    dispatch(setQuoteTokens(_quoteTokens))
  }

  // category
  const [updateCategory, setUpdateCategory] = useState('')
  const onUpdateCategory = (value) => {
    setUpdateCategory(value)
  }

  // price
  const [updatePrice, setUpdatePrice] = useState({ value: '', label: '' })
  const onUpdatePrice = (value) => {
    setUpdatePrice(value)
  }

  // Voting System
  const getCanVote = async () => {
    if (!address) return
    if (!exchangeNftSC) return
    if (exchangeNftSC && exchangeNftSC._address === null) return
    if (!nft.collectionAddress || !nft.tokenId) return

    try {
      const _canVote = await exchangeNftSC.methods.getUserCanVote(address, nft.collectionAddress, nft.tokenId).call()
      setCanVote(_canVote)
    } catch (err) {
      console.error('Vote Error--->', err)
    }
  }

  const onVote = async (e) => {
    e.stopPropagation()
    if (!canVote) {
      return
    }
    const result = await exchangeNftSC.methods.voteToken(nft.collectionAddress, nft.tokenId).send({ from: address })
    if (result) {
      dispatch(setActiveNft({ ...nft, voteCnt: nft.voteCnt + 1 }))

      /*const _allNfts = allNfts.map((row) => {
        return {
          ...row,
          voteCnt:
            nft.collectionAddress === row.collectionAddress && nft.tokenId === row.tokenId
              ? Number(row.voteCnt) + 1
              : row.voteCnt,
        }
      })
      dispatch(setAllNfts(_allNfts))*/
    }
  }

  const fetchAllNftCollections = async () => {
    if (nft && Object.keys(nft).length === 0) return
    if (nft.collectionAddress === '') return

    try {
      const _nftCollections = await getAllNFTCollections()
      const activeCollection = _nftCollections.find(
        (item) => item.address.toLowerCase() === nft.collectionAddress.toLowerCase(),
      )
      if (activeCollection && Object.keys(activeCollection).length > 0) {
        setCollectionDetails(activeCollection)
        setIsCollectionDetailsAvailabe(true)
      }
    } catch (err) {
      setIsCollectionDetailsAvailabe(false)
    }
  }

  const handleContract = () => {
    const nftTokenSC = new web3.eth.Contract(abis.nftToken, collectionAddress)
    const quoteTokenSC = new web3.eth.Contract(abis.quoteToken, nft?.quoteToken)

    setNftTokenSC(nftTokenSC)
    setquoteTokenSC(quoteTokenSC)
  }

  useEffect(() => {
    if (!web3 || Object.keys(web3).length === 0) {
      return
    }
    handleContract()
  }, [web3, nft])

  useEffect(() => {
    if (!address || address === '') return

    /*console.log('DEBUG-FETCHing')
    fetchAllNftAssets()
    fetchMyNftAssets()*/
    dispatch(setCollection({ label: 'Collection', value: 'all' }))
    /*return () => {
      cleanupNfts()
    }*/
  }, [address])

  useEffect(() => {
    fetchQuoteTokens()
    getCanVote()
  }, [address, exchangeNftSC])

  useEffect(() => {
    if (!notFound) getCanVote()
  }, [nft])

  // Get NFT
  const fetchNft = async () => {
    setLoading(true)
    const getNft = await getSingleNFT({ collectionAddress, tokenId })
    const active = await getNftsWithDetail(getNft, web3, address, exchangeNftSC)

    if (address && active.length === 0 && Object.keys(activeNft).length > 0) {
      setNFT(activeNft)
    } else if (active.length > 0) {
      setNFT(active[0])
    }
    if (active[0] === undefined && Object.keys(activeNft).length === 0) {
      setNotfound(true)
    }

    setLoading(false)
  }

  useEffect(() => {
    // if (!address || address === '') return
    fetchNft()
  }, [])

  useEffect(() => {
    if (nft && Object.keys(nft).length === 0) return
    fetchAllNftCollections()
  }, [nft])

  const setOwner = async () => {
    if (!address) return
    if (!nftTokenSC) return
    if (nftTokenSC && nftTokenSC._address === null) return
    if (nft && Object.keys(nft).length === 0) return
    try {
      const nftOwner = await nftTokenSC.methods.ownerOf(nft.tokenId).call()
      setIsMyNft(nftOwner.toLowerCase() === address.toLowerCase())
    } catch (error) {
      console.error('123--->check owner error', error)
    }
  }

  const fetchBidding = async () => {
    if (!address) return
    if (!exchangeNftSC) return
    if (exchangeNftSC && exchangeNftSC._address === null) return
    if (nft && Object.keys(nft).length === 0) return
    if (nft.price === 0) {
      setBidTransactions([])
      return
    }
    try {
      const auctionId = await exchangeNftSC.methods.getAuctionId(collectionAddress, nft.tokenId).call()

      const tokenId = `${collectionAddress}-${nft.tokenId}`

      let _bidTransactions = await getBidTransactions(tokenId, auctionId)
      _bidTransactions = _bidTransactions.map((row: BidTransaction, index: number) => {
        return {
          id: index,
          price: Number(row.price),
          quoteToken: row.quoteToken,
          buyerAddress: row.buyer ? row.buyer.address : '',
        }
      })

      setBidTransactions(_bidTransactions)
      setSelectedBidId(0)
    } catch (error) {
      console.error('123--->fetch bidding error', error)
    }
  }

  const fetchTrading = async () => {
    if (nft && Object.keys(nft).length === 0) return
    // const tokenId = `${collectionAddress}-${nft.tokenId}`
    //  let _tradeTransactions = await getTradeTransactions(nft.collectionAddress + '-' + nft.tokenId)
    let _tradeTransactions = await getTradeTransactions(nft.tokenId, nft.collectionAddress)
    _tradeTransactions = _tradeTransactions.map((row: TradeTransaction, index: number) => {
      return {
        id: index,
        price: row.price,
        buyerAddress: row.buyer ? row.buyer.address : '',
        sellerAddress: row.seller ? row.seller.address : '',
        tradeDate: row.tradeDate,
        type: row.type,
        quoteToken: getTokenNameFromAddress(row.quoteToken),
      }
    })
    setTradeTransactions(_tradeTransactions)
  }

  useEffect(() => {
    if (nft && Object.keys(nft).length > 0 && address && exchangeNftSC && exchangeNftSC._address !== null) {
      fetchBidding()
    }
  }, [nft, address, web3, exchangeNftSC])

  useEffect(() => {
    if (nft && Object.keys(nft).length > 0) {
      fetchTrading()
    }
  }, [nft])

  useEffect(() => {
    if (nft && Object.keys(nft).length > 0 && address && nftTokenSC && nftTokenSC._address !== null) {
      setOwner()
    }
  }, [nft, address, web3, nftTokenSC])

  useEffect(() => {
    const _canBid = bidTransactions.filter((row: FormattedBidTransaction) => row.buyerAddress === address).length === 0
    if (canBid !== _canBid) {
      setCanBid(_canBid)
    }
  }, [bidTransactions])

  useEffect(() => {
    if (nft && Object.keys(nft).length > 0) {
      fetchUserProfileDetails(nft.ownerAddress)
    }
  }, [nft])

  const onCancelToSell = async () => {
    if (!web3) return
    if (asking || increasing || cancellingSell || selling || nft.price === 0) {
      swal({
        title: 'Error',
        text: 'Something went wrong, Try again',
        icon: 'error',
      })
      return
    }
    setCancellingSell(true)
    try {
      await exchangeNftSC.methods
        .cancelSellToken(collectionAddress, nft.tokenId, nft.quoteToken)
        .send({ from: address })
      dispatch(setActiveNft({ ...nft, price: 0 }))
      onUpdatePrice({ value: '', label: '' })
      setTimeout(async () => {
        setCancellingSell(false)
        swal({
          title: 'Done',
          text: 'Removed from Market',
          icon: 'success',
        })
        await fetchNft()
      }, delay * 1000)
    } catch (error) {
      setCancellingSell(false)
      swal({
        title: 'Error',
        text: 'Something went wrong, Try again',
        icon: 'error',
      })
    }
  }

  const onSell = async () => {
    if (!web3) return
    if (asking || increasing || cancellingSell || selling || nft.price === 0) {
      swal({
        title: 'Error',
        text: 'Something went wrong, Try again',
        icon: 'error',
      })
      return
    }

    const selectedBid: FormattedBidTransaction | undefined = bidTransactions.find(
      (row: FormattedBidTransaction) => row.id === selectedBidId,
    )
    if (!selectedBid) {
      swal({
        title: 'Error',
        text: 'Something went wrong, Try again',
        icon: 'error',
      })

      return
    }
    const { buyerAddress } = selectedBid

    setSelling(true)
    try {
      // approve
      await nftTokenSC.methods.approve(exchangeNftSC._address, nft.tokenId).send({ from: address })
      await exchangeNftSC.methods
        .sellToken(collectionAddress, nft.tokenId, buyerAddress, nft.quoteToken)
        .send({ from: address })
      await fetchMyNftAssets()

      dispatch(setMyNfts(myNfts.filter((row: NftType) => row.tokenId !== nft.tokenId)))
      dispatch(setActiveNft({ ...nft, ownerAddress: buyerAddress }))
      setTimeout(async () => {
        setSelling(false)
        swal({
          title: 'Done',
          text: 'Sold Nft',
          icon: 'success',
        })
        await fetchNft()
      }, delay * 1000)
    } catch (error) {
      setSelling(false)
      swal({
        title: 'Error',
        text: 'Something went wrong, Try again',
        icon: 'error',
      })
    }
  }

  // confirm modal actions
  const onConfirmAsk = async (quoteToken: string, price: string, category: string) => {
    setAsking(true)
    //const priceInWei = web3.utils.toWei(price.toString(), 'ether')
    const priceInWei = addDecimalInPrice(quoteToken, Number(price))

    try {
      await exchangeNftSC.methods
        .readyToSellToken(collectionAddress, nft.tokenId, quoteToken, priceInWei.toString(), category)
        .send({ from: address })
      dispatch(setActiveNft({ ...nft, quoteToken, price: Number(price) }))
      onUpdateCategory(category)
      onUpdatePrice({ value: `${price}`, label: getTokenNameFromAddress(quoteToken) })

      setTimeout(async () => {
        setAsking(false)
        swal({
          title: 'Done',
          text: 'Added price successfully',
          icon: 'success',
        })
        await fetchNft()
      }, delay * 1000)
    } catch (error) {
      setAsking(false)
      swal({
        title: 'Error',
        text: 'Something went wrong, Try again',
        icon: 'error',
      })
      return { error: error }
    }
  }

  const onConfirmIncrease = async (quoteToken: string, price: string) => {
    setIncreasing(true)
    // const priceInWei = web3.utils.toWei(price.toString(), 'ether')
    const priceInWei = addDecimalInPrice(quoteToken, Number(price))
    try {
      await exchangeNftSC.methods
        .setCurrentPrice(collectionAddress, nft.tokenId, quoteToken, priceInWei.toString())
        .send({ from: address })
      dispatch(setActiveNft({ ...nft, price: Number(price) }))
      onUpdatePrice({ value: `${price}`, label: getTokenNameFromAddress(quoteToken) })

      setTimeout(async () => {
        setIncreasing(false)
        swal({
          title: 'Done',
          text: 'Increase price successfully',
          icon: 'success',
        })
        await fetchNft()
      }, delay * 1000)
    } catch (error) {
      setIncreasing(false)
      swal({
        title: 'Error',
        text: 'Something went wrong, Try again',
        icon: 'error',
      })
      return { error: error }
    }
  }

  const checkIsEnoughBalance = async (quoteToken, address, bidPrice) => {
    const quoteSC = new web3.eth.Contract(abis.quoteToken, quoteToken)
    const balance = await quoteSC.methods.balanceOf(address).call()
    const Balance = removeDecimalFromPrice(quoteToken, Number(balance))
    if (Balance > bidPrice) return true
    else false
  }

  const onConfirmBid = async (quoteToken: string, price: string) => {
    setBidding(true)
    try {
      // approve
      const isEnough = await checkIsEnoughBalance(quoteToken, address, Number(price))
      if (!isEnough) {
        setBidding(false)
        swal({
          title: 'Error',
          text: "You don't have enough Balance",
          icon: 'error',
        })
        return
      }
      const priceInWei = addDecimalInPrice(quoteToken, Number(price))
      await quoteTokenSC.methods.approve(exchangeNftSC._address, priceInWei.toString()).send({ from: address })
      await exchangeNftSC.methods
        .bidToBuyToken(collectionAddress, nft.tokenId, quoteToken, priceInWei.toString())
        .send({ from: address })
      setTimeout(async () => {
        setBidding(false)

        swal({
          title: 'Done',
          text: 'Set your Bid',
          icon: 'success',
        })
        setCanBid(false)
        await fetchBidding()
      }, delay * 1000)
    } catch (error) {
      console.log('onConfirmBid Error', error)
      setBidding(false)
      swal({
        title: 'Error',
        text: 'Something went wrong, Try again',
        icon: 'error',
      })
      await fetchBidding()
      return { error: error }
    }
  }

  const onSelectBid = (bidId: number) => {
    if (bidId !== selectedBidId) {
      setSelectedBidId(bidId)
    }
  }

  const getAssetLink = () => {
    if (nft && Object.keys(nft).length > 0 && activeChainID) {
      const AssetLink = CHAIN_INFO.find((item) => item.chainId === activeChainID)
      if (AssetLink) {
        return (
          <div>
            <a
              className="no-underline text-center flex flex-row gap-x-1 justify-center hover:text-pink-500 text-purple-500"
              target="_blank"
              rel="noopener noreferrer"
              href={AssetLink.explorer + 'token/' + nft.collectionAddress + '?a=' + nft.tokenId}
            >
              <img src={AssetLink.explorerlogoUrl} className="w-8 h-8" />
              <span className="whitespace-nowrap text-center text-center pt-1">{`View ${AssetLink.explorerName}`}</span>
            </a>
          </div>
        )
      } else return
    } else return
  }

  const getMetaDataLink = () => {
    if (nft && Object.keys(nft).length > 0) {
      return (
        <div>
          <a
            className="no-underline text-center flex flex-row gap-x-2 justify-center hover:text-pink-500 text-purple-500"
            target="_blank"
            rel="noopener noreferrer"
            href={nft.image}
          >
            <DocumetSeachIcon />
            <span className="whitespace-nowrap pt-1">View Metadata</span>
          </a>
        </div>
      )
    } else {
      return
    }
  }

  const SellButtonContainer = () => {
    const commonDisableValid = asking || increasing || cancellingSell || selling

    return (
      <div className="grid grid-cols-2 gap-4">
        {!loading && nft && Object.keys(nft).length > 0 && (
          <PriceModal
            type="ask"
            title={asking ? 'Asking to sell ...' : 'Place sell order'}
            label="Set price to Ask"
            disabled={commonDisableValid || Number(nft.price) > 0}
            minPrice={0}
            onConfirm={onConfirmAsk}
            nft={nft}
            quoteTokens={quoteTokens}
            chainId={activeChainID}
          />
        )}

        {!loading && nft && Object.keys(nft).length > 0 && (
          <PriceModal
            type="increase"
            title={increasing ? 'Increasing price ...' : 'Increase price'}
            label="Set price to Increase"
            disabled={commonDisableValid || !nft.price || Number(nft.price) === 0}
            minPrice={nft.price}
            onConfirm={onConfirmIncrease}
            nft={nft}
            quoteTokens={quoteTokens}
            chainId={activeChainID}
          />
        )}

        <div className="flex items-center justify-center">
          <button
            type="button"
            className={`${
              (commonDisableValid || !nft.price) && 'opacity-50'
            } w-full flex items-center justify-center gap-x-1 px-4 py-3 text-sm font-medium text-white glow-on-hover bg-gradient-to-r from-blue-400 via-green-300 to-purple-500 rounded-md focus:outline-none hover:ring-2 hover:ring-offset-2 hover:ring-purple-500 focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}
            disabled={commonDisableValid || !nft.price || nft.price === 0}
            onClick={onCancelToSell}
          >
            <span>{cancellingSell ? 'Cancelling Sell ...' : 'Cancel Sell'}</span>
          </button>
        </div>

        <div className="flex items-center justify-center">
          <button
            type="button"
            className={`${
              (commonDisableValid || !nft.price || bidTransactions.length === 0) && 'opacity-50'
            } w-full flex items-center justify-center gap-x-1 px-4 py-3 text-sm font-medium text-white glow-on-hover bg-gradient-to-r from-blue-400 via-green-300 to-purple-500 rounded-md focus:outline-none hover:ring-2 hover:ring-offset-2 hover:ring-purple-500 focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}
            disabled={commonDisableValid || !nft.price || bidTransactions.length === 0}
            onClick={onSell}
          >
            <span>{selling ? 'Selling Token...' : 'Sell Token'}</span>
          </button>
        </div>
      </div>
    )
  }

  const BuyButtonContainer = () => {
    const disable = bidding || nft.price === 0 || !nft.price || !canBid

    return (
      <>
        {!loading && nft && Object.keys(nft).length > 0 && (
          <PriceModal
            type="bid"
            title={bidding ? 'Bidding to buy ...' : 'Place buy order'}
            label="Set price to Bid"
            disabled={disable}
            minPrice={nft.price}
            onConfirm={onConfirmBid}
            nft={nft}
            quoteTokens={quoteTokens}
            chainId={activeChainID}
          />
        )}
      </>
    )
  }

  const handleRedirectToUserProfile = () => {
    if (nft && Object.keys(nft).length > 0) {
      history.push(`/profile/${nft.ownerAddress}`)
    }
    return
  }

  const handleCollectionClick = () => {
    if (nft && Object.keys(nft).length > 0) {
      history.push(`/collection/${nft.collectionAddress}`)
    }
    return
  }

  return (
    <>
      {notFound ? (
        comeFrom ? (
          <NotFound
            title=""
            description="Reach the end of the grid... let's head back"
            goTo="my-profile"
            buttonLabel="Back to My Profile"
          >
            <div className="font-playfair w-full text-center md:w-1/2 text-3xl md:text-6xl font-semibold dark:text-white">
              <p>{`The item you're looking for is not listed here`}</p>
            </div>
          </NotFound>
        ) : (
          <NotFound
            title="Seems like the item you're looking for is not here!"
            description="Reach the end of the grid... let's head back"
            goTo="marketplace"
            buttonLabel="Back to Marketplace"
          />
        )
      ) : (
        <>
          {loading || profileLoader ? (
            <div className=" p-5 max-w-screen-2xl min-h-screen flex items-center justify-center dark:text-white">
              <ClipLoader color={'dark:text-white'} />
            </div>
          ) : (
            <>
              {(!loading || !profileLoader) && nft && Object.keys(nft).length > 0 && (
                <>
                  <div
                    className={`relative px-3 py-5 md:px-5 lg:px-8 max-w-screen-2xl m-auto min-h-screen1 ${
                      asking || increasing || cancellingSell || selling || bidding ? 'relative' : ''
                    }`}
                  >
                    {(asking || increasing || cancellingSell || selling || bidding) && (
                      <div className="absolute h-full w-full top-0 left-0  bg-black bg-opacity-40 flex flex-col items-center pt-24 justify-top z-30 gap-4 backdrop-filter backdrop-grayscale">
                        <ClipLoader color="#fff" />
                        <span className="text-white mt-4">Note: Please do not refresh the page</span>
                      </div>
                    )}
                    <div className="flex flex-col gap-8 md:gap-4 md:flex-row h-full">
                      <section className="md:flex-1">
                        <div className="h-96 md:h-xl flex justify-center">
                          {nft.artworkType === 'video' || nft.artWorkType === 'video' ? (
                            <video
                              controls
                              className="w-full md:w-2/3 h-full object-contain shadow-xl  dark:bg-gray-900 rounded-xl overflow-hidden"
                            >
                              <source className="w-full h-full object-contain" src={nft.image} type="video/mp4" />
                            </video>
                          ) : (
                            <picture className="w-full md:w-2/3 h-full object-contain shadow-xl  dark:bg-gray-900  rounded-xl overflow-hidden">
                              <source
                                className="w-full h-full object-contain"
                                srcSet={nft.image}
                                media="(min-width: 800px)"
                              />
                              <img src={nft.image} alt={nft.artworkName} className="w-full h-full object-contain" />
                            </picture>
                          )}
                        </div>
                        {!isMobile && (
                          <div className="mt-4  flex flex-row justify-center gap-8 ">
                            <div>{getAssetLink()}</div>
                            {getMetaDataLink()}
                          </div>
                        )}
                      </section>

                      <section className="md:flex-1 flex flex-col gap-y-6 md:gap-y-10">
                        <div className="flex gap-5 items-center justify-between">
                          <section className="flex gap-5 items-center">
                            <div className="w-8 md:w-12 h-8 md:h-12 rounded-full bg-purple-200 ring-2 ring-offset-2 ring-purple-500 overflow-hidden">
                              <img
                                onClick={handleRedirectToUserProfile}
                                src={
                                  isProfileDetailsAvailable && userDetails.profileImage !== null
                                    ? userDetails.profileImage
                                    : Persona
                                }
                                alt="persona"
                                className="w-full h-full object-fit cursor-pointer"
                              />
                            </div>
                            <div className="dark:text-white">
                              <p className="text-sm italic text-gray-400">Owned by:</p>
                              <p
                                onClick={handleRedirectToUserProfile}
                                className="text-lg font-medium cursor-pointer hover:text-pink-400 "
                              >
                                {isProfileDetailsAvailable && userDetails.name !== ''
                                  ? userDetails.name
                                  : getOwnerShortenAddress(nft.ownerAddress)}
                              </p>
                            </div>
                          </section>

                          <section className="dark:text-white">
                            {nft.voteCnt && (
                              <Vote canVote={canVote} count={Number(nft.voteCnt)} onClick={(e) => onVote(e)} />
                            )}
                          </section>
                        </div>
                        <div>
                          <h1 className="text-3xl md:text-6xl font-playfair font-medium dark:text-white">
                            {nft.artworkName}
                          </h1>
                          {isCollectionDetailsAvailable && (
                            <section className="flex gap-2 p-2 items-center dark:text-white">
                              <div className="w-8 h-8 rounded-full  bg-purple-200 ring-1 ring-offset-1 ring-purple-500 overflow-hidden">
                                <img
                                  onClick={handleCollectionClick}
                                  src={
                                    collectionDetails?.contractUri !== ''
                                      ? collectionDetails?.contractUri
                                      : Default_Profile_Pic
                                  }
                                  alt="collection_image"
                                  className="w-full h-full object-fit p-1 cursor-pointer"
                                />
                              </div>
                              <div>
                                <p
                                  onClick={handleCollectionClick}
                                  className=" py-2 text-sm cursor-pointer hover:text-pink-400"
                                >
                                  {collectionDetails?.name !== ''
                                    ? collectionDetails.name
                                    : getOwnerShortenAddress(collectionDetails.address)}
                                </p>
                              </div>
                            </section>
                          )}
                          <p className="w-2/3 mt-4 dark:text-gray-500">{nft.bio}</p>
                          <div className="w-2/3 mt-4 text-sm flex items-center gap-x-2">
                            <p className="text-gray-500 font-medium">Artwork Type:</p>

                            <p className="inline-block px-2 py-1 rounded-lg bg-yellow-100 text-yellow-700 capitalize">
                              {nft.artWorkType !== null ? nft.artWorkType : 'NA'}
                            </p>
                          </div>
                          <div className="w-2/3 mt-4 text-sm flex items-center gap-x-2">
                            <p className="text-gray-500 font-medium">Category:</p>
                            {isMyNft ? (
                              <p className="inline-block px-2 py-1 rounded-lg bg-yellow-100 text-yellow-700 capitalize">
                                {nft.category ? nft.category : updateCategory !== '' ? updateCategory : 'Not Selected'}
                              </p>
                            ) : (
                              <p className="inline-block px-2 py-1 rounded-lg bg-yellow-100 text-yellow-700 capitalize">
                                {nft.category !== null ? nft.category : 'NA'}
                              </p>
                            )}
                          </div>
                          <p className="mt-4 flex items-center gap-x-4">
                            <span className="text-sm font-medium text-gray-500">Price:</span>
                            <span className="text-xl md:text-3xl py-1 px-2 font-semibold border-b-4 border-double border-gray-300 text-gray-900 dark:text-white">
                              {nft.price && nft.quoteToken ? (
                                `${nft.price} ${getTokenNameFromAddress(nft.quoteToken)}`
                              ) : updatePrice.value !== '' && updatePrice.label !== '' ? (
                                <>
                                  {updatePrice.value} {updatePrice.label}
                                </>
                              ) : (
                                <>NA</>
                              )}
                            </span>
                          </p>
                          <div className="mt-4 flex items-center">
                            {address && (isMyNft ? <SellButtonContainer /> : <BuyButtonContainer />)}
                            {!address && (
                              <div>
                                <button
                                  type="button"
                                  disabled={true}
                                  className=" bg-gradient-to-r from-blue-700  to-purple-700 text-white px-4 py-2 rounded-lg"
                                  onClick={null}
                                >
                                  Place buy order
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <details className="bg-white dark:bg-gray-900 px-4 py-4 border shadow-sm rounded-lg cursor-pointer dark:text-white">
                          <div className="pt-4">
                            <div className="grid grid-cols-2 border-b border-gray-300 py-2">
                              <p className="font-medium text-gray-500">NFT Contract ID</p>
                              <p onClick={handleCollectionClick} className="hover:text-purple-500">
                                {getShortenAddress(nft.collectionAddress)}
                              </p>
                            </div>
                            <div className="grid grid-cols-2 border-b border-gray-300 py-2">
                              <p className="font-medium text-gray-500">Token ID</p>
                              <p>{nft.tokenId}</p>
                            </div>
                            <div className="grid grid-cols-2 border-b border-gray-300 py-2">
                              <p className="font-medium text-gray-500">{`Creator's Address`}</p>
                              <p>{getShortenAddress(getKeyValue(nft, 'creatorAddress')) || 'NA'}</p>
                            </div>
                            <div className="grid grid-cols-2 py-2">
                              <p className="font-medium text-gray-500">{`Owner's Address`}</p>
                              <p>{getShortenAddress(nft.ownerAddress) || 'NA'}</p>
                            </div>
                            <div className="grid grid-cols-2 py-2">
                              <p className="ont-medium text-gray-500">Created by:</p>
                              <p>{getKeyValue(nft, 'artistName')}</p>
                            </div>
                          </div>
                        </details>
                      </section>
                    </div>
                    {isMobile && (
                      <div className="mt-4 px-1  py-2 flex flex-row justify-center gap-8 ">
                        <div>{getAssetLink()}</div>
                        {getMetaDataLink()}
                      </div>
                    )}
                    <div className="mt-8 dark:text-white">
                      <section className="flex flex-col md:flex-row gap-4">
                        {/* Auction Table */}
                        <article className="md:flex-1">
                          <h5 className="text-lg font-medium mb-4">Auction</h5>
                          <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto">
                              <div className="py-2 align-middle inline-block min-w-full">
                                <div
                                  className="relative border border-gray-300 border-b border-gray-200 sm:rounded-lg overflow-y-auto"
                                  style={{ maxHeight: 'calc((((1.5rem * 2) + 1.25rem) * 5 ) - 0.5rem)' }}
                                >
                                  <table className="relative min-w-full divide-y divide-gray-200 h-full overflow-y-auto">
                                    <thead className="bg-gray-50 dark:bg-gray-700 sticky top-0">
                                      <tr>
                                        <th
                                          scope="col"
                                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider"
                                        >
                                          Address
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider"
                                        >
                                          Price
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200">
                                      {bidTransactions.length > 0 ? (
                                        bidTransactions.map((bidTransaction: FormattedBidTransaction) => (
                                          <tr key={bidTransaction.id}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-white   flex items-center gap-2">
                                              {isMyNft && (
                                                <span>
                                                  <input
                                                    type="checkbox"
                                                    name=""
                                                    id={`${bidTransaction.id}`}
                                                    className="rounded-md cursor-pointer"
                                                    checked={selectedBidId === bidTransaction.id}
                                                    onChange={() => onSelectBid(bidTransaction.id)}
                                                  />
                                                </span>
                                              )}
                                              <span>{getShortenAddress(bidTransaction.buyerAddress)}</span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-white">
                                              {removeDecimalFromPrice(
                                                bidTransaction.quoteToken,
                                                Number(bidTransaction.price),
                                              )}
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr className="bg-white dark:bg-gray-900  divide-y divide-gray-200 flex items-center justify-center px-6 py-4">
                                          <td>No one is currently bidding here</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </article>

                        {/* Marketplace History Table */}
                        <article className="md:flex-1">
                          <h5 className="text-lg font-medium mb-4">Marketplace History</h5>
                          <div className="flex flex-col">
                            <div className="-my-2  ">
                              <div className="py-2 align-middle inline-block  overflow-x-auto w-full ">
                                <div
                                  className="border border-gray-300 overflow-auto border-b border-gray-200 sm:rounded-lg  min-w-full"
                                  style={{ maxHeight: 'calc((((1.5rem * 2) + 1.25rem) * 5 ) - 0.5rem)' }}
                                >
                                  <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50 dark:bg-gray-700">
                                      <tr>
                                        <th
                                          scope="col"
                                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider"
                                        >
                                          Action
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider"
                                        >
                                          Price
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider"
                                        >
                                          From
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-white uppercase tracking-wider"
                                        >
                                          To
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-6 py-3 text-left text-xs font-medium text-gray-500  dark:text-white uppercase tracking-wider"
                                        >
                                          Date
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200">
                                      {tradeTransactions.length > 0 ? (
                                        tradeTransactions.map((row: FormattedTradeTransaction) => (
                                          <tr key={row.id}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-white">
                                              {row.type}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-white">
                                              <span>
                                                {removeDecimalFromPriceUsingSymbol(row.quoteToken, Number(row.price))}
                                              </span>
                                              <span className="ml-1">{row.quoteToken}</span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-white">
                                              {getShortenAddress(row.sellerAddress)}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-white">
                                              {getShortenAddress(row.buyerAddress)}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-white">
                                              {getDateinto24Hr(row.tradeDate)}
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr className="bg-white dark:bg-gray-800 dark:text-white divide-y divide-gray-200 flex items-center justify-center px-6 py-4">
                                          <td>No data present</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </article>
                      </section>
                    </div>
                    <Recommendation
                      contract_id={collectionAddress}
                      token_id={tokenId}
                      notFound={notFound}
                      NFTloading={loading}
                    />
                  </div>
                </>
              )}{' '}
            </>
          )}
        </>
      )}
    </>
  )
}

export default React.memo(NftDetail)
