import gql from 'graphql-tag'
import { addDecimalInPrice } from 'utils'

export const NFT_ASSETS_SEARCH = ({
  account,
  collectionAddress,
  quoteToken,
  pageNumber = 0,
  perPage,
  sortOption,
  category,
}) => {
  const queryString = `
    query transactions {
      tokenAssets(
        where: { 
          ${account ? `owner: "${account}"` : ``} 
          ${collectionAddress ? `tokenCollection: "${collectionAddress}"` : ``} 
          ${quoteToken ? `quoteToken: "${quoteToken}"` : ``}
          ${category ? `category: "${category}"` : ``}
        }   
        ${sortOption}           
        first: ${perPage}
        skip: ${pageNumber * perPage}
      )
      {
        id
        tokenId
        tokenUri
        tokenCollection {
          address
        }
        creator {
          address
        }
        owner {
          address
        }
        price
        quoteToken
        voteCnt
        artworkType
        artworkName
        artistName
        description
        category
      }
    }`
  return gql(queryString)
}

//New
export const NFT_ASSETS_SELL = ({ account, quoteToken, artwork, pageNumber = 0, perPage, sortOption }) => {
  const queryString = `
    query transactions {
      tokenAssets(
        where: { 
          ${account ? `owner: "${account}"` : ``} 
          ${quoteToken ? `quoteToken: "${quoteToken}"` : ``}
          ${artwork ? `artworkType: "${artwork}"` : ``}
          price_gt: 0
         
        }   
        ${sortOption}           
        first: ${perPage}
        skip: ${pageNumber * perPage}
      )
      {
        id
        tokenId
        tokenUri
        tokenCollection {
          address
        }
        creator {
          address
        }
        owner {
          address
        }
        price
        quoteToken
        voteCnt
        artworkType
        artworkName
        artistName
        description
        category
      }
    }`
  return gql(queryString)
}

export const NFT_ASSETS_SEARCH_ALL = ({
  collectionAddress,
  quoteToken,
  pageNumber = 0,
  perPage,
  sortOption,
  priceGt,
  priceLt,
  category,
  artWork,
}) => {
  if (+priceGt > 999) priceGt = 999
  if (priceLt === 0 || +priceLt > 999) priceLt = 999
  priceGt = addDecimalInPrice(quoteToken, Number(priceGt))

  // priceLt = priceLt * 1e18
  //${priceLt ? `price_lt: "${priceLt}"` : `price_lt: "99999999999999999999999"`}

  const queryString = `
    query transactions {
      tokenAssets(
        where: { 
          ${collectionAddress ? `tokenCollection: "${collectionAddress}"` : ``}
          ${quoteToken ? `quoteToken: "${quoteToken}"` : ``}
          ${category ? `category: "${category}"` : ``}
          ${priceGt ? `price_gt: "${priceGt}"` : `price_gt: "0"`}
          ${artWork ? `artworkType: "${artWork}"` : ``}
          
        }
        ${sortOption}
        first: ${perPage}
        skip: ${pageNumber * perPage}
      )
      {
        id
        tokenId
        tokenUri
        tokenCollection {
          address
        }
        creator {
          address
        }
        owner {
          address
        }
        price
        quoteToken
        voteCnt
        artworkType
        artworkName
        artistName
        description
        category
      }
    }`

  return gql(queryString)
}

export const NFT_COLLECTIONS_SEARCH_ALL = () => {
  const queryString = `
    query transactions {
      tokenCollections(
        where: {}
      )
      {
        id
        address
        name
        contractUri
      }
    }`
  return gql(queryString)
}

export const NFT_SINGLE_ASSETS_SEARCH = ({ collectionAddress, tokenId }) => {
  const queryString = `
    query transactions {
      tokenAssets(
        where: { 
          ${tokenId ? `tokenId: "${tokenId}"` : ``} 
          ${collectionAddress ? `tokenCollection: "${collectionAddress}"` : ``} 
        }              
      )
      {
        id
        tokenId
        tokenUri
        tokenCollection {
          address
        }
        creator {
          address
        }
        owner {
          address
        }
        price
        quoteToken
        voteCnt
        artworkType
        artworkName
        artistName
        description
        category
      }
    }`
  return gql(queryString)
}
