import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/reducers'
import useFetchNfts from 'hooks/useFetchNfts'
import useFetchProfile from 'hooks/useProfile'
import UserTab from '../MyProfile/components/UserTab'
import { ClipLoader } from 'react-spinners'
import { ArtworkIcon, CollectionIcon, SoldIcon, TradeupIcon, PuzzleIcon } from 'shared-next/Icons'
import { CardList } from 'components-next'
import { ErrorBoundry } from 'shared-next'
import { setActiveNft } from 'store/actions'
import { NftType } from 'types'
import { getContractAddresses } from 'contracts'

const profileTabs = [
  {
    name: 'GNFT',
    value: 'GNFT',
    icon: <PuzzleIcon />,
  },
  {
    name: 'Collections',
    value: 'Collections',
    icon: <CollectionIcon />,
  },
  {
    name: 'All',
    value: 'All',
    icon: <ArtworkIcon />,
  },
  {
    name: 'Sold',
    value: 'Sold',
    icon: <SoldIcon />,
  },
  {
    name: 'On Sale',
    value: 'onSale',
    icon: <TradeupIcon />,
  },
]

const Profile = (props: any) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    address,
    web3,
    fetchProfileData,
    fetchMyAllNftCollection,
    myNFTCollection,
    myNfts,
    fetchMyParticularNftCollection,
    loading,
    fetchMySellNftAssets,
    fetchMySoldNftAssets,
    canFetchMore,
    fetchMore,
    mySoldNFT,
    myOnSaleNFT,
    fetchGNFT,
    GNFT,
  } = useFetchNfts()
  const activeChainID = useSelector((state: RootState) => state.wallet.activeChainID)

  const { profileLoader, isProfileDetailsAvailable, userDetails, fetchUserProfileDetails } = useFetchProfile()
  const { address: userAddress } = props.match.params
  const addresses = getContractAddresses(activeChainID)

  const [activeLink, setActiveLink] = useState('GNFT')

  const handleLinkChange = (newLink) => {
    if (activeLink === newLink) return
    setActiveLink(newLink)
    switch (newLink) {
      case 'GNFT': {
        fetchGNFT(userAddress, (addresses && addresses.nftToken) || '')
        break
      }
      case 'Collections': {
        fetchMyAllNftCollection(userAddress)

        break
      }
      case 'All': {
        fetchProfileData(userAddress, '', '')

        break
      }
      case 'Sold': {
        fetchMySoldNftAssets(userAddress)

        break
      }
      case 'onSale': {
        fetchMySellNftAssets(userAddress, 'all', '', '')

        break
      }

      default: {
        return 0
      }
    }
  }

  const onSelectNft = (collectionAddress: string, tokenId: string, is_Collection: boolean) => {
    if (is_Collection) {
      setActiveLink('')
      return fetchMyParticularNftCollection(userAddress, collectionAddress)
    }
    if (collectionAddress === '' || tokenId === '') return

    const selectedNft = myNfts.find(
      (row: NftType) => row.collectionAddress === collectionAddress && row.tokenId === tokenId,
    )
    const selectedNFTSale = myOnSaleNFT.find(
      (row: NftType) => row.collectionAddress === collectionAddress && row.tokenId === tokenId,
    )
    const selectedNFTSold = mySoldNFT.find(
      (row: NftType) => row.collectionAddress === collectionAddress && row.tokenId === tokenId,
    )
    const selectedGNFT = GNFT.find(
      (row: NftType) => row.collectionAddress === collectionAddress && row.tokenId === tokenId,
    )

    if (selectedNft) {
      dispatch(setActiveNft(selectedNft))
      history.push(`/assets/${selectedNft.collectionAddress}/${selectedNft.tokenId}`, { detail: 'my-profile' })
    }
    if (selectedNFTSale) {
      dispatch(setActiveNft(selectedNFTSale))
      history.push(`/assets/${selectedNFTSale.collectionAddress}/${selectedNFTSale.tokenId}`, {
        detail: 'my-profile',
      })
    }
    if (selectedNFTSold) {
      dispatch(setActiveNft(selectedNFTSold))
      history.push(`/assets/${selectedNFTSold.collectionAddress}/${selectedNFTSold.tokenId}`, {
        detail: 'my-profile',
      })
    }
    if (selectedGNFT) {
      dispatch(setActiveNft(selectedGNFT))
      history.push(`/assets/${selectedGNFT.collectionAddress}/${selectedGNFT.tokenId}`, {
        detail: 'my-profile',
      })
    }
  }

  useEffect(() => {
    if (!userAddress) return
    if (!address) return

    if (userAddress.toLowerCase() === address.toLowerCase()) history.push('/my-profile')
  }, [address, userAddress])

  useEffect(() => {
    if (!userAddress) return
    fetchUserProfileDetails(userAddress)
    fetchGNFT(userAddress, (addresses && addresses.nftToken) || '')
  }, [userAddress])

  return (
    <React.Fragment>
      {profileLoader ? (
        <div className="p-5 max-w-screen-2xl min-h-screen flex items-center dark:text-white justify-center">
          <ClipLoader color={'dark:text-white'} />
        </div>
      ) : (
        <React.Fragment>
          <div className="border-gray-200 border-b">
            <UserTab
              activeLink={activeLink}
              handleLinkChange={handleLinkChange}
              address={userAddress}
              displayAlert={null}
              isProfileDetailsAvailable={isProfileDetailsAvailable}
              userDetails={userDetails}
              web3={web3}
              profileTabs={profileTabs}
              isMyProfile={false}
            />
          </div>
          <div className="flex">
            <section className="w-full px-4 sm:px-8 ">
              <div className="flex items-center pt-5  justify-between ">
                <p className="text-gray-500 dark:text-white text-sm">
                  <span>
                    {' '}
                    {activeLink === 'Collections'
                      ? myNFTCollection.length
                      : activeLink === 'All'
                      ? myNfts.length
                      : activeLink === 'Sold'
                      ? mySoldNFT.length
                      : activeLink === 'onSale'
                      ? myOnSaleNFT.length
                      : activeLink === 'GNFT'
                      ? GNFT.length
                      : myNfts.length}
                  </span>
                  <span className="ml-1">results</span>
                </p>
              </div>
              <div className="pb-4">
                <ErrorBoundry>
                  <CardList
                    isCollection={activeLink === 'Collections'}
                    nfts={
                      activeLink === 'Collections'
                        ? myNFTCollection
                        : activeLink === 'All'
                        ? myNfts
                        : activeLink === 'Sold'
                        ? mySoldNFT
                        : activeLink === 'onSale'
                        ? myOnSaleNFT
                        : activeLink === 'GNFT'
                        ? GNFT
                        : myNfts
                    }
                    loader={loading}
                    canFetchMore={activeLink === 'Collections' ? false : canFetchMore}
                    fetchMore={fetchMore}
                    onSelectNft={onSelectNft}
                    mintLoader={false}
                  />
                </ErrorBoundry>
              </div>
            </section>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
export default Profile
