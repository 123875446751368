import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { NftType } from 'types'
import Card from 'components-next/Card'
import { SkeletonCard, NotItemFound } from 'shared-next'

interface NftListProps {
  nfts: NftType[]
  canFetchMore: boolean
  fetchMore: () => void
  onSelectNft: (collectionAddress: string, tokenId: string, is_Collection: boolean) => void
  loader?: boolean
  isCollection?: boolean
  layout?: 'grid' | 'flex'
  mintLoader: boolean
}

const count = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }]

const CardList = ({
  nfts,
  canFetchMore,
  loader = false,
  fetchMore,
  onSelectNft,
  layout = 'grid',
  mintLoader = false,
}: NftListProps) => {
  return (
    <>
      {loader ? (
        <div
          className={`${
            layout === 'grid'
              ? 'grid grid-col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'
              : 'grid grid-flow-col auto-cols-max'
          } gap-4 py-4`}
        >
          {count.map((item) => (
            <SkeletonCard key={item.id} />
          ))}
        </div>
      ) : (
        <React.Fragment>
          {nfts.length > 0 ? (
            <InfiniteScroll
              dataLength={nfts.length}
              next={fetchMore}
              hasMore={canFetchMore}
              loader={count.map((item) => (
                <SkeletonCard key={item.id} />
              ))}
              className={`${
                layout === 'grid'
                  ? 'grid grid-col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'
                  : 'grid grid-flow-col auto-cols-max'
              } gap-4 py-4`}
            >
              {nfts.map((nft: NftType, index) => (
                <Card
                  key={`${nft.collectionAddress}_${nft.tokenId}_${index}`}
                  nft={nft}
                  onSelectNft={onSelectNft}
                  layout={layout}
                />
              ))}
              {mintLoader && <SkeletonCard key={12333} />}
            </InfiniteScroll>
          ) : (
            <NotItemFound />
          )}
        </React.Fragment>
      )}
    </>
  )
}

export default React.memo(CardList)
