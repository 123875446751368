import React from 'react'
import { useHistory } from 'react-router'

const NoItemFound = () => {
  const history = useHistory()

  const handleBacktoMarket = () => {
    if (history.location.pathname === '/my-profile') history.push(`/my-profile`)
    else history.push(`/marketplace`)
  }

  return (
    <div className="w-full h-full text-center py-16 px-4">
      <div className="border-2 py-8 rounded border-gray-500 dark:border-white rounded">
        <h1 className="text-2xl text-gray-500 dark:text-white">No items found</h1>
        <button
          type="button"
          title="Back to All"
          onClick={handleBacktoMarket}
          className=" glow-on-hover items-center gap-x-1 mt-4 px-4 py-3 text-sm font-medium text-white bg-gradient-to-r from-blue-400 via-green-300 to-purple-500 rounded-md focus:outline-none hover:ring-2 hover:ring-offset-2 hover:ring-purple-500 focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
        >
          <span>Back to All</span>
          <span></span>
        </button>
      </div>
    </div>
  )
}

export default NoItemFound
