import React, { Suspense, useEffect, useState } from 'react'
import { Accordion, Dropdown, FilterListBox, PriceFilter } from 'shared-next'
import { ArtworkIcon, CollectionIcon, DollarIcon, ListIcon } from 'shared-next/Icons'
import { ClipLoader } from 'react-spinners'
import { artworkTypeOptions, categoryOptions } from '../constants'

import { Sidebar } from 'components-next'

const Filters = (props: any) => {
  // Option data

  const {
    onFilterChanged,
    setPageNumber,
    setCanFetchMore,
    selectedCollection,
    collectionLoading,
    nftCollections,
    quoteTokenOptions,
  } = props

  //Selected Options
  const [categoryOpt, setCategoryOpt] = useState({ label: 'All', value: '' })
  const [activeQuoteTokenOption, setActiveQuoteTokenOption] = useState({ label: 'All', value: 'all' })
  const [activeNftCollection, setActiveNftCollection] = useState(selectedCollection)
  const [artworkTypeOpt, setArtworkTypeOpt] = useState({ label: 'Select Type', value: '', field: 'artworkType' })
  const [minPrice, setMinPrice] = useState(0)
  const [maxPrice, setMaxPrice] = useState(9223372036854775807)

  /**
   * Handle Category Change
   */
  const onChangeCategory = (item) => {
    if (item.value !== categoryOpt.value) {
      setCanFetchMore(true)
      setPageNumber(0)
      setCategoryOpt(item)
      // handleFilterOption(categoryOpt, artworkTypeOpt, activeQuoteTokenOption, activeNftCollection, minPrice, maxPrice)
    }
  }

  /**
   * Handle Price Filter
   */
  const handlePriceFilter = (val) => {
    setCanFetchMore(true)
    setPageNumber(0)
    setActiveQuoteTokenOption(val.activeQuoteTokenOption)
    setMinPrice(val.min)
    setMaxPrice(val.max)
    // handleFilterOption(categoryOpt, artworkTypeOpt, activeQuoteTokenOption, activeNftCollection, minPrice, maxPrice)
  }

  /**
   * Handle Collection Change
   */
  const onSelectNftCollection = (item) => {
    if (item.value !== activeNftCollection.value) {
      setCanFetchMore(true)
      setPageNumber(0)
      setActiveNftCollection(item)
    }
  }

  /**
   * Handle Artwork Change
   */
  const onChangeSelect = (item) => {
    if (item.value !== artworkTypeOpt.value) {
      setCanFetchMore(true)
      setPageNumber(0)
      setArtworkTypeOpt(item)
      //  handleFilterOption(categoryOpt, artworkTypeOpt, activeQuoteTokenOption, activeNftCollection, minPrice, maxPrice)
    }
  }

  /**
   * handle change in any filter parameter
   */
  const handleFilterOption = (
    categoryOpt,
    artworkTypeOpt,
    activeQuoteTokenOption,
    activeNftCollection,
    minPrice,
    maxPrice,
  ) => {
    const category = categoryOpt.value === 'all' ? '' : categoryOpt.value
    const collection = activeNftCollection.value === 'all' ? '' : activeNftCollection.value
    const quoteToken = activeQuoteTokenOption.value === 'all' ? '' : activeQuoteTokenOption.value
    const artWork = artworkTypeOpt === 'all' ? '' : artworkTypeOpt.value
    const filterData = {
      category,
      collection,
      quoteToken,
      minPrice,
      maxPrice,
      artWork,
    }

    onFilterChanged(filterData)
  }

  useEffect(() => {
    const collection = { ...selectedCollection, url: '' }
    onSelectNftCollection(collection)
    //setActiveNftCollection(collection)
  }, [selectedCollection])

  useEffect(() => {
    handleFilterOption(categoryOpt, artworkTypeOpt, activeQuoteTokenOption, activeNftCollection, minPrice, maxPrice)
  }, [categoryOpt, artworkTypeOpt, activeQuoteTokenOption, activeNftCollection, minPrice, maxPrice])

  return (
    <Sidebar>
      <Accordion title="Categories" icon={<ListIcon />} defaultOpen={true}>
        <Suspense fallback={<ClipLoader color={'dark:text-white'} />}>
          <FilterListBox list={categoryOptions} selected={categoryOpt} onSelect={onChangeCategory} />
        </Suspense>
      </Accordion>
      <Accordion title="Price" icon={<DollarIcon />}>
        <PriceFilter onApply={handlePriceFilter} list={quoteTokenOptions} />
      </Accordion>

      <Accordion title="Collection" icon={<CollectionIcon />} defaultOpen={true}>
        <Suspense fallback={<ClipLoader color={'dark:text-white'} />}>
          <FilterListBox
            list={nftCollections}
            selected={activeNftCollection}
            onSelect={onSelectNftCollection}
            loading={collectionLoading}
          />
        </Suspense>
      </Accordion>
      <Accordion title="Artwork Type" icon={<ArtworkIcon />}>
        <Dropdown list={artworkTypeOptions} selected={artworkTypeOpt} onSelect={onChangeSelect} />
      </Accordion>
    </Sidebar>
  )
}
export default React.memo(Filters)
