import React, { useState } from 'react'
import CreateNFT from '../MyProfile/components/CreateNFT'
import { getContractAddresses } from 'contracts'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducers'
import TheGraph from 'assets-next/images/thegraph.png'
import Covalet from 'assets-next/images/covalent.png'
import { ArrowRightSM } from 'shared-next/Icons'
import { Alert } from 'shared-next'
import { CHAIN_INFO } from 'utils/chains'
export const chainsLogo = CHAIN_INFO.map((item) => item.logoUrl)
const companyLogo = [...chainsLogo, TheGraph, Covalet]

const HomePage = () => {
  const activeChainID = useSelector((state: RootState) => state.wallet.activeChainID)
  const addresses = getContractAddresses(activeChainID)
  const [showAlert, setShowAlert] = useState<string>('')

  const displayAlert = (state: string, data: any) => {
    setShowAlert(state)
    setTimeout(() => {
      setShowAlert('')
    }, 7000)
  }

  return (
    <div className="box-border px-8 md:px-24 py-16 md:py-16 h-full min-h-screen">
      <div className="fixed bottom-4 right-4 z-40">
        <>
          {showAlert === 'success' && <Alert icon="success">Your NFT will be added in few seconds...</Alert>}

          {showAlert === 'error' && <Alert icon="danger">Oops! Something went wrong...</Alert>}
        </>
      </div>
      <div className="flex flex-col  md:flex-row items-center justify-center mt-8 gap-y-16 gap-x-4 dark:text-white">
        <div className="z-10">
          <h4 className="text-4xl text-center md:text-6xl font-bold  text-pink-500  mb-2 antialiased tracking-normal uppercase">
            <span className="underline text-purple-500 dark:text-white  ">
              <span className="text-pink-500 ">GamyFi</span>{' '}
            </span>
            NFT
          </h4>
          <h4 className="text-4xl md:text-6xl text-center text-pink-500  font-bold mb-8 5 antialiased tracking-normal uppercase">
            MarketPlace{' '}
          </h4>
          <div className="flex flex-col items-center justify-center gap-4 mb-8">
            <p className="uppercase font-medium text-xl  text-center ">
              A completely decentralised permissionless multi-chain NFT marketplace.{' '}
            </p>
            <p className="uppercase font-medium text-xl text-center">
              Create, trade and collect rare NFT collections with zero fees & low gas.
            </p>
          </div>
          <div className="flex flex-row items-center justify-center  gap-x-4">
            <CreateNFT
              collectionAddress={(addresses && addresses.nftToken) || ''}
              displayAlert={displayAlert}
              handleAfterMintLoader={() => {
                return
              }}
            />
            <a
              className="flex items-center gap-x-1 px-4 py-3 text-sm font-medium text-white glow-on-hover bg-gradient-to-r from-blue-400 via-green-300 to-purple-500 rounded-md focus:outline-none hover:ring-2 hover:ring-offset-2 hover:ring-purple-500 focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-opacity-75`"
              href="/marketplace"
            >
              Trade
              <ArrowRightSM />
            </a>
          </div>
          <div className="mt-20 flex flex-col items-center justify-center pt-8 dark:bg-gray-900  ">
            <h1 className="font-semibold mb-2   ">Powered By </h1>
            <div className="grid grid-cols-3 lg:grid-cols-11 gap-6 ">
              {companyLogo.map((item, index) => (
                <div key={`company_${index}`}>
                  <img src={item} className="h-16 w-16" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage
